import React, { useState } from "react";
import PaymentOption from "./PaymentOption";
import Button from "../Button";
import ActivityModal from "../ActivityModal";
import PaymentResolver from "./PaymentResolver";
import { Service } from "../../typings/reservation";
import { PayStackPayload } from "../../pages/callbacks/PaystackPayment";

type PayWith = "bank transfer" | "credit or debit card" | "paypal";

const PaymentBuilder = ({
  amount,
  onPaymentCompleted,
  type,
  email,
  payload,
  includeButton = true,
}: {
  amount: number;
  onPaymentCompleted: (amountPaid: number) => void;
  type: Service;
  email: string;
  payload: PayStackPayload;
  includeButton?: boolean;
}) => {
  const [paymentOption, setPaymentOption] = useState<PayWith>("bank transfer");
  const [confirmAndPay, setConfirmAndPay] = useState(false);

  if (confirmAndPay) {
    return (
      <ActivityModal
        title={"Confirm and pay"}
        body={
          <PaymentResolver
            amountToPay={amount}
            payWith={paymentOption}
            type={type}
            email={email}
            payload={payload}
            handlePaymentCompleted={onPaymentCompleted}
          />
        }
        footer={""}
        onClose={() => {
          setConfirmAndPay(false);
        }}
      />
    );
  }

  return (
    <div className={"space-y-6"}>
      <PaymentOption setPaymentMethod={setPaymentOption} />
      {includeButton && (
        <Button
          text={"Confirm and pay"}
          block={true}
          onClick={() => {
            setConfirmAndPay(true);
          }}
        />
      )}
    </div>
  );
};

export default PaymentBuilder;
