import React, { ReactNode } from "react";

export interface Job {
  id: string;
  deadline: string;
  title: string;
  location: string;
  jobType: string;
  body: ReactNode[];
}

export const Jobs: Job[] = [
  {
    id: "guest-experience-host",
    title: "Guest Experience Host",
    location: "Alagbaka GRA Extension, Akure, Ondo State",
    jobType: "Contract (3 months)",
    deadline: "2024-12-27T10:00:00",
    body: [
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>About us</p>
        <p>
          We are a newly established, vibrant hospitality company committed to
          delivering exceptional guest experiences through our short-let
          properties, including luxury homes, beauty salon, spa and restaurant.
          Our dedicated team takes pride in curating unique and memorable stays,
          and we are seeking a motivated and professional Guest Experience Host
          to join us for an initial 3-month contract, with the potential for
          extension based on performance.
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Position Overview</p>
        <p>
          As a Guest Experience Host, you will serve as the welcoming face of
          our brand, ensuring that each guest feels valued, comfortable, and
          well-cared-for throughout their stay. This multifaceted role
          encompasses guest hosting, housekeeping, social media content
          creation, and responding to guest inquiries. If you are personable,
          attentive, and skilled at crafting exceptional guest experiences, we
          encourage you to apply!
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Key Responsibilities</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            <span className={"font-semibold"}>Guest Hosting:</span> Welcome
            guests warmly, provide them with check-in support, and ensure their
            needs are met throughout their stay.
          </li>
          <li>
            <span className={"font-semibold"}>Housekeeping:</span> Perform
            housekeeping duties to maintain a clean and inviting environment for
            all guests.
          </li>
          <li>
            <span className={"font-semibold"}>Cooking:</span> Prepare light
            meals and snacks for guests, accommodating dietary preferences and
            special requests as needed.
          </li>
          <li>
            <span className={"font-semibold"}>
              Social Media Content Creation:
            </span>{" "}
            Capture and create engaging content that reflects our brand for
            social media platforms, showcasing our unique spaces and guest
            experiences.
          </li>
          <li>
            <span className={"font-semibold"}>
              Customer Service & Inquiries:
            </span>{" "}
            Respond promptly and professionally to guest inquiries through
            phone, email, and social media, providing information, support, and
            problem resolution.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Requirements</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            Proven experience in hospitality, guest services, or customer
            support.
          </li>
          <li>
            A friendly and welcoming demeanor is essential, ensuring guests feel
            at ease from the moment they arrive.
          </li>
          <li>
            Strong communication skills and a professional, approachable manner.
          </li>
          <li>
            Excellent organizational skills with close attention to detail,
            particularly in housekeeping tasks.
          </li>
          <li>
            Proficiency in social media platforms (Instagram, Facebook, TikTok,
            etc.), with a creative eye for engaging content
          </li>
          <li>
            Solid understanding of and experience with booking platforms such as
            Airbnb, Booking.com, and similar.
          </li>
          <li>
            Flexibility to work weekends, evenings, and adapt to a dynamic
            schedule as needed.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>What we offer</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>Competitive hourly rate.</li>
          <li>Flexible working hours.</li>
          <li>
            Hands-on experience in a fast-paced, guest-focused environment..
          </li>
          <li>The opportunity to be part of a passionate, supportive team!</li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>To Apply</p>
        <p>
          If you’re ready to create memorable experiences and leave a lasting
          impression on our guests, apply today! We’re excited to meet someone
          who’s as passionate about hospitality as we are.
        </p>
        <p className={"font-semibold"}>
          Please submit your resume and a brief cover letter to{" "}
          <span className={"underline underline-offset-4 decoration-dashed"}>
            <a
              href={
                "mailto:bookings@ifemide.com?subject=Application%20for%20Guest%20Experience%20Host%20Position%20–%20[Your%20Name]&body=Dear%20Hiring%20Team,%0A%0AI%20hope%20this%20message%20finds%20you%20well.%20I%20am%20excited%20to%20apply%20for%20the%20Guest%20Experience%20Host%20position%20at%20Ifemide.%20I%20am%20confident%20that%20my%20skills%20and%20experience%20align%20perfectly%20with%20the%20requirements%20of%20the%20role.%20Please%20find%20my%20resume%20and%20cover%20letter%20attached%20for%20your%20review.%0A%0ALooking%20forward%20to%20the%20opportunity%20to%20contribute%20to%20your%20team!"
              }
            >
              bookings@ifemide.com
            </a>
          </span>
          , including your salary expectations.
        </p>
        <p>
          We look forward to learning more about you and how you can contribute
          to creating exceptional guest experiences with our team.
        </p>
      </div>,
    ],
  },
  {
    id: "hair-stylist",
    title: "Hair Stylist",
    location: "Alagbaka GRA Extension, Akure, Ondo State",
    jobType: "Contract",
    deadline: "2024-12-27T10:00:00",
    body: [
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>About Us</p>
        <p>
          We are a newly established, vibrant hospitality company committed to
          delivering exceptional guest experiences through our short-let
          properties, including luxury homes, beauty salon, spa and restaurant.
          Our team takes pride in offering clients a personalized and luxurious
          experience while staying at the forefront of beauty and styling
          trends. We are seeking a talented and enthusiastic Hair Stylist for a
          3-month contract with the potential for extension based on
          performance.
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Position Overview</p>
        <p>
          As a Hair Stylist, you will be responsible for creating stunning
          hairstyles that meet and exceed client expectations. From braiding to
          general haircare and social media content creation, this role combines
          technical skill with creativity. If you are passionate about hair
          styling, love to work with people, and thrive in a fast-paced
          environment, we encourage you to apply!
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Key Responsibilities</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            <span className={"font-semibold"}>Hair Styling:</span> Style and
            braid hair, specializing in intricate and long braid styles, and
            ensure client satisfaction with the final look.
          </li>
          <li>
            <span className={"font-semibold"}>Hair Care:</span> Provide hair
            treatment and maintenance services to clients, promoting healthy
            hair practices.
          </li>
          <li>
            <span className={"font-semibold"}>Creative Content Creation:</span>{" "}
            Capture and produce engaging content for social media platforms,
            showcasing your styling work and promoting our brand.
          </li>
          <li>
            <span className={"font-semibold"}>Customer Interaction:</span>{" "}
            Provide exceptional customer service, understanding client needs,
            and tailoring services to meet their preferences.
          </li>
          <li>
            <span className={"font-semibold"}>Up-to-date Trends:</span> Stay
            informed about the latest trends and techniques in hair styling,
            ensuring our services remain modern and competitive.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Requirements</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            Proven experience as a Hair Stylist, with expertise in braiding and
            creative hair designs.
          </li>
          <li>
            A creative and detail-oriented approach to hairstyling, ensuring
            every client leaves satisfied.
          </li>
          <li>
            Strong communication skills and a friendly, approachable manner.
          </li>
          <li>
            Proficiency in social media platforms (Instagram, TikTok, etc.) with
            a knack for creating visually appealing and engaging content.
          </li>
          <li>
            Flexibility to work weekends, evenings, and adapt to a dynamic
            schedule as needed.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>What We Offer</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>Competitive hourly rate.</li>
          <li>A creative and dynamic working environment.</li>
          <li>
            Opportunities for personal and professional growth in the beauty
            industry.
          </li>
          <li>The chance to be part of a passionate, supportive team!</li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>To Apply</p>
        <p>
          If you’re ready to showcase your skills, create stunning hairstyles,
          and help clients feel their best, apply today! We’re excited to meet
          someone who shares our passion for beauty and creativity.
        </p>
        <p className={"font-semibold"}>
          Please submit your resume, portfolio, and a brief cover letter to{" "}
          <span className={"underline underline-offset-4 decoration-dashed"}>
            <a
              href={
                "mailto:bookings@ifemide.com?subject=Application%20for%20Hair%20Stylist%20Position%20–%20[Your%20Name]&body=Dear%20Hiring%20Team,%0A%0AI%20hope%20this%20message%20finds%20you%20well.%20I%20am%20excited%20to%20apply%20for%20the%20Hair%20Stylist%20position%20at%20Ifemide.%20I%20am%20confident%20that%20my%20skills%20and%20experience%20align%20perfectly%20with%20the%20requirements%20of%20the%20role.%20Please%20find%20my%20resume%20and%20portfolio%20attached%20for%20your%20review.%0A%0ALooking%20forward%20to%20the%20opportunity%20to%20contribute%20to%20your%20team!"
              }
            >
              bookings@ifemide.com
            </a>
          </span>
          , including your salary expectations.
        </p>
        <p>
          We look forward to learning more about you and how you can contribute
          to our team’s creativity and success.
        </p>
      </div>,
    ],
  },
  {
    id: "chef",
    title: "Chef",
    location: "Alagbaka GRA Extension, Akure, Ondo State",
    jobType: "Contract",
    deadline: "2024-12-27T10:00:00",
    body: [
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>About Us</p>
        <p>
          We are a newly established, vibrant hospitality company committed to
          delivering exceptional guest experiences through our short-let
          properties, including luxury homes, beauty salon, spa and restaurant.
          Our goal is to provide our guests with flavorful and memorable meals
          that celebrate the richness of African and international cuisines. We
          are currently seeking a talented and passionate Chef to join our team
          oon an initial contract, with the possibility of extension based on
          performance.
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Position Overview</p>
        <p>
          As a Chef, you will be the creative force behind our kitchen,
          responsible for crafting exquisite dishes and designing menus that
          delight our guests. With expertise in Nigerian cuisine and a flair for
          continental cooking, you will ensure a high standard of food
          preparation and presentation. If you are a skilled and innovative
          culinary professional, we encourage you to apply!
        </p>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Key Responsibilities</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            <span className={"font-semibold"}>Food Preparation:</span> Prepare
            and cook a variety of Nigerian dishes, alongside continental meals
            to meet guest expectations.
          </li>
          <li>
            <span className={"font-semibold"}>Menu Design:</span> Develop
            creative and seasonal menus that incorporate local ingredients and
            balance traditional and contemporary flavors.
          </li>
          <li>
            <span className={"font-semibold"}>Kitchen Management:</span> Oversee
            kitchen operations, ensuring cleanliness, efficiency, and compliance
            with health and safety standards.
          </li>
          <li>
            <span className={"font-semibold"}>Culinary Innovation:</span>{" "}
            Experiment with new recipes, techniques, and presentation styles to
            keep our offerings fresh and exciting.
          </li>
          <li>
            <span className={"font-semibold"}>Inventory Management:</span>{" "}
            Monitor and manage stock levels, ensuring optimal use of ingredients
            and minimizing waste.
          </li>
          <li>
            <span className={"font-semibold"}>Guest Interaction:</span>{" "}
            Occasionally engage with guests to understand their preferences and
            create tailored culinary experiences.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>Requirements</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>
            Proven experience as a Chef or Cook, with a strong background in
            African and continental cuisines.
          </li>
          <li>
            Expertise in preparing Yoruba dishes and an appreciation for
            traditional cooking techniques.
          </li>
          <li>
            Creativity and passion for developing unique and flavorful menus.
          </li>
          <li>
            Strong organizational skills and ability to work in a fast-paced
            environment.
          </li>
          <li>
            Excellent communication and interpersonal skills to work effectively
            with the team and occasionally engage with guests.
          </li>
          <li>
            Flexibility to work weekends, evenings, and adapt to a dynamic
            schedule.
          </li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>What We Offer</p>
        <ul className={"list-disc list-outside pl-4 space-y-3"}>
          <li>Competitive salary based on experience.</li>
          <li>
            Opportunities to showcase your culinary creativity and innovation.
          </li>
          <li>
            Access to a well-equipped kitchen with high-quality ingredients.
          </li>
          <li>Collaborative and supportive team environment.</li>
          <li>Potential for contract extension based on performance.</li>
        </ul>
      </div>,
      <div className={"space-y-3"}>
        <p className={"text-lg font-extrabold"}>To Apply</p>
        <p>
          If you’re ready to bring your culinary skills to our kitchen and
          create memorable dining experiences, we would love to hear from you!
          Submit your application today.
        </p>
        <p className={"font-semibold"}>
          Please submit your resume, portfolio, and a brief cover letter to{" "}
          <span className={"underline underline-offset-4 decoration-dashed"}>
            <a
              href={
                "mailto:bookings@ifemide.com?subject=Application%20for%20Chef%20Position%20–%20[Your%20Name]&body=Dear%20Hiring%20Team,%0A%0AI%20hope%20this%20message%20finds%20you%20well.%20I%20am%20excited%20to%20apply%20for%20the%20Chef%20position%20at%20Ifemide.%20I%20am%20confident%20that%20my%20skills%20and%20experience%20align%20perfectly%20with%20the%20requirements%20of%20the%20role.%20Please%20find%20my%20resume%20and%20portfolio%20attached%20for%20your%20review.%0A%0ALooking%20forward%20to%20the%20opportunity%20to%20contribute%20to%20your%20team!"
              }
            >
              bookings@ifemide.com
            </a>
          </span>
          , including your salary expectations.
        </p>
        <p>
          We look forward to discovering your culinary talent and having you
          contribute to our team’s success.
        </p>
      </div>,
    ],
  },
];
