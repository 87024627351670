import React from "react";
import EditCustomer from "./EditCustomer";
import FullPage from "../../../components/FullPage";
import { hasPermission } from "../../../utils/validation";
import { useStaff } from "../../../hooks/useStaff";
import { useLocation } from "react-router-dom";

const Customers = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { staff } = useStaff();
  return (
    <FullPage
      bannerUrl={
        "https://african.business/wp-content/uploads/2020/04/shutterstock_333848435.jpg"
      }
      back={"/host"}
      title={"MANAGE CUSTOMERS"}
      hasPermission={hasPermission(["*:customer"], staff?.permissions)}
      component={
        <div className={"space-y-6 p-6 bg-white rounded-lg shadow"}>
          <EditCustomer customerId={searchParams.get("uuid") || undefined} />
        </div>
      }
    />
  );
};

export default Customers;
