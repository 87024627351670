import React from "react";
import ActivityModal from "../../../../components/ActivityModal";
import { PayWith } from "../../../../components/payment/PaymentOption";
import PaymentResolver from "../../../../components/payment/PaymentResolver";
import { NewKitchenOrder } from "../../../../typings/menu";

interface Interface {
  onClose: () => void;
  payWith: PayWith;
  amount: number;
  payload: NewKitchenOrder;
  onPaymentCompleted: (paid: number) => void;
}

const MakePayment = ({
  onClose,
  payWith,
  onPaymentCompleted,
  amount,
  payload,
}: Interface) => {
  return (
    <ActivityModal
      title={"Confirm and pay"}
      body={
        <PaymentResolver
          amountToPay={amount}
          payWith={payWith}
          type={"FOOD"}
          email={payload.emailAddress}
          payload={payload}
          handlePaymentCompleted={onPaymentCompleted}
        />
      }
      footer={""}
      onClose={onClose}
    />
  );
};

export default MakePayment;
