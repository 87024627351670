import React, { ReactNode, useState } from "react";
import LogoRider from "../../../../emails/shared/LogoRider";
import { TelLink } from "../../../../constants/links";
import IfemideFooter from "../../../../emails/shared/IfemideFooter";

interface Props {
  component: ReactNode;
}

const guestLinks = [
  { title: "GUEST LOUNGE", href: "/guest" },
  { title: "ORDER FOR FOOD", href: "/kitchen" },
  { title: "MAKE A RESERVATION", href: "/reservations" },
  { title: "CONTACT HOST", href: TelLink },
  { title: "OUR LOCATION", href: "/faq#directions-to-the-property" },
  { title: "FAQ", href: "/faq" },
];

const companyLinks = [{ title: "JOBS", href: "/jobs" }];

const hostLinks = [
  { title: "DASHBOARD", href: "/host" },
  { title: "RESERVATION", href: "/host/reservations" },
  { title: "INVOICING", href: "/host/invoice" },
];

const Header = (props: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="h-full w-full overflow-auto bg-black relative">
      <div
        className={
          "absolute top-0 left-0 right-0 w-full flex flex-row justify-between items-center p-6 text-white z-30"
        }
      >
        <LogoRider />
        <div className={"flex-row flex items-center justify-center space-x-3"}>
          <a href={TelLink}>
            <div
              className={
                "flex flex-row space-x-1 text-white font-bold items-center"
              }
            >
              <i className="bx bx-phone text-2xl"></i>
              <p className={"text-lg"}>{TelLink.replace("tel:+234", "0")}</p>
            </div>
          </a>
          <button onClick={() => setShowMenu(!showMenu)}>
            <i className="bx bx-menu text-3xl"></i>
          </button>
        </div>
      </div>
      {/* Sidebar Menu */}
      <div
        className={`z-50 pb-40 space-y-1 absolute p-6 top-0 right-0 w-full h-screen overflow-y-auto bg-black transform transition-transform duration-300 ${
          showMenu ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className={"flex flex-row justify-end"}>
          <button onClick={() => setShowMenu(!showMenu)}>
            <i className="bx bx-x text-4xl text-white float-right"></i>
          </button>
        </div>
        <div className={"space-y-16"}>
          <div>
            <p className={"text-xs text-white opacity-50"}>GUEST</p>
            <ul>
              {guestLinks.map((m) => (
                <a href={m.href} key={m.title}>
                  <li
                    className={
                      "py-6 border-b border-white text-white font-bold"
                    }
                  >
                    <p>{m.title.toUpperCase()}</p>
                  </li>
                </a>
              ))}
            </ul>
          </div>
          <div>
            <p className={"text-xs text-white opacity-50"}>COMPANY</p>
            <ul>
              {companyLinks.map((m) => (
                <a href={m.href} key={m.title}>
                  <li
                    className={
                      "py-6 border-b border-white text-white font-bold"
                    }
                  >
                    <p>{m.title.toUpperCase()}</p>
                  </li>
                </a>
              ))}
            </ul>
          </div>
          <div>
            <p className={"text-xs text-white opacity-50"}>HOST</p>
            <ul>
              {hostLinks.map((m) => (
                <a href={m.href} key={m.title}>
                  <li
                    className={
                      "py-6 border-b border-white text-white font-bold"
                    }
                  >
                    <p>{m.title.toUpperCase()}</p>
                  </li>
                </a>
              ))}
            </ul>
          </div>
          <IfemideFooter body={"text-white"} />
        </div>
      </div>

      {/* Ensure this div fills the full width of the parent */}
      <div className="w-full overflow-auto h-screen">
        {props.component}
        <div className={"bg-white pb-80 p-6"}>
          <IfemideFooter />
        </div>
      </div>
    </div>
  );
};

export default Header;
