import React, { useState } from "react";
import { CategoryConfig } from "../config/salon-categories";
import { SalonServiceType } from "../../../../typings/salon";

const Selection = () => {
  const [selected, setSelected] = useState<SalonServiceType>();

  return (
    <div
      className={
        "scrollbar-hidden w-auto overflow-x-scroll space-x-3 flex-row flex"
      }
    >
      {Object.keys(CategoryConfig).map((key) => {
        const type = key as SalonServiceType;
        return (
          <button
            className={"text-center space-y-1"}
            onClick={() => setSelected(type)}
          >
            <div
              className={`transition-all ease-in-out duration-300 rounded-xl p-3 w-16 h-16 ${selected && selected === type ? "bg-pink-500" : "bg-white"}`}
            >
              <img
                className={"object-cover"}
                src={CategoryConfig[type].thumbnailUrl}
                alt={""}
              />
            </div>
            <p className={"text-xs"}>{CategoryConfig[type].title}</p>
          </button>
        );
      })}
    </div>
  );
};

export default Selection;
