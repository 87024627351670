import React from "react";
import { Link, Text } from "@react-email/components";
import { BUSINESS_WEBSITE } from "../../constants/links";

const LogoRider = () => {
  return (
    <Link href={BUSINESS_WEBSITE} target={""}>
      <Text
        style={{ margin: 0 }}
        className={
          "text-start text-white px-3 py-1 m-0 rounded-lg bg-pink-500 font-extrabold w-fit"
        }
      >
        ifemide
      </Text>
    </Link>
  );
};

export default LogoRider;
