import React from "react";

const NoPermission = () => {
  return (
    <div className={"m-3 p-6 rounded-lg bg-pink-50 border-pink-500"}>
      <i className="bx bxs-error text-pink-500"></i>
      <p>
        You have not have the required permission for this feature or
        permission. Please contact admin to grant access.
      </p>
    </div>
  );
};

export default NoPermission;
