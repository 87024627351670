import Loader from "../Loader";
import { useEffect, useRef, useState } from "react";
import { post } from "../../api/database";
import {
  TransactionInitRequest,
  TransactionRequest,
} from "../../typings/transaction";
import { AxiosError } from "axios";
import { Service } from "../../typings/reservation";

interface Interface {
  orderJson: string;
  email: string;
  amount: number;
  type: Service;
}

const DebitOrCreditCard = ({ orderJson, amount, email, type }: Interface) => {
  const init = useRef(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (init.current) return;

    init.current = true;

    post<TransactionInitRequest, TransactionRequest>(
      "paystack/transaction/initialize",
      {
        email,
        amount: amount * 100, // NGN to KOBO
        type,
        orderJson,
      },
    )
      .then((response) => {
        if (response.initResponse) {
          window.location.href = response.initResponse.data.authorization_url;
        } else setError(new AxiosError("Could not initialize transaction"));
      })
      .catch(setError);
  }, [amount, email, orderJson, type]);

  if (error) return <p>An error occurred</p>;

  return <Loader />;
};

export default DebitOrCreditCard;
