export const terms = [
  {
    title: "Booking and Reservation",
    body: [
      "Reservations are processed on a first-come, first-served basis. This ensures that the first guest to complete the booking process and submit the necessary deposit secures the reservation in the event of multiple inquiries for the same dates.",
      "A booking is considered confirmed only upon receipt of the required deposit or full payment of the rental fee.",
      "Guests must be at least 18 years old to make a reservation.",
    ],
  },
  {
    title: "Payment",
    body: [
      "To secure a booking, a deposit equal to 70% of the total rental fee is required, unless otherwise specified during the booking process. This amount includes any applicable security deposits, cleaning fees, and taxes.",
      "The remaining balance, along with any additional charges such as security deposits, cleaning fees, and taxes, must be settled prior to check-in.",
    ],
  },
  {
    title: "Cancellation and Refunds",
    body: [
      "Cancellations and refunds are governed by the property’s stated cancellation policy. Refunds vary depending on the timing of the cancellation relative to the check-in date.",
      "Guests who cancel at least five days before the check-in date will receive a full refund.",
      "Cancellations made less than five days of check-in will incur a charge equal to the cost of the first night, plus 20% of the total booking fee as compensation for lost opportunities.",
      "For cancellations made on the check-in date, 50% of the total booking fee will be charged.",
    ],
  },
  {
    title: "Check-in and Check-out",
    body: [
      "Check-in is available from 3:00 PM to 10:00 PM, and check-out is by 12:00 PM. Adherence to these times is expected. Early check-in or late check-out may be arranged subject to availability and additional fees.",
      "To ensure a smooth process, guests are encouraged to inform the host of their estimated arrival and departure times in advance.",
    ],
  },
  {
    title: "Curfew and Security Measures",
    body: [
      "A strict curfew shall be enforced on the premises from 11:00 PM to 5:00 AM daily. During this period, the entry of any visitors is prohibited, and the gates to the premises will be securely always locked. This curfew policy is of paramount importance, primarily geared towards ensuring the safety and security of not only the occupants of the property but also of individuals within the estate and the surrounding neighbourhood.",
      "The implementation of this curfew rule is non-negotiable, as it serves as a foundational measure for maintaining a secure and peaceful environment for all parties involved. It is a vital contribution to the overall safety fabric of the property, the estate, and the neighbouring community.",
    ],
  },
  {
    title: "Occupancy, Additional Guests, and Parking",
    body: [
      "The property’s base occupancy is 2 adults per room at any given time. This occupancy limit includes all individuals present on the premises, regardless of whether they will be staying overnight or not. The exact number of guests must be agreed upon and specified during the reservation process.",
      "Should the Guest wish to have additional visitors beyond the agreed-upon occupancy during their stay, such requests must be submitted to the Host for approval. The Guest assumes full responsibility and liability for the conduct and actions of their visitors while on the premises. The Host reserves the right to grant or deny approval for additional visitors at their discretion. ",
      "Exceeding the established base occupancy limit with respect to guests necessitates prior consent from the Host and will result in an extra charge as determined by the Host. This supplementary fee is intended to cover augmented utility consumption, heightened property usage, and the broader influence on the lodging, amounting to a minimum of 30,000 Nigerian Naira per additional guest for each day, irrespective of their overnight stay. The inclusion of further guests and the settlement of related charges must be organized prior to the arrival of the said visitors. ",
      "The property allows a maximum of 2 vehicles to be parked within the designated parking area on the premises. Any extra vehicles brought onto the property must be parked outside the compound. The Host cannot guarantee the security or safety of vehicles parked outside the compound, and parking them there is at the Guest's own risk.",
    ],
  },
  {
    title: "Security Deposit",
    body: [
      "A security deposit is required to cover any damages or additional expenses incurred during the stay. The deposit serves as a safeguard for the Host in case of damages or additional expenses during the guest's stay. The deposit will be refunded within 1-2 days after check-out, less any applicable deductions.",
    ],
  },
  {
    title: "Energy Usage, Savings, and Conservation",
    body: [
      "(A) Energy Allocation: The guest acknowledges and agrees that as part of this short-term rental agreement, a maximum daily energy allocation kilowatt-hour (kWh) of electricity and kilogram (kg) of cooking gas per night is 8 kWH for a 4 or 3 bedroom apartments, 5 kWH for a 1 or 2 bedroom apartment. This allocation is designed to encourage energy conservation and to ensure a sustainable and comfortable stay.",
      "(B) Energy Conservation: The guest undertakes to be responsible and conscientious in their energy usage during their stay. This includes, but is not limited to, turning off lights, heating, cooling, and electronic devices when they are not in use. The guest agrees to use energy-efficient practices and to make every effort to minimize unnecessary energy consumption. ",
      "(C) Excess Usage: If the guest exceeds the allocated energy usage during their stay, they shall be responsible for any additional costs incurred. These additional costs will be calculated based on the prevailing utility rates and deducted from the security deposit or billed separately if necessary.",
      "(D) Reporting Issues: The guest agrees to promptly report any malfunctioning or energy-consuming devices to the property owner or management to facilitate timely repairs or replacements. This helps ensure optimal energy efficiency.",
      "E) Cooking Gas: The guest is granted their maximum daily allocation of cooking gas of 1kg per night of for 4, 3 bedroom rentals amd 500g for 2 and 1 bedroom rentals. The guest agrees to use cooking gas sparingly and efficiently. If additional cooking gas is required, the guest may arrange for refills at their own expense.",
      "(F) Sustainability Efforts: The property owner encourages guests to participate in sustainability efforts, such as recycling and water conservation, to reduce their overall environmental impact during their stay.",
      "If the guest exceeds the allocated kilowatt-hours (kWh) of electricity per night, an additional charge of 150 Nigerian Naira (NGN) per kWh over the allocation will be applied. This additional charge will be calculated based on the excess energy consumed and may be deducted from the security deposit or billed separately if necessary. Should the guest require more than the allocated kilogram (kg) of cooking gas per night, an extra charge of 3,000 Nigerian Naira (NGN) per additional kg of cooking gas will be incurred. ",
    ],
  },
  {
    title: "Internet Data Usage ",
    body: [
      "(A) Data Allocation: The guest acknowledges and agrees that as part of this short-term rental agreement, a maximum daily data allocation of 25 Giga Bites (gb) for 4, 3 bedroom rentals and 10  Giga Bites (gb) for 2 and 1 bedroom rentals. This allocation is designed to encourage fair data usage and to ensure a sustainable high speed internet connectivity provided though the installed Starlink Satellite Internet",
      "(B) Data Conservation: The guest undertakes to be responsible and conscientious in their data usage during their stay.",
      "(C) Excess Usage: If the guest exceeds the allocated data usage during their stay, they shall be responsible for any additional costs incurred. These additional costs will be calculated at the rate of 275 Nigerian Naira per Giga Bites used and deducted from the security deposit or billed separately if necessary. ",
    ],
  },
  {
    title: "Environmental Conservation",
    body: [
      "Guests are encouraged to be mindful of the environment by recycling whenever possible and following any recycling guidelines provided. Water conservation practices should be observed, especially in regions or seasons experiencing drought or water scarcity. ",
    ],
  },
  {
    title: "Child Safety Policy for Our Property - Children Below 7 Years Old ",
    body: [
      "At our property, the safety and well-being of our younger guests are of utmost importance to us. To ensure a secure and enjoyable stay for families with children under the age of 7, we have established the following child safety guidelines",
      "1. Accommodation Location: Children under 7 years old must be accommodated on the ground floor of the building. Access to the first floor, where staircases and balconies are located, is restricted for children's safety. ",
      "2. Supervision: Parents or guardians are responsible for the constant supervision of their children during their stay. Extra attention should be given to children in areas with potential hazards, such as standing TVs, hot plates, the kitchen, and any sharp objects. ",
      "3. Balcony Safety: Children are prohibited from accessing balconies under any circumstances. Balconies are off-limits to children due to safety concerns, and parents must ensure compliance. ",
      "4. Accountability: Parents or guardians are fully responsible for the safety and well-being of their children while staying at our property. ",
      "In the event of any accidents or incidents involving their children, the management will not be held accountable. ",
      "We appreciate your cooperation in adhering to these child safety guidelines. These measures are in place to create a safe and enjoyable environment for all our guests, especially those with young children. If you have any questions or need further assistance regarding child safety during your stay, please do not hesitate to reach out to our management team. Your child's safety is our top priority, and we want to ensure a worry-free and pleasant experience for your family at our property. ",
    ],
  },
  {
    title: "House Rules",
    body: [
      "Guests are expected to comply with all house rules provided by the property owner/manager or Host: This emphasises that guests are required to follow all rules and guidelines set by the property owner/manager or Host for the property's proper use and upkeep. Failure to abide by the rules may result in additional fees or termination of the rental agreement: If guests fail to adhere to the house rules, there may be consequences, such as incurring extra charges or having their rental agreement terminated.",
    ],
  },

  {
    title: "Property Care and Maintenance",
    body: [
      "Guests are responsible for maintaining the property in good condition during their stay. They are expected to take care of the property and keep it clean and tidy. Any damages or issues should be reported to the property owner/manager or Host promptly.",
    ],
  },
  {
    title: "Cleaning and Housekeeping",
    body: [
      "The Guest agrees to maintain the property in a clean and tidy condition throughout their stay. Upon check-out, the Guest is expected to leave the property in the same condition it was found upon arrival, including washing and safely storing kitchen plates, pots, utensils, and other cooking equipment used.",
      "The Guest is also responsible for ensuring that all linens used during their stay are stain-free.",
      "If excessive dirtiness, spills, stains, or neglect require cleaning beyond the standard post-checkout cleaning, the Host reserves the right to charge the Guest for additional cleaning services of not less than 50,000 Nigerian Naira.",
      "The cost of additional cleaning services will be deducted from the security deposit. If the deposit is insufficient, the Guest agrees to reimburse the Host for the remaining amount.",
    ],
  },
  {
    title: "Appliance and Equipment Usage",
    body: [
      "Guests should use all appliances, equipment, and amenities according to the provided instructions and guidelines. Any damage caused by misuse or negligence may result in financial responsibility for repairs or replacements.",
    ],
  },
  {
    title: "Right to Daily Inspection",
    body: [
      "The host or their authorized representative shall have the right to conduct daily inspections of the property during the guest's stay to ensure safety and proper maintenance.",
      "Inspections may occur at reasonable times and the host will provide advance notice when possible. In emergencies, the host may enter without prior notice.",
      "The guest agrees to cooperate with the host during inspections. Failure to cooperate may be considered a breach of the agreement.",
    ],
  },
  {
    title: "Reporting Damages and Issues",
    body: [
      "Guests must report any damages or issues noticed upon check-in to the property owner/manager or Host immediately. For damages or issues that occur during the stay, guests should report them promptly to avoid misunderstanding.",
    ],
  },
  {
    title: "Pets and Pet Waste",
    body: [
      "If pets are allowed, guests must adhere to the pet policy and clean up after their pets, properly disposing of waste inside and outside the property.",
    ],
  },
  {
    title: "Smoking and Fire Hazards",
    body: [
      "Smoking may be prohibited inside the property. If allowed, guests should use designated smoking areas and dispose of cigarette butts responsibly. Guests should avoid fire hazards, such as lighting candles near flammable materials or using outdoor grills in unsafe locations.",
    ],
  },
  {
    title: "Security and Locks",
    body: [
      "Guests are responsible for properly locking all doors and windows when leaving the property unattended. Lost or unreturned keys or access devices may result in a replacement fee.",
    ],
  },
  {
    title: "Pool and Water Features",
    body: [
      "Guests should follow all safety guidelines when using the pool, hot tub, or other water features. Children must always be supervised when using these facilities.",
    ],
  },
  {
    title: "Prohibited Activities",
    body: [
      "Guests should refrain from engaging in illegal or disruptive activities, including excessive noise, disturbing neighbors, or violating local ordinances or laws.",
    ],
  },
  {
    title: "Insurance",
    body: [
      "Guests are encouraged to have appropriate travel or rental insurance to cover unforeseen events during their stay, such as trip cancellations or medical emergencies.",
    ],
  },
  {
    title: "Liability",
    body: [
      "The property owner/manager or Host is not responsible for accidents, injuries, or losses that occur on the premises.",
    ],
  },
  {
    title: "Property owner/manager or Host Liability",
    body: [
      "The property owner/manager or Host will ensure the property is safe and in good condition before guests' arrival and comply with relevant safety regulations.",
    ],
  },
  {
    title: "Guests' Responsibility",
    body: [
      "Guests are responsible for their own well-being and that of their fellow guests. They should exercise caution while using any amenities or facilities.",
    ],
  },
  {
    title: "Accidents and Injuries",
    body: [
      "The property owner/manager or Host is not liable for any accidents, injuries, or illnesses that occur on the premises. Guests should take precautions to prevent accidents.",
    ],
  },
  {
    title: "Damage to Personal Property",
    body: [
      "The property owner/manager or Host is not responsible for loss, damage, or theft of guests' personal belongings. Guests should secure their valuables properly.",
    ],
  },
  {
    title: "Loss of Personal Items",
    body: [
      "Any personal items left behind after check-out will be handled according to the property's Lost and Found policy, if applicable. The property owner/manager or Host is not liable for any loss or damage to items left behind.",
    ],
  },
  {
    title: "Indemnification",
    body: [
      "Guests agree to indemnify and hold harmless the property owner/manager or Host from any claims, losses, damages, liabilities, or expenses arising from their use of the property.",
    ],
  },
  {
    title: "Unforeseeable Events",
    body: [
      "The property owner/manager or Host is not liable for circumstances beyond their control, such as natural disasters, power outages, or acts of terrorism, that may affect the booking or stay.",
    ],
  },
  {
    title: "Exclusions",
    body: [
      "This Liability clause does not limit liability for gross negligence or willful misconduct by the property owner/manager or Host.",
    ],
  },
  {
    title: "Force Majeure",
    body: [
      "The property owner/manager or Host is not liable for any unforeseen events (e.g., natural disasters, strikes, government actions) that disrupt the booking or stay.",
    ],
  },
  {
    title: "Privacy Policy",
    body: [
      "The property owner/manager or Host will handle guests' personal information in compliance with applicable data protection laws.",
    ],
  },
  {
    title: "Authority",
    body: [
      "This agreement is governed by the laws of Nigeria, and any disputes will be subject to the exclusive authority of the courts in that area.",
    ],
  },
  {
    title: "Amendments",
    body: [
      "The property owner/manager or Host reserves the right to amend these terms and conditions. Guests will be notified of any changes.",
    ],
  },
  {
    title: "Acceptance",
    body: [
      'The party identified above as "THE GUEST(S)" hereby acknowledges its acceptance and adherence to the house rules and comprehensive general terms and conditions outlined within this contractual rental agreement. ',
    ],
  },
];
