import { v4 } from "uuid";
import moment from "moment";

export type ReservationStatus =
  | "PENDING"
  | "CONFIRMED"
  | "CANCELLED"
  | "CHECKED_IN"
  | "CHECKED_OUT"
  | "CLOSED";

export interface PaymentInfo {
  total: number;
  paid: number;
  due: number;
  discount: number;
  deposit: number;
  currency: string;
}

export interface Booking {
  checkInDateTime: string;
  checkOutDateTime: string;
  guests: Guest;
  roomUuid: string;
}

export interface Reservation {
  readonly id: number;
  bookings: Booking[];
  customerUuid: string;
  status: ReservationStatus;
  paymentInfo: PaymentInfo;
  tags: string[];
  specialRequests: string[];
  updatedAt: string;
  createdAt: string;
}

export interface Guest {
  adults: number;
  children: number;
}

export type Service = "ROOM" | "SPA" | "SALON" | "DRINK" | "FOOD" | "OTHER";

export interface InvoiceEntry {
  uuid: string;
  title: string;
  description: string;
  rate: number;
  quantity: number;
  cost: number;
  unit: string;
  service: Service;
}

export interface Invoice {
  reservationId: number;
  discount: number;
  entries: InvoiceEntry[];
  vat: number;
  total: number;
  paid: number;
  balance: number;
  removedFromStock?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Enquiry {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumberLine1: string;
  addressLine1: string;
  addressLine2: string;
  checkInDateTime: string;
  checkOutDateTime: string;
  room: string;
  roomUuids: string[];
}

export type OptionalId<T extends { id: any }> = Omit<T, "id"> & {
  id?: T["id"];
};
export type OptionalUuid<T extends { uuid: any }> = Omit<T, "id"> & {
  id?: T["uuid"];
};

export type CreateDto<T extends { id: any; updatedAt: any; createdAt: any }> =
  Omit<T, "id" | "updatedAt" | "createdAt"> & {
    id?: T["id"];
  };

export type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export type NumberKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

export interface Transaction {
  id: string;
  amount: number;
  availableBalance: number;
  ledgerBalance: number;
  narration: string;
  reference: string;
  accountNumber: string;
  dateTime: string;
  type: "DEBIT" | "CREDIT";
  category?: TransactionCategory;
  note?: string;
}

export const transactionCategoryArray = [
  "SALES_ROOM",
  "SALES_DRINK",
  "SALES_SPA",
  "SALES_SALON",
  "SALES_OTHER",
  "DEPOSIT",
  "REFUND",
  "EXPENSES_FUEL",
  "EXPENSES_ELECTRICITY",
  "EXPENSES_DRINKS",
  "EXPENSES_MAINTENANCE",
  "EXPENSES_SALARY",
  "EXPENSES_OTHER",
  "LOAN_DIRECTOR",
  "LOAN_OTHERS",
  "INVESTMENT",
  "BANK_CHARGES",
] as const;

export type TransactionCategory = (typeof transactionCategoryArray)[number];

export const SampleTransaction: Transaction = {
  id: v4(),
  amount: 30000,
  availableBalance: 1000000,
  ledgerBalance: 1000000,
  narration: "Transfer for many things",
  reference: "HFIWHE224924ZJH",
  accountNumber: "13******53",
  dateTime: moment().format(),
  type: "DEBIT",
  category: "SALES_ROOM",
};

export const months = [
  { id: "1", title: "January" },
  { id: "2", title: "February" },
  { id: "3", title: "March" },
  { id: "4", title: "April" },
  { id: "5", title: "May" },
  { id: "6", title: "June" },
  { id: "7", title: "July" },
  { id: "8", title: "August" },
  { id: "9", title: "September" },
  { id: "10", title: "October" },
  { id: "11", title: "November" },
  { id: "12", title: "December" },
];

export interface ExtendedGuests {
  adults: number;
  children: number;
  infants: number;
  pets: number;
}

export type Month = string;
export type RoomUuid = string;
export type Nights = number;

export interface Stats {
  occupancy: Record<Month, { nights: Nights; rooms: Record<RoomUuid, Nights> }>;
}
