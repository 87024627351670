import React from "react";
import moment from "moment/moment";
import { CurrentHostThumbnailUrl } from "../constants/links";
import { Rita } from "../typings/permissions";

export const HostPhoto = ({
  thumbnailUrl = CurrentHostThumbnailUrl,
  size = 16,
}: {
  thumbnailUrl?: string;
  size?: number;
}) => {
  return (
    <div className={`shadow rounded-full w-${size} h-${size}`}>
      <img
        alt={"Ifemide Host"}
        className={"object-cover rounded-full m-0 p-0 shadow-2xl"}
        src={thumbnailUrl}
      ></img>
    </div>
  );
};

const HostProfile = ({
  name = Rita.displayName,
  thumbnailUrl = Rita.thumbnailUrl,
}: {
  name?: string;
  thumbnailUrl?: string;
}) => {
  return (
    <div>
      <HostPhoto thumbnailUrl={thumbnailUrl} />
      <p className={"mt-3"}>{name}</p>
      <p className={"font-thin text-sm"}>{moment().format("MMMM DD, YYYY")}</p>
    </div>
  );
};

export default HostProfile;
