import React, { useEffect, useState } from "react";

export const DEFAULT_PAID = 200000;
export const DEFAULT_TOTAL = 200000;

const Price = (props: {
  total?: number;
  paid?: number;
  setPaid: (value: number) => void;
  setTotal: (value: number) => void;
}) => {
  const [total, setTotal] = useState(props.total || DEFAULT_TOTAL);
  const [paid, setPaid] = useState(props.paid || DEFAULT_PAID);

  function formatNumber(num: number): string {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 10,
      notation: "compact",
      compactDisplay: "short",
    }).format(num);
  }

  function isNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    return !isNaN(Number(value));
  }

  function handlePaidChange(event: React.ChangeEvent<HTMLInputElement>) {
    isNumber(event) && setPaid(Number(event.target.value));
  }

  function handleTotalChange(event: React.ChangeEvent<HTMLInputElement>) {
    isNumber(event) && setTotal(Number(event.target.value));
  }

  useEffect(() => {
    props.setTotal(total);
  }, [props, total]);

  useEffect(() => {
    props.setPaid(paid);
  }, [paid, props]);

  return (
    <div className={""}>
      <div className={"mb-3"}>
        <p className={"font-extrabold text-xl"}>Costs and discounts</p>
        <p className={"text-gray-400"}>
          Applying discounts? you can remove from the total
        </p>
      </div>
      <div className={"grid grid-cols-2 gap-8"}>
        <div className={"text-center rounded-2xl border py-6 bg-white shadow"}>
          <p className={"text-gray-500 mb-2"}>Paid</p>
          <input
            onChange={handlePaidChange}
            value={paid}
            type={"number"}
            className={"w-full font-extrabold text-xl text-center"}
          />
          <p className={"text-xs text-gray-500 italic"}>{formatNumber(paid)}</p>
        </div>
        <div className={"text-center rounded-2xl border py-6 bg-white shadow"}>
          <p className={"text-gray-500 mb-2"}>Total</p>
          <input
            onChange={handleTotalChange}
            value={total}
            type={"number"}
            className={"w-full font-extrabold text-xl text-center"}
          />
          <p className={"text-xs text-gray-500 italic"}>
            {formatNumber(total)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Price;
