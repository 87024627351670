import React, { useState } from "react";
import FullPage from "../../../components/FullPage";
import { Jobs } from "./config/JobsConfig";
import moment from "moment";

const JobsPage = () => {
  const [show, setShow] = useState<string>();
  return (
    <FullPage
      title={"JOBS"}
      bannerUrl={
        "https://www.topbusinessdxb.com/wp-content/uploads/2015/02/careers-banner.jpg"
      }
      showTitleText={false}
      component={
        <div className={"space-y-3"}>
          <p>
            <span className={"font-extrabold"}>{Jobs.length}</span> AVAILABLE
            OPENINGS
          </p>
          <div className={"space-y-6"}>
            {Jobs.map((job) => (
              <div
                onClick={() => setShow(show === job.id ? undefined : job.id)}
              >
                <div
                  className={`shadow rounded-xl border p-6 bg-white space-y-3 cursor-pointer ${show && show === job.id ? "border-pink-500" : ""}`}
                  key={job.id}
                >
                  <div>
                    <p className={"font-extrabold text-lg"}>{job.title}</p>
                    <p>{job.location}</p>
                    <p>{job.jobType}</p>
                    <p>{moment(job.deadline).format("dddd, Do MMMM yyyy")}</p>
                  </div>
                  {show && show === job.id && (
                    <div className={"space-y-3 bg-slate-50 p-6 rounded-xl"}>
                      {job.body.map((body) => (
                        <>{body}</>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default JobsPage;
