import React from "react";
import { ModalView } from "../Reservations";

const YourDetails = ({
  onClick,
  emailAddress,
  customerPreview,
  validation,
  view,
}: {
  onClick: () => void;
  validation?: string;
  customerPreview: string;
  emailAddress?: string;
  view: ModalView;
}) => {
  return (
    <section
      id={`reservation-${view}`}
      className={"p-9 bg-white space-y-3 rounded-lg"}
    >
      <p className={"font-semibold"}>Your details</p>
      <div className={"flex flex-row justify-between"}>
        <div>
          <p className={`${validation ? "text-pink-500" : ""}`}>
            {customerPreview}
          </p>
          <p className={"text-gray-500"}>{emailAddress}</p>
        </div>
        <p
          className={
            "underline font-semibold underline-offset-4 cursor-pointer"
          }
          onClick={onClick}
        >
          Edit
        </p>
      </div>
    </section>
  );
};

export default YourDetails;
