import React, { useEffect, useState } from "react";
import FullPage from "../../../../components/FullPage";
import BotChat from "../../../../components/BotChat";
import { CurrentHostThumbnailUrl } from "../../../../constants/links";
import { getGreeting, isValidName } from "../../../../utils/validation";
import { Customer } from "../../../../typings/customer";
import Loader from "../../../../components/Loader";
import FormEntry from "../../../../components/FormEntry";
import ListInput from "../../../../components/ListInput";
import Button from "../../../../components/Button";
import { AxiosError } from "axios";
import { banks } from "../../../../utils/banks";
import { get, patch } from "../../../../api/database";

interface Validation {
  bankId?: string;
  bankName?: string;
  accountNo?: string;
  accountName?: string;
}

const BankDetails = ({ customer }: { customer?: Customer }) => {
  const [bankId, setBankId] = useState(customer?.bankDetails?.bankId || "");
  const [accountNumber, setAccountNumber] = useState(
    customer?.bankDetails?.accountNumber || "",
  );
  const [accountName, setAccountName] = useState(
    customer?.bankDetails?.accountName || "",
  );

  const [saving, setSaving] = useState(false);
  const [validation, setValidation] = useState<Validation>({});
  const [error, setError] = useState<AxiosError | undefined>();

  function validate(): Validation {
    const d: Validation = {};
    const find = banks[bankId];
    if (!find) d.bankId = "Invalid";

    if (accountNumber.trim() === "") d.accountNo = "Invalid";

    if (accountName.trim() === "" || !isValidName(accountName))
      d.accountName = "Invalid";

    return d;
  }

  async function onSave() {
    if (customer) {
      const validation = validate();
      setValidation(validation);

      if (Object.keys(validation).length > 0 || !banks[bankId]) return;

      setSaving(true);
      const payload = {
        ...customer,
        bankDetails: {
          ...customer.bankDetails,
          bankId: banks[bankId].id,
          bankName: banks[bankId].name,
          accountName,
          accountNumber,
        },
      };

      await patch<Customer>("customers", payload).catch(setError);
      setSaving(false);
    }
  }

  useEffect(() => {
    if (customer) {
      get<Customer>("customers/" + customer.uuid)
        .then((c) => {
          if (c.bankDetails) {
            setBankId(c.bankDetails.bankId);
            setAccountName(c.bankDetails.accountName);
            setAccountNumber(c.bankDetails.accountNumber);
          }
        })
        .catch(console.log);
    }
  }, [customer]);

  if (!customer) return <Loader />;

  return (
    <FullPage
      title={"BANK DETAILS"}
      back={"/guest"}
      component={
        <div className={"space-y-6 p-3 px-6 bg-white rounded-lg shadow"}>
          <BotChat
            text={"text-sm"}
            delays={1000}
            profilePhoto={CurrentHostThumbnailUrl}
            messages={[
              {
                content: `${getGreeting()} ${customer.firstName}, should in case you are expecting a refund, kindly put in your bank details here:`,
                sender: "Rita",
              },
            ]}
          />
          <br />
          <div className={"space-y-3"}>
            {error && (
              <p className={"p-3 rounded-lg bg-white border-pink-500 border"}>
                {error.message ||
                  "An unexpected error occurred, please trs again later"}
              </p>
            )}
            <ListInput<any>
              icon={"bx bxs-bank"}
              title={"Bank name"}
              showSearch={true}
              keySelector={"id"}
              titleSelector={"name"}
              list={Object.values(banks)}
              selectedId={bankId}
              onChange={setBankId}
              error={validation.bankId}
            />
            <FormEntry
              title={"Account number"}
              placeholder={""}
              value={accountNumber}
              type={"string"}
              onChange={setAccountNumber}
              error={validation.accountNo}
            />
            <FormEntry
              title={"Account name"}
              placeholder={""}
              value={accountName}
              type={"text"}
              onChange={setAccountName}
              error={validation.accountName}
            />
          </div>
          <div className={"flex flex-row justify-end pb-6"}>
            <Button onClick={onSave} type={"primary"} text={"Save"} />
          </div>
          {saving && <Loader />}
        </div>
      }
    />
  );
};

export default BankDetails;
