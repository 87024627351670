import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

interface SignatureProps {
  existingDataUrl?: string;
  onSave: (dataUrl: string) => void;
}

const SignatureCapture: React.FC<SignatureProps> = ({
  onSave,
  existingDataUrl,
}) => {
  const sigCanvas = useRef<SignatureCanvas>(null);

  const clearSignature = () => {
    sigCanvas.current?.clear();
  };

  const saveSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL("image/png");
      onSave(dataUrl);
    }
  };

  // Automatically save when the user stops drawing
  const handleEnd = () => {
    saveSignature();
  };

  useEffect(() => {
    if (existingDataUrl && sigCanvas.current) {
      sigCanvas.current.fromDataURL(existingDataUrl);
    }
  }, [existingDataUrl]);

  return (
    <div>
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{
          className: "sigCanvas",
          style: { border: "1px solid black", width: "100%", height: 150 },
        }}
        onEnd={handleEnd} // Trigger save when drawing ends
      />
      <div
        style={{ marginTop: "10px" }}
        className={"flex flex-row justify-end"}
      >
        <button onClick={clearSignature}>Clear</button>
      </div>
    </div>
  );
};

export default SignatureCapture;
