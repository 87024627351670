import React from "react";
import { Section, Text } from "@react-email/components";
import MailSetup from "./shared/MailSetup";
import { Enquiry as ReservationRequestI } from "../typings/reservation";
import { Instagram, WhatsApp } from "../pages/faq/FaqHome";
import moment from "moment";
import { Rita } from "../typings/permissions";

const ReservationRequest = (request: ReservationRequestI) => {
  return (
    <MailSetup
      staff={Rita}
      previewText={"Your reservation is almost confirmed!"}
      component={
        <>
          <Text>Hi {request.firstName + " " + request.lastName} 👋</Text>
          <Section>
            <Text className={"font-bold text-lg"}>
              Thank you for choosing{" "}
              <span className={"font-extrabold text-pink-500"}>
                Ifemide Estates.
              </span>{" "}
              We're super excited to get you all set for your upcoming stay! 🥳
            </Text>
          </Section>
          <Section>
            <Text className={"font-bold text-lg"}>
              Here’s a peek at your reservation request:
            </Text>
            <div className={"bg-gray-100 p-3 rounded-xl"}>
              <Text>
                <span className={"font-extrabold"}>😊 Name:</span>{" "}
                {request.firstName + " " + request.lastName}
              </Text>
              <Text>
                <span className={"font-extrabold"}>🛏️ Room-type:</span>{" "}
                {request.room}
              </Text>
              <Text>
                <span className={"font-extrabold"}>📅 Check-in:</span>{" "}
                {moment(request.checkInDateTime).format("Do MMMM YYYY")}
              </Text>
              <Text>
                <span className={"font-extrabold"}>🚪 Check-out:</span>{" "}
                {moment(request.checkOutDateTime).format("Do MMMM YYYY")}
              </Text>
              <Text>
                <span className={"font-extrabold"}>📞 Phone number:</span>{" "}
                {request.phoneNumberLine1}
              </Text>
              <Text>
                <span className={"font-extrabold"}>🏠 Address:</span>{" "}
                {request.addressLine1 + " " + request.addressLine2}
              </Text>
              <Text>
                <span className={"font-extrabold"}>🆔 Reservation ID:</span>{" "}
                [NOT YET CONFIRMED]
              </Text>
            </div>
            <Text>
              Your request is being processed, and I’ll confirm everything
              shortly! If you have any special requests or just want to chat,
              feel free to reach out to me on {Instagram()} or {WhatsApp()}!
              💬✨
            </Text>
            <Text className={"text-lg font-extrabold"}>What’s Next?</Text>
            <div className={"bg-gray-100 p-3 rounded-xl"}>
              <Text>&#x2022; Quick confirmation over a phone call</Text>
              <Text>&#x2022; Make an initial deposit to secure your dates</Text>
              <Text>&#x2022; Look out for your confirmation email!</Text>
            </div>
            <Text>Thanks a bunch for choosing us! 🤗❤️</Text>
          </Section>
        </>
      }
    />
  );
};

export default ReservationRequest;
