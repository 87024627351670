import { Drink } from "../../../../typings/menu";
import React, { useCallback, useEffect, useState } from "react";
import { get } from "../../../../api/database";
import Loader from "../../../../components/Loader";
import moment from "moment";
import Search from "../../../../components/Search";
import DrinkItem from "./DrinkItem";
import { toastAxiosError } from "../../../../utils/toast";
import { useStaff } from "../../../../hooks/useStaff";
import NoPermission from "../../../../components/NoPermission";

const Restock = (props: {
  setDrinks: (d: Drink[]) => void;
  working?: boolean;
}) => {
  const { staff } = useStaff();

  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [drinks, setDrinks] = useState<Record<string, Drink>>({});

  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState<Drink[]>(
    Object.values(drinks),
  );

  function removeItem(uuid: string) {
    if (!drinks[uuid]) return;

    if (drinks[uuid].currentStock > 0) {
      window.alert("Please clear the stock before deleting the item.");
      return;
    }

    const newDrinks = { ...drinks };
    delete newDrinks[uuid];
    setDrinks(newDrinks);
    props.setDrinks(Object.values(newDrinks));
  }

  function updateItem(drink: Partial<Drink>) {
    if (!drink.uuid || !drinks[drink.uuid]) return;

    const d: Record<string, Drink> = {
      ...drinks,
      [drink.uuid]: {
        ...drinks[drink.uuid],
        ...drink,
        updatedAt: moment().format(),
      },
    };

    setDrinks(d);
    props.setDrinks(Object.values(d));
  }

  const searchList = useCallback(
    (value: string) => {
      if (value.trim() === "") {
        setFilterItems(Object.values(drinks));
      } else {
        const filtered = Object.values(drinks).filter((item: Drink) =>
          item.title.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterItems(filtered);
      }
    },
    [drinks],
  );

  useEffect(() => {
    setHasPermission(
      staff !== null &&
        (staff.permissions.includes("*") ||
          staff.permissions.includes("*:stock") ||
          staff.permissions.includes("modify:stock")),
    );
  }, [staff]);
  useEffect(() => {
    searchList(search);
  }, [drinks, search, searchList]);

  useEffect(() => {
    get<Drink[]>("drinks")
      .then((drinkArray) => {
        const sortedDrinkArray = drinkArray.sort(
          (a, b) => b.currentStock - a.currentStock,
        );

        const drinkRecord = sortedDrinkArray.reduce<Record<string, Drink>>(
          (acc, drink) => {
            acc[drink.uuid] = drink;
            return acc;
          },
          {},
        );

        setDrinks(drinkRecord);
      })
      .catch(toastAxiosError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div className={""}>
      {hasPermission ? (
        <>
          <div className={"bg-slate-50 p-3 space-y-3"}>
            <Search onChange={setSearch} />
          </div>
          <ul className={""}>
            {filterItems.map((drink) => (
              <li key={drink.uuid} className={"px-3"}>
                <DrinkItem
                  drink={drink}
                  working={props.working}
                  editable={hasPermission}
                  key={drink.uuid}
                  removeItem={removeItem}
                  updateItem={updateItem}
                  showStock={true}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <NoPermission />
      )}
    </div>
  );
};

export default Restock;
