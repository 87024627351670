import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../Button";
import { copyToClipboard, formatPrice } from "../../utils/validation";
import {
  ACCOUNT_NAME,
  ACCOUNT_NO,
  BANK_NAME,
} from "../../pages/guest/lounge/pages/MakeAPayment";
import Loader from "../Loader";
import { Transaction } from "../../typings/reservation";
import { get, MOCK_PAYMENT } from "../../api/database";
import CountdownTimer from "../CountdownTimer";
import { Instagram, Phone, WhatsApp } from "../../pages/faq/FaqHome";
import { toastError } from "../../utils/toast";

const MAX_SEARCH_MILLI_SECONDS = 20000;

const BankTransfer = ({
  amount,
  onPaymentCompleted,
}: {
  amount: number;
  onPaymentCompleted: (amount: number) => void;
}) => {
  const [searching, setSearching] = useState(false);
  const [code] = useState(generateCode());
  const [shouldSearch, setShouldSearch] = useState(false);
  const [needsHelp, setNeedsHelp] = useState(false);

  function generateCode() {
    const prefix = "IFD";
    const randomPart = Math.floor(1000 + Math.random() * 9000); // Generates a 4-digit random number
    return `${prefix}${randomPart}`;
  }

  // Search function
  // Memoize search function using useCallback
  const search = useCallback(async () => {
    get<Transaction[]>("payments/credits/today")
      .then((transactions) => {
        const totalAmountPaid = transactions
          .filter((transaction) =>
            transaction.narration.toLowerCase().includes(code.toLowerCase()),
          )
          .reduce((sum, transaction) => sum + transaction.amount, 0);

        if (totalAmountPaid >= amount) onPaymentCompleted(totalAmountPaid);
      })
      .catch(() => {
        toastError("An error occurred while trying to get your transaction");
      });
  }, [code, amount, onPaymentCompleted]);

  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (shouldSearch) {
      setSearching(true);

      // Set up the interval to trigger search every 5 seconds
      intervalId.current = setInterval(async () => {
        await search(); // Wait for the current search to complete before continuing
      }, MAX_SEARCH_MILLI_SECONDS / 3);

      // Set up the timeout to stop searching after 30 seconds
      timeoutId.current = setTimeout(() => {
        if (intervalId.current) clearInterval(intervalId.current); // Stop the search interval
        intervalId.current = null;
        setSearching(false); // Set searching to false to stop the process
        setShouldSearch(false); // Reset shouldSearch to allow restart
      }, MAX_SEARCH_MILLI_SECONDS); // Stop after 30 seconds
    } else {
      if (intervalId.current) clearInterval(intervalId.current); // Stop if shouldSearch becomes false
      if (timeoutId.current) clearTimeout(timeoutId.current); // Stop the timeout if shouldSearch becomes false
      intervalId.current = null;
      timeoutId.current = null;
    }

    // Cleanup on component unmount or when dependencies change
    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [shouldSearch, search]); // Run the effect when shouldSearch changes

  return (
    <div className={"p-6 space-y-6"}>
      <p className={"font-thin text-3xl"}>Payment details</p>
      <div className={"rounded-xl space-y-2 border"}>
        <div className={"bg-slate-100 p-6 space-y-6"}>
          <div>
            <p className={"text-md"}>BANK NAME</p>
            <div className={"flex flex-row justify-between items-center"}>
              <p className={"font-extrabold"}>{BANK_NAME}</p>
              <i
                className="bx bx-copy"
                onClick={() => copyToClipboard(BANK_NAME)}
              ></i>
            </div>
          </div>
          <div>
            <p className={"text-md"}>ACCOUNT NO</p>
            <div className={"flex flex-row justify-between items-center"}>
              <p className={"font-extrabold"}>{ACCOUNT_NO}</p>
              <i
                className="bx bx-copy"
                onClick={() => copyToClipboard(ACCOUNT_NO)}
              ></i>
            </div>
          </div>
          <div>
            <p className={"text-md"}>ACCOUNT NAME</p>
            <div className={"flex flex-row justify-between items-center"}>
              <p className={"font-extrabold"}>{ACCOUNT_NAME}</p>
              <i
                className="bx bx-copy"
                onClick={() => copyToClipboard(ACCOUNT_NAME)}
              ></i>
            </div>
          </div>
        </div>
        <div className={"p-6 py-3 space-y-6"}>
          <div>
            <p className={"text-md"}>AMOUNT</p>
            <div className={"flex flex-row justify-between items-center"}>
              <p className={"font-extrabold"}>{formatPrice(amount)}</p>
              <i
                className="bx bx-copy"
                onClick={() => copyToClipboard(String(amount))}
              ></i>
            </div>
          </div>
          <div>
            <p className={"text-md"}>NARRATION/ REFERENCE</p>
            <div
              className={
                "flex flex-row justify-between items-center text-pink-500"
              }
            >
              <p
                className="font-extrabold relative"
                onClick={() => copyToClipboard(code)}
              >
                {code}
                {/* Dashed underline with animation */}
                <span className="absolute border-black bottom-0 left-0 block h-[2px] border-dashed border-t-2 w-0 animate-underline"></span>
              </p>

              <i
                className="bx bx-copy"
                onClick={() => copyToClipboard(String(code))}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {searching && (
        <div className={"w-full text-center space-y-2"}>
          <Loader />
          <p className={""}>Looking for your transaction</p>
          <CountdownTimer
            start={MAX_SEARCH_MILLI_SECONDS / 1000}
            onComplete={() => {
              if (intervalId.current) clearInterval(intervalId.current);
              if (timeoutId.current) clearTimeout(timeoutId.current);
              intervalId.current = null;
              timeoutId.current = null;
              setSearching(false);
              setShouldSearch(false); // Reset shouldSearch to allow restart
            }}
          />
        </div>
      )}
      <Button
        type={"primary"}
        text={"I HAVE MADE THE PAYMENT"}
        block={true}
        disabled={searching}
        onClick={() => setShouldSearch(true)} // Start searching when button is clicked
      />
      <p className={"text-center"}>
        Once you have made the payment using the{" "}
        <span
          onClick={() => copyToClipboard(code)}
          className={
            "underline underline-offset-4 decoration-dashed text-pink-500"
          }
        >
          narration or reference
        </span>{" "}
        code above, kindly click the button above, and your transaction will be
        automatically confirmed
      </p>
      <p
        onClick={() => setNeedsHelp(!needsHelp)}
        className={"underline decoration-dashed underline-offset-4 text-center"}
      >
        Need help?
      </p>
      {needsHelp && (
        <div className={"space-y-3 text-center p-3 bg-slate-100 rounded-xl"}>
          <p>
            If you have made the transfer but the confirmation is not going
            through, please contact us and we can sort this out for you right
            away!
          </p>
          <p>
            {Phone()} {WhatsApp()} {Instagram()}{" "}
          </p>
        </div>
      )}
      {MOCK_PAYMENT && (
        <Button
          type={"secondary"}
          block={true}
          text={"Mock Transfer (1M)"}
          onClick={() => onPaymentCompleted(1000000)}
        />
      )}
    </div>
  );
};

export default BankTransfer;
