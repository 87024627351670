import { post } from "./database";

const RESOURCE_PATH = "email";

export const sendEmail = ({
  to,
  subject,
  html,
  isSecret = false,
}: {
  html: string;
  to: string;
  subject: string;
  isSecret?: boolean;
}): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    post(
      `${RESOURCE_PATH}?to=${to}&subject=${subject}&isSecret=${isSecret}`,
      html,
      {
        headers: { "Content-Type": "text/html" },
      },
    )
      .then(() => resolve())
      .catch(reject);
  });
};
