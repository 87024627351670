import React, { useEffect, useRef, useState } from "react";
import FullPage from "../../../components/FullPage";
import { BANNER_URL, Foods } from "./config/FoodConfig";
import { Drink, KitchenOrder, Menu, MenuType } from "../../../typings/menu";
import FoodPreview from "./pages/FoodPreview";
import { PayWith } from "../../../components/payment/PaymentOption";
import MakePayment from "./pages/MakePayment";
import CreateOrder from "./pages/CreateOrder";
import CategoryPills from "./fragments/CategoryPills";
import ViewBasket from "./fragments/ViewBasket";
import FoodsList from "./fragments/FoodsList";
import AddNotes from "./pages/AddNotes";
import { CustomerInfo } from "../../../utils/memory";
import Basket from "./pages/Basket";
import { toastError } from "../../../utils/toast";
import { get } from "../../../api/database";
import {
  BUSINESS_ADDRESS_LINE_1,
  BUSINESS_ADDRESS_LINE_2,
} from "../../../constants/links";

type CartItem = {
  food: Menu;
  quantity: number;
};

export type Cart = Map<string, CartItem>;

type ModalView =
  | "food"
  | "basket"
  | "payment"
  | "payment-completed"
  | "add notes";

const Kitchen = () => {
  // Stateful
  const [selected, setSelected] = useState<MenuType>();
  const [modalView, setModalView] = useState<ModalView>();
  const [cart, setCart] = useState<Cart>(new Map());
  const [food, setFood] = useState<Menu>();
  const [payWith, setPayWith] = useState<PayWith>();
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [pickUpAtTheHotel, setPickUpAtTheHotel] = useState(true);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [notes, setNotes] = useState<string>();

  const initStatus = useRef(false);
  const [isFixed, setIsFixed] = useState(false);

  const [menus, setMenus] = useState<Menu[]>([]);
  const initMenu = useRef(false);

  const handleScrollToCategory = (category: MenuType) => {
    const element = document.getElementById(`category-${category}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleCategorySelection = (category: MenuType) => {
    setSelected(category);
    handleScrollToCategory(category);
  };

  const handleConfirmAndPay = (payWith: PayWith, amountToPay: number) => {
    setAmountToPay(amountToPay);
    setPayWith(payWith);
    setModalView("payment");
  };

  const handleOnClose = () => {
    setModalView(undefined);
  };

  const handleOnCompleted = () => {
    setCart(new Map());
    setAmountPaid(0);
    setAmountToPay(0);
    setNotes(undefined);
    setPayWith(undefined);
    setFood(undefined);
    setSelected(undefined);
    setModalView(undefined);
    setCustomerInfo(undefined);
  };

  const getNotesByLine = () => {
    if (!notes) return [];

    return notes
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);
  };

  const getKitchenOrder = (
    cart: Cart,
    customerInfo: CustomerInfo,
  ): Omit<KitchenOrder, "id"> => {
    const order: Omit<KitchenOrder, "id"> = {
      cart: [],
      notes: getNotesByLine(),
      paymentInfo: {
        total: amountToPay,
        paid: amountPaid,
        due: Math.max(0, amountToPay - amountPaid),
        discount: Math.abs(amountToPay - amountPaid),
        deposit: amountPaid,
        currency: "NGN",
      },
      withDeliveryFee: deliveryFee,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName,
      emailAddress: customerInfo.emailAddress,
      phoneNumber: customerInfo.phoneNumberLine1,
      delivery: {
        addressLine1: pickUpAtTheHotel
          ? BUSINESS_ADDRESS_LINE_1
          : customerInfo.addressLine1,
        addressLine2: pickUpAtTheHotel
          ? BUSINESS_ADDRESS_LINE_2
          : customerInfo.addressLine2,
      },
    };
    for (let cartItem of Array.from(cart.values())) {
      order.cart.push({
        uuid: cartItem.food.uuid || "",
        title: cartItem.food.title || "",
        quantity: cartItem.quantity,
        rate: cartItem.food.rate,
      });
    }
    return order;
  };

  const getModal = () => {
    switch (modalView) {
      case "food": {
        if (food) {
          return (
            <FoodPreview
              food={food}
              onClose={handleOnClose}
              defaultQty={cart.get(food.uuid)?.quantity || 1}
              onAdd={(qty) => {
                handleAddToCart(food, qty);
                handleOnClose();
              }}
            />
          );
        }
        break;
      }
      case "basket": {
        return (
          <Basket
            confirmAndPay={(
              payWith,
              amountToPay,
              withDeliveryFee,
              pickUpAtTheHotel,
              customerInfo,
            ) => {
              handleConfirmAndPay(payWith, amountToPay);
              setCustomerInfo(customerInfo);
              setDeliveryFee(withDeliveryFee);
              setPickUpAtTheHotel(pickUpAtTheHotel);
            }}
            notes={notes}
            cart={cart}
            onClose={handleOnClose}
            addToCart={handleAddToCart}
            addNotes={handleAddNotes}
          />
        );
      }
      case "add notes": {
        return (
          <AddNotes
            onClose={() => {
              setModalView("basket");
            }}
            notes={notes || ""}
            onSave={(notes) => {
              setNotes(notes);
              setModalView("basket");
            }}
          />
        );
      }
      case "payment": {
        if (payWith && customerInfo) {
          return (
            <MakePayment
              payWith={payWith}
              onClose={() => setModalView(undefined)}
              payload={getKitchenOrder(cart, customerInfo)}
              onPaymentCompleted={(amountPaid) => {
                setAmountPaid(amountPaid);
                setModalView("payment-completed");
              }}
              amount={amountToPay}
            />
          );
        }
        break;
      }
      case "payment-completed": {
        if (
          customerInfo &&
          Array.from(cart.values()).filter((c) => c.quantity > 0).length > 0
        )
          return (
            <CreateOrder
              order={getKitchenOrder(cart, customerInfo)}
              onClose={handleOnClose}
              onCompleted={handleOnCompleted}
            />
          );
      }
    }
  };

  const handleAddToCart = (menu: Menu, quantity: number = 1) => {
    const updatedCart = new Map(cart);

    if (quantity === 0) {
      updatedCart.delete(menu.uuid);
    } else {
      if (updatedCart.has(menu.uuid)) {
        const item = updatedCart.get(menu.uuid);
        if (item) {
          item.quantity = quantity;
        }
      } else {
        updatedCart.set(menu.uuid, { food: menu, quantity: quantity });
      }
    }

    setCart(updatedCart);
  };

  const handleAddNotes = () => {
    setModalView("add notes");
  };

  useEffect(() => {
    if (initMenu.current) return;

    initMenu.current = true;

    get<Drink[]>("drinks")
      .then((drinks) => {
        const menus: Menu[] = [...Foods];

        for (let drink of drinks) {
          menus.push({
            ...drink,
            type: "DRINK",
            thumbnailUrl:
              drink.thumbnailUrl ||
              "https://t4.ftcdn.net/jpg/03/10/01/97/360_F_310019753_1M07s7jaI3tQ3hubPWYLekZ6xx0PWVPN.jpg",
            description: drink.description || drink.title,
          });
        }
        setMenus(menus);
      })
      .catch(() => {
        toastError("Could not get available drinks at the moment.");
      });
  }, []);

  useEffect(() => {
    if (!initStatus.current && selected) {
      handleScrollToCategory(selected);
    }
    initStatus.current = true;
  }, [selected]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.length > 0) setIsFixed(entries[0].intersectionRatio <= 0);
      },
      { threshold: 0 },
    );
    const section1 = document.getElementById("selection-pills");
    if (section1) observer.observe(section1);
    return () => {
      if (section1) observer.unobserve(section1);
    };
  }, []);

  return (
    <>
      <FullPage
        title={"KITCHEN"}
        bannerUrl={BANNER_URL}
        header={
          isFixed ? (
            <div className={"space-y-2 px-1 py-6"}>
              <CategoryPills
                onClick={handleCategorySelection}
                selected={selected}
              />
            </div>
          ) : undefined
        }
        component={
          <div className={"space-y-6 h-full"}>
            <CategoryPills
              onClick={handleCategorySelection}
              selected={selected}
            />
            <FoodsList
              cart={cart}
              foods={menus}
              onClick={(food) => {
                setFood(food);
                setModalView("food");
              }}
            />
          </div>
        }
      />
      <ViewBasket
        cart={cart}
        onClick={() => {
          setModalView("basket");
        }}
      />
      {modalView && getModal()}
    </>
  );
};

export default Kitchen;
