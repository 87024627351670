import React, { useState } from "react";
import FormEntry from "../../../../components/FormEntry";
import { Drink } from "../../../../typings/menu";
import { useStaff } from "../../../../hooks/useStaff";
import NoPermission from "../../../../components/NoPermission";
import { hasPermission } from "../../../../utils/validation";

const CreateDrink = ({
  drinkDto,
  setDrinkDto,
}: {
  drinkDto: Partial<Drink>;
  setDrinkDto: (d: Partial<Drink>) => void;
}) => {
  const [working] = useState(false);
  const { staff } = useStaff();

  const isPermitted = hasPermission(["create:stock"], staff?.permissions);

  return (
    <div className={"p-6 space-y-3"}>
      {!isPermitted && <NoPermission />}
      <div>
        <FormEntry
          title={"Name*"}
          placeholder={"Coca cola light 30cl"}
          value={drinkDto.title || ""}
          type={"text"}
          onChange={(title) => setDrinkDto({ ...drinkDto, title })}
          disabled={working || !isPermitted}
        />
        <div className={"grid grid-cols-2 gap-4"}>
          <FormEntry
            title={"Rate*"}
            placeholder={"1000"}
            value={String(drinkDto.rate) || ""}
            type={"number"}
            onChange={(rate) =>
              setDrinkDto({ ...drinkDto, rate: Number(rate) })
            }
            disabled={working || !isPermitted}
          />
          <FormEntry
            title={"Current stock*"}
            placeholder={"10"}
            value={String(drinkDto.currentStock) || ""}
            type={"number"}
            onChange={(currentStock) =>
              setDrinkDto({ ...drinkDto, currentStock: Number(currentStock) })
            }
            disabled={working || !isPermitted}
          />
        </div>
        <FormEntry
          title={"Description"}
          placeholder={""}
          value={drinkDto.description || ""}
          type={"text"}
          onChange={(description) => setDrinkDto({ ...drinkDto, description })}
          disabled={working || !isPermitted}
        />
        <FormEntry
          title={"Thumbnail url"}
          placeholder={"https://"}
          value={drinkDto.thumbnailUrl || ""}
          type={"text"}
          onChange={(thumbnailUrl) =>
            setDrinkDto({ ...drinkDto, thumbnailUrl })
          }
          disabled={working || !isPermitted}
        />
      </div>
    </div>
  );
};

export default CreateDrink;
