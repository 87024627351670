import React, { useState } from "react";
import moment, { Moment } from "moment";
import { Reservation } from "../../../../typings/reservation";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";
import MonthCalendar from "../../../../components/MonthCalendar";
import { composeBookingDates } from "../../../../utils/room";
import { Uom } from "../../../../typings/rooms";
import Loader from "../../../../components/Loader";
import HourSelector from "../../../../components/HourSelector";
import { BookingDates } from "../Reservations";

interface Interface {
  onChange: (d: { checkIn?: Moment; checkOut?: Moment }) => void;
  checkIn?: Moment;
  checkOut?: Moment;
  onClose: () => void;
  reservations: Reservation[];
  roomUuid: string;
  uom: Uom;
  blockedNights: Moment[];
}

export const MAX_MONTHS_IN_ADVANCE = 12;

const SelectDates = (props: Interface) => {
  const [checkIn, setCheckIn] = useState<Moment | undefined>(props.checkIn);
  const [checkOut, setCheckOut] = useState<Moment | undefined>(props.checkOut);

  const startMonth = moment();
  const monthsToDisplay = MAX_MONTHS_IN_ADVANCE;

  const isDateDisabled = (date: Moment) => {
    return (
      date.isBefore(moment(), "day") ||
      props.blockedNights.some((disabledDate) =>
        date.isSame(disabledDate, "day"),
      )
    );
  };

  function onSelect(selection: Moment) {
    const bookingDates = composeBookingDates(
      selection,
      { checkIn, checkOut },
      props.blockedNights,
    );

    // Update the state with the newly calculated checkIn and checkOut dates
    setCheckIn(bookingDates.checkIn);
    setCheckOut(bookingDates.checkOut);

    props.onChange(bookingDates);
  }

  function getDates() {
    if (!props.uom) return <Loader />;
    switch (props.uom) {
      case "night":
        return (
          <>
            {Array.from({ length: monthsToDisplay }).map((_, index) => (
              <MonthCalendar
                key={index}
                monthMoment={moment(startMonth).add(index, "months")}
                onSelect={onSelect}
                isDateDisabled={isDateDisabled}
                checkIn={checkIn}
                checkOut={checkOut}
              />
            ))}
          </>
        );
      case "hour": {
        return (
          <HourSelector
            isDateDisabled={isDateDisabled}
            startMonth={startMonth}
            onChange={(d: BookingDates) => {
              setCheckOut(d.checkOut);
              setCheckIn(d.checkIn);
              props.onChange(d);
            }}
            checkIn={checkIn}
            checkOut={checkOut}
          />
        );
      }
      case "nos": {
        return <p>Unsupported selection</p>;
      }
    }
  }

  return (
    <ActivityModal
      title={"Select dates"}
      body={<div className="w-full px-6 p-3">{getDates()}</div>}
      footer={<Button text={"Save"} block={true} onClick={props.onClose} />}
      onClose={props.onClose}
    />
  );
};

export default SelectDates;
