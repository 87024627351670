import React, { useRef, useState } from "react";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";
import {
  toastAxiosError,
  toastError,
  toastSuccess,
} from "../../../../utils/toast";
import { render } from "@react-email/components";
import { sendEmail } from "../../../../api/email";
import { Customer } from "../../../../typings/customer";
import { Reservation } from "../../../../typings/reservation";
import { defaultAxios, patch, put } from "../../../../api/database";
import SignatureCapture from "../../../../components/SignatureCapture";
import MeansOfIDUpload from "../../../../components/MeansOfIDUpload";
import TermsOfServiceMail from "../../../../emails/TermsOfServiceMail";
import { Staff } from "../../../../typings/permissions";
import Loader from "../../../../components/Loader";
import RentalContract from "./RentalContract";
import moment from "moment";

// Utility to convert dataURL to Blob
const dataUrlToBlob = (dataUrl: string): Blob => {
  const parts = dataUrl.split(",");
  const mimeType = parts[0].match(/:(.*?);/)?.[1] || "image/png";
  const byteString = atob(parts[1]);
  const arrayBuffer = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeType });
};

const CheckInCustomer = ({
  customer,
  reservation,
  setReservation,
  onClose,
  staff,
}: {
  customer: Customer;
  reservation: Reservation;
  setReservation: (d: Reservation) => void;
  onClose: () => void;
  staff: Staff;
}) => {
  const [signature, setSignature] = useState<string>();
  const [meansOfId, setMeansOfId] = useState<File>();

  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [completingCheckinIn, setCompletingCheckinIn] = useState(false);

  const contractRef = useRef(null);

  const uploadFiles = async (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      if (!meansOfId || !signature || !contractRef.current) {
        reject("Please select both the Means of ID and the Signature.");
        return;
      }

      const signatureBlob = dataUrlToBlob(signature);

      put<
        { idContentType: string; signatureContentType: string },
        { meansOfIdentification: string; signature: string }
      >(`reservation-files/${reservation.id}`, {
        idContentType: meansOfId.type,
        signatureContentType: signatureBlob.type,
      })
        .then((response) => {
          defaultAxios
            .put(response.meansOfIdentification, meansOfId, {
              headers: { "Content-Type": meansOfId.type },
            })
            .then(() => {
              defaultAxios
                .put(response.signature, signatureBlob, {
                  headers: { "Content-Type": signatureBlob.type },
                })
                .then(() => {
                  sendEmail({
                    to: "ifemideestates@gmail.com",
                    html: `${reservation.id} checked in - ${moment().format("dddd Do, MMMM YYYY hh:mm A")}`,
                    isSecret: false,
                    subject: `${reservation.id} checked in`,
                  })
                    .then(resolve)
                    .catch(resolve);
                })
                .catch(() => reject("Failed to upload rental contract"));
            })
            .catch(() => reject("Failed to upload means of identification"));
        })
        .catch(() => reject("Failed to get upload directive"));
    });
  };

  async function checkInCustomer() {
    if (!reservation) {
      toastError("ERROR: Invalid challenge code, please try again.");
      return;
    }

    setCompletingCheckinIn(true);

    uploadFiles()
      .then(() => {
        patch<Partial<Reservation>>("reservations", {
          ...reservation,
          status: "CHECKED_IN",
        })
          .then(() => {
            setReservation({ ...reservation, status: "CHECKED_IN" });
            toastSuccess("Customer checked in");
            onClose();
          })
          .catch(toastAxiosError);
      })
      .catch(toastError)
      .finally(() => setCompletingCheckinIn(false));
  }

  if (showTermsOfService) {
    return (
      <ActivityModal
        title={"Terms of service"}
        body={
          <RentalContract
            setSignature={setSignature}
            signature={signature}
            customer={customer}
          />
        }
        footer={
          <Button
            text={"Send via email"}
            block={true}
            onClick={() => {
              render(
                <TermsOfServiceMail
                  staff={staff}
                  customerFirstName={customer.firstName}
                  previewText={""}
                />,
              )
                .then((html) => {
                  sendEmail({
                    to: customer.emailAddress,
                    html,
                    subject: "Our terms of service",
                  })
                    .then(() => toastSuccess("Email sent"))
                    .catch(toastAxiosError);
                })
                .catch(toastError);
            }}
          />
        }
        onClose={() => setShowTermsOfService(false)}
      />
    );
  }

  return (
    <ActivityModal
      title={"Check in"}
      body={
        <div className={"p-6 space-y-6"}>
          <div className={"space-y-3"}>
            <p className={"text-xs"}>MEANS OF IDENTIFICATION</p>
            <MeansOfIDUpload onChange={setMeansOfId} />
          </div>
          <div className={"space-y-3"}>
            <p className={"text-xs"}>CUSTOMER SIGNATURE</p>
            <SignatureCapture
              onSave={setSignature}
              existingDataUrl={signature}
            />
          </div>
          <Button
            disabled={completingCheckinIn}
            text={"By continuing, you have accepted our terms of service"}
            onClick={() => setShowTermsOfService(!showTermsOfService)}
          />
          <div className={"space-y-6 p-6"}>
            <p className={"text-xs"}>PREVIEW</p>
            <div className={"p-6 bg-slate-100 rounded-lg"} ref={contractRef}>
              <RentalContract
                customer={customer}
                signature={signature}
                setSignature={setSignature}
              />
            </div>
          </div>
        </div>
      }
      footer={
        <div className={"w-full space-y-6"}>
          {completingCheckinIn && <Loader size={4} />}
          <Button
            block={true}
            text={"Check in"}
            onClick={checkInCustomer}
            disabled={!signature || !meansOfId || completingCheckinIn}
          />
        </div>
      }
      onClose={onClose}
    />
  );
};

export default CheckInCustomer;
