import React from "react";

const Button = ({
  onClick = () => {},
  type = "primary",
  text,
  disabled,
  block = false,
}: {
  onClick?: () => void;
  type?: "primary" | "secondary";
  text: string;
  disabled?: boolean;
  block?: boolean;
}) => {
  const styles = {
    primary: "bg-pink-500 text-white border border-pink-500",
    secondary: "bg-white border",
  };
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`font-semibold shadow-xl  px-6 ${disabled ? "opacity-50" : ""} ${styles[type]} ${block ? "w-full py-4 rounded-full" : "w-fit py-2 rounded-lg"}`}
    >
      {text}
    </button>
  );
};

export default Button;
