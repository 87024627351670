import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../../api/database";
import { TransactionRequest } from "../../typings/transaction";
import { Room } from "../../typings/rooms";
import CompleteReservation, {
  CompleteReservationRequest,
  NewCustomerData,
  NewReservationData,
} from "../visitor/reservation/pages/CompleteReservation";
import { AxiosError } from "axios";
import LottieAnimation from "../../components/lottie/LottieAnimation";
import CreateOrder from "../guest/kitchen/pages/CreateOrder";
import { KitchenOrder } from "../../typings/menu";

export type PayStackRoomPayload = Omit<CompleteReservationRequest, "room"> & {
  roomUuid: string;
};

export type PayStackLoungePayload = { customerUuid: string };

export type PayStackKitchenPayload = Omit<KitchenOrder, "id">;

export type PayStackPayload =
  | PayStackRoomPayload
  | PayStackLoungePayload
  | PayStackKitchenPayload;

const PaystackPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [reference] = useState<string | null>(
    new URLSearchParams(location.search).get("reference"),
  );

  const [roomData, setRoomData] = useState<CompleteReservationRequest>();

  const [kitchenOrder, setKitchenOrder] = useState<Omit<KitchenOrder, "id">>();

  const init = useRef(false);

  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (init.current) return;

    init.current = true;

    if (reference) {
      get<TransactionRequest>("paystack/transaction/verify/" + reference)
        .then((response) => {
          if (response.verifyResponse && response.verifyResponse.status) {
            if (response.type === "ROOM") {
              const {
                customer,
                reservation,
                roomUuid,
              }: {
                reservation: NewReservationData;
                customer: NewCustomerData;
                roomUuid: string;
              } = JSON.parse(response.orderJson) as PayStackRoomPayload;

              get<Room>("rooms/" + roomUuid)
                .then((room) => {
                  setRoomData({
                    reservation,
                    customer,
                    room,
                  });
                })
                .catch(setError);
            } else if (response.type === "FOOD") {
              const order = JSON.parse(
                response.orderJson,
              ) as PayStackKitchenPayload;
              setKitchenOrder(order);
            } else
              setError(
                new AxiosError(
                  "Could not verify this payment. Error code [700]",
                ),
              );
          } else
            setError(
              new AxiosError("Could not verify this payment. Error code [701]"),
            );
        })
        .catch(setError);
    } else
      setError(
        new AxiosError("Could not verify this payment. Error code [702]"),
      );
  }, [reference]);

  if (roomData)
    return (
      <CompleteReservation
        customer={roomData.customer}
        reservation={roomData.reservation}
        room={roomData.room}
      />
    );

  if (kitchenOrder)
    return (
      <CreateOrder
        order={kitchenOrder}
        onClose={() => navigate("/kitchen")}
        onCompleted={() => navigate("/kitchen")}
      />
    );

  if (error)
    return (
      <>
        <LottieAnimation type={"error"} className={"w-full aspect-square"} />
        <p className={"font-thin text-xl text-center"}>{error.message}</p>
      </>
    );

  return <Loader />;
};

export default PaystackPayment;
