import React, { useState } from "react";
import { Staff } from "../typings/permissions";
import { JWT_KEY } from "./HostAuthenticator";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const StaffHeaderProfile = ({ staff }: { staff: Staff }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function logOut() {
    localStorage.removeItem(JWT_KEY);
    navigate("/");
  }

  return (
    <div className={"flex flex-row space-x-1 items-center relative"}>
      <button className={"h-8 w-8"}>
        <img
          onClick={() => setOpen(!open)}
          src={staff.thumbnailUrl}
          alt={""}
          className={"w-full h-full rounded-full"}
        />
      </button>
      <button onClick={() => setOpen(!open)}>
        <i className="bx bx-chevron-down"></i>
      </button>
      {open && (
        <div
          className={
            "absolute top-9 right-0 bg-white shadow-xl rounded-lg w-48 text-sm space-y-3 overflow-hidden"
          }
        >
          <ul className={"space-y-3 p-3 pb-0"}>
            <li>
              <span className={"mr-1"}>
                <i className="bx bxs-dashboard"></i>
              </span>
              <a href={"/host"}>Dashboard</a>
            </li>
            <li>
              <a href={"/host/calendar"}>
                <span className={"mr-1"}>
                  <i className="bx bx-calendar-alt"></i>
                </span>
                Calendar
              </a>
            </li>
            <hr />
            <li>
              <span className={"mr-1"}>
                <i className="bx bx-briefcase-alt"></i>
              </span>
              <a href={"/host/reservations"}>Reservations</a>
            </li>
            <li>
              <span className={"mr-1"}>
                <i className="bx bx-user"></i>
              </span>
              <a href={"/host/customers"}>Customers</a>
            </li>
            <hr />
            <li>
              <span className={"mr-1"}>
                <i className="bx bx-drink"></i>
              </span>
              <a href={"/host/menu"}>Stock</a>
            </li>
            <li>
              <span className={"mr-1"}>
                <i className="bx bx-receipt"></i>
              </span>
              <a href={"/host/invoice"}>Invoice</a>
            </li>
            <hr />
            <li>
              <span className={"mr-1"}>
                <i className="bx bxs-log-out-circle"></i>
              </span>
              <button onClick={logOut}>Log out</button>
            </li>
          </ul>
          <div className={"text-xs bg-slate-100 p-3"}>
            <p className={"italic text-xs"}>
              {staff.firstName} {staff.lastName}
            </p>
            <p>{moment().format("dd. Do MMM., yy")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffHeaderProfile;
