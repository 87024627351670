import moment from "moment-timezone";
import { BookingDates } from "../Reservations";
import {
  ExtendedGuests,
  Reservation,
  Stats,
} from "../../../../typings/reservation";
import {
  NewCustomerData,
  NewReservationData,
} from "../pages/CompleteReservation";
import { Customer } from "../../../../typings/customer";
import { Uom } from "../../../../typings/rooms";
import { Moment } from "moment";

export const buildReservation = (
  roomUuid: string,
  amountPaid: number,
  grandTotal: number,
  coupon: number,
  uom: Uom,
  dates: BookingDates,
  guests: ExtendedGuests,
): NewReservationData => {
  return {
    bookings: [
      {
        checkInDateTime: moment(dates.checkIn!!)
          .tz("Africa/Lagos")
          .set({
            hour: uom === "night" ? 15 : dates.checkIn!!.hour(),
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format(),
        checkOutDateTime: moment(dates.checkOut!!)
          .tz("Africa/Lagos")
          .set({
            hour: uom === "night" ? 12 : dates.checkOut!!.hour(),
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format(),
        roomUuid: roomUuid,
        guests: {
          adults: guests.adults,
          children: guests.children,
        },
      },
    ],
    paymentInfo: {
      paid: amountPaid,
      deposit: 0,
      due: Math.max(0, grandTotal - amountPaid),
      total: grandTotal,
      discount: coupon,
      currency: "NGN",
    },
    specialRequests: [
      "Pets: " + guests.pets,
      "Infant " + guests.infants,
      "Children " + guests.children,
    ],
  };
};

export const validCustomer = (customer: Partial<Customer>): boolean => {
  return (
    Boolean(customer.salutation) &&
    Boolean(customer.firstName) &&
    Boolean(customer.lastName) &&
    Boolean(customer.phoneNumberLine1) &&
    Boolean(customer.addressLine1) &&
    Boolean(customer.addressLine2)
  );
};

export const buildCustomer = (customer: Partial<Customer>): NewCustomerData => {
  return {
    salutation: customer.salutation || "",
    firstName: customer.firstName || "",
    lastName: customer.lastName || "",
    phoneNumberLine1: customer.phoneNumberLine1 || "",
    phoneNumberLine2: customer.phoneNumberLine2 || "",
    emailAddress: customer.emailAddress || "",
    addressLine1: customer.addressLine1 || "",
    addressLine2: customer.addressLine2 || "",
    thumbnailUrl: customer.thumbnailUrl || "",
  };
};

export const getQty = (dates: BookingDates, uom: Uom) => {
  if (!dates.checkIn || !dates.checkOut) {
    return 0;
  }

  if (uom === "hour") return dates.checkOut.diff(dates.checkIn, "hours");

  return dates.checkOut.diff(dates.checkIn, "days");
};

export const getQtyString = (qty: number, uom: Uom) => {
  return `${qty} ${qty > 1 ? uom + "s" : uom}`;
};

// Helper function to check if a date falls in December using Moment.js
export function isDateInDecember(date: Moment) {
  if (!date) return false; // Handle null/undefined dates
  const month = moment(date).month(); // moment().month() returns 0 for January, 11 for December
  return month === 11; // 11 corresponds to December
}

export function getStatistics(reservations: Reservation[]): Stats {
  const result: Stats = {
    occupancy: {},
  };

  reservations
    .filter((reservation) => reservation.status !== "CANCELLED")
    .forEach((reservation) => {
      reservation.bookings.forEach((booking) => {
        const checkInDateTime = moment(booking.checkInDateTime); // Parse check-in date
        const checkOutDateTime = moment(booking.checkOutDateTime); // Parse check-out date
        const nights = checkOutDateTime.diff(checkInDateTime, "day");

        for (let i = 0; i <= nights; i++) {
          const currentDate = checkInDateTime.clone().add(i, "day");
          const monthKey = currentDate.format("YYYY-MM");

          result.occupancy = {
            ...result.occupancy,
            [monthKey]: {
              ...result.occupancy[monthKey],
              nights: (result.occupancy[monthKey]?.nights || 0) + 1,
              rooms: {
                ...result.occupancy[monthKey]?.rooms,
                [booking.roomUuid]:
                  (result.occupancy[monthKey]?.rooms[booking.roomUuid] || 0) +
                  1,
              },
            },
          };
        }
      });
    });

  return result;
}
