import React, { useCallback, useEffect, useState } from "react";
import { Drink } from "../../../../typings/menu";
import { formatPrice } from "../../../../utils/validation";
import { DrinkAction } from "../Menu";
import DrinkItem from "./DrinkItem";
import DropdownButton from "../../../../components/DropdownButton";
import Search from "../../../../components/Search";

const DrinkList = ({
  editor = false,
  action,
  drinks,
}: {
  editor?: boolean;
  action: (action: DrinkAction, drink?: Drink) => boolean;
  listVersion?: string;
  drinks: Drink[];
}) => {
  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState<Drink[]>(drinks);

  const handleSearchChange = (data: string) => {
    setSearch(data);
  };

  const searchList = useCallback(
    (value: string) => {
      if (value.trim() === "") {
        setFilterItems(drinks.sort((a, b) => b.currentStock - a.currentStock));
      } else {
        const filtered = drinks.filter((item: Drink) =>
          item.title.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterItems(
          filtered.sort((a, b) => b.currentStock - a.currentStock),
        );
      }
    },
    [drinks],
  );

  useEffect(() => {
    searchList(search);
  }, [drinks, search, searchList]);

  const value = drinks.reduce(
    (total, drink) => total + drink.currentStock * drink.rate,
    0,
  );

  return (
    <div className={"text-sm space-y-3"}>
      <div className={"flex flex-row justify-between items-center text-sm"}>
        {editor && (
          <>
            <p>
              <span className={"font-extrabold"}>
                DRINKS ({formatPrice(value)})
              </span>
            </p>
            <DropdownButton
              onClick={(id) => action(id as DrinkAction)}
              items={[
                { title: "Restock", id: "restock" },
                { title: "Create", id: "create" },
              ]}
            />
          </>
        )}
      </div>
      <Search onChange={handleSearchChange} />
      <div className={"bg-white rounded-xl p-3"}>
        {filterItems.map((drink) => (
          <DrinkItem
            key={drink.uuid}
            drink={drink}
            editable={false}
            showStock={editor}
          />
        ))}
      </div>
    </div>
  );
};

export default DrinkList;
