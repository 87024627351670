import React from "react";
import { Img, Section, Text } from "@react-email/components";
import { Contact } from "./IfemideFooter";
import { Staff } from "../../typings/permissions";

const HostSignature = ({ staff }: { staff: Staff }) => {
  return (
    <Section>
      <Text style={{ margin: 0 }} className={""}>
        Warm regards
      </Text>
      <br />
      <Img
        alt={"Ifemide Host Thumbnail Photo"}
        height={50}
        width={50}
        className={"contain-content rounded-full m-0 p-0"}
        src={staff.thumbnailUrl}
      ></Img>
      <Text className={"m-0 p-0 mb-0 font-extrabold"}>{staff.displayName}</Text>
      <Text className={"m-0 p-0 mb-0 text-pink-500"}>{staff.role}</Text>
      <Contact />
    </Section>
  );
};

export default HostSignature;
