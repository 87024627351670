import React, { useState } from "react";
import FullPage from "../../../components/FullPage";
import { GuestLink } from "../../../constants/links";
import DrinkMenu from "./drinks/DrinkMenu";

export type DrinkAction = "delete" | "create" | "patch" | "restock";

const Menu = ({
  back = GuestLink,
  editor = false,
}: {
  back?: string;
  editor?: boolean;
}) => {
  const [view] = useState<"drink" | "food">("drink");

  const getContent = () => {
    if (view === "drink") return <DrinkMenu editor={editor} />;
    return null;
  };

  return (
    <FullPage
      bannerUrl={
        "https://png.pngtree.com/thumb_back/fh260/back_our/20190625/ourmid/pngtree-icy-summer-fruit-drink-poster-banner-image_256693.jpg"
      }
      title={"FOOD AND DRINKS"}
      back={back}
      component={
        <div>
          <>
            {!editor && (
              <div
                className={
                  "bg-white p-3 px-6 rounded-xl w-full mb-4 flex flex-row space-x-3"
                }
              >
                <div className={"h-8 w-8 overflow-hidden rounded-full"}>
                  <img
                    alt={""}
                    className={"object-cover"}
                    src={
                      "https://smokeyjollof.com/wp-content/uploads/2022/07/Ey9RpckWQAIzcXj.jpg"
                    }
                  />
                </div>
                <div>
                  <p className={"font-extrabold"}>
                    Need food or feeling hungry?
                  </p>
                  <p>
                    Our kitchen is opened, you can place your order{" "}
                    <span>
                      <a
                        href={"/kitchen"}
                        className={
                          "text-pink-500 underline underline-offset-4 decoration-dashed"
                        }
                      >
                        here
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            )}
            {getContent()}
          </>
        </div>
      }
    />
  );
};

export default Menu;
