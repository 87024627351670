import React from "react";
import TermsOfService from "../../../terms/TermsOfService";
import SignatureCapture from "../../../../components/SignatureCapture";
import moment from "moment/moment";
import { Customer } from "../../../../typings/customer";

const RentalContract = ({
  customer,
  signature,
  setSignature = () => {},
}: {
  customer: Customer;
  signature?: string;
  setSignature?: (d: string) => void;
}) => {
  return (
    <div>
      <TermsOfService />
      <div className={"p-6 space-y-3"}>
        <SignatureCapture onSave={setSignature} existingDataUrl={signature} />
        <div>
          <p className={"font-bold"}>
            {customer.firstName} {customer.lastName}
          </p>
          <p>{moment().format("dddd Do, MMMM YYYY hh:mm A")}</p>
        </div>
      </div>
    </div>
  );
};

export default RentalContract;
