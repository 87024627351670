import React, { useEffect, useRef, useState } from "react";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";
import { AxiosError } from "axios";
import LottieAnimation from "../../../../components/lottie/LottieAnimation";
import OrderResult from "../../../../components/OrderResult";
import Loader from "../../../../components/Loader";
import { post } from "../../../../api/database";
import { Instagram, Phone, WhatsApp } from "../../../faq/FaqHome";
import { NewKitchenOrder } from "../../../../typings/menu";
import { render } from "@react-email/components";
import { sendEmail } from "../../../../api/email";
import ConfirmedKitchenOrder from "../../../../emails/ConfirmedKitchenOrder";

export interface CreateKitchenOrderData {
  order: NewKitchenOrder;
  onCompleted: () => void;
  onClose: () => void;
}

const CreateOrder = ({
  order,
  onCompleted,
  onClose,
}: CreateKitchenOrderData) => {
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [orderId, setOrderId] = useState<string>();

  // Use a ref to track whether the effect has run
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true; // Set to true to prevent future executions

      post<any, { id: string }>("restaurants/alagbaka/orders", order)
        .then(({ id }) => {
          render(<ConfirmedKitchenOrder order={{ ...order, id }} />)
            .then((html) => {
              sendEmail({
                to: order.emailAddress,
                subject: "We are now preparing your meal!",
                html,
              })
                .then(() => {
                  setOrderId(id);
                  setCompleted(true);
                })
                .catch(setError);
            })
            .catch(setError);
        })
        .catch(setError);
    }
  }, [order]); // Only track `order` as dependency, assuming it's stable

  const handleGoBack = () => {
    if (error) {
      onClose();
    } else {
      onCompleted();
    }
  };

  const getContent = () => {
    if (error) {
      return (
        <div>
          <LottieAnimation type={"error"} className={"w-full aspect-square"} />
          <div className={"px-9 text-center space-y-3"}>
            <p className={"text-3xl text-center font-thin"}>
              Sorry, we could not complete your order{" "}
            </p>
            <p className={"font-extrabold px-6"}>
              Kindly try again or you can also reach us directly via {Phone()},{" "}
              {Instagram()} or {WhatsApp()}
            </p>
          </div>
        </div>
      );
    }

    if (completed && orderId) {
      return (
        <div>
          <LottieAnimation
            type={"cooking"}
            className={"w-full aspect-square"}
          />
          <OrderResult
            id={orderId}
            title={"We are working on it!"}
            subtitle={"YOUR ORDER ID"}
          />
        </div>
      );
    }

    return (
      <div>
        <LottieAnimation type={"walking"} className={"w-full aspect-square"} />
        <Loader />
      </div>
    );
  };

  return (
    <ActivityModal
      title={"Finalizing your order"}
      body={<div>{getContent()}</div>}
      footer={
        (completed || error) && (
          <Button
            type={"primary"}
            text={"Back to the kitchen"}
            block={true}
            onClick={handleGoBack}
            disabled={!hasRun.current}
          />
        )
      }
      onClose={handleGoBack}
      noClose={true}
    />
  );
};

export default CreateOrder;
