import React from "react";
import { terms } from "./terms";
import LogoRider from "../../emails/shared/LogoRider";

const TermsOfService = () => {
  return (
    <div className={"space-y-6 p-6 overflow-y-scroll h-screen bg-white"}>
      <LogoRider />
      <p className={"text-3xl font-bold"}>Terms of Service</p>
      <div className={"space-y-6"}>
        {terms.map((term) => (
          <div className={"space-y-3"} key={term.title}>
            <p className={"font-bold"}>{term.title.toUpperCase()}</p>
            {term.body.map((body, i) => (
              <p key={i}>{body}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;
