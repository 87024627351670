import React from "react";
import { ModalView, Validation } from "../Reservations";
import Button from "../../../../components/Button";
import { PayWith } from "../../../../components/payment/PaymentOption";

const ProceedToPay = ({
  setModal,
  validation,
  validate,
  payWith,
  valid,
  scrollTo,
}: {
  setModal: (d: ModalView) => void;
  validation?: Validation;
  validate: () => Validation;
  scrollTo: (d: ModalView) => void;
  payWith: PayWith;
  valid: boolean;
}) => {
  const scrollToView = (validation: Validation) => {
    if (!validation.valid) {
      if (validation.details) {
        scrollTo("customer data");
      } else if (validation.dates) {
        scrollTo("select dates");
      }
    }
  };

  return (
    <section
      className={
        "flex flex-col justify-center bg-white space-y-6 p-9 rounded-lg"
      }
    >
      <p className={"text-xs"}>
        By proceeding with this reservation and clicking the button below, you
        agree to our{" "}
        <span
          onClick={() => setModal("ground rules")}
          className={"underline font-semibold"}
        >
          ground rules
        </span>
        ,{" "}
        <span
          onClick={() => setModal("cancellation policy")}
          className={"underline font-semibold"}
        >
          refund
        </span>
        , and{" "}
        <span
          onClick={() => setModal("cancellation policy")}
          className={"underline font-semibold"}
        >
          cancellation policies
        </span>
        . Guests are responsible for any damages caused to the property during
        their stay and will be charged accordingly. Reservations are subject to
        availability and are not guaranteed until confirmed.
      </p>
      <div className={"space-y-3 flex flex-col items-center justify-center"}>
        <Button
          type={"primary"}
          text={"Confirm and pay"}
          block={true}
          onClick={() => {
            if (valid && payWith) setModal("confirm and pay");
            else {
              const validation = validate();
              scrollToView(validation);
            }
          }}
        />
        <p className={"text-xs text-gray-500 text-center"}>OR</p>
        <button
          className={"text center underline decoration-dashed mx-auto"}
          onClick={() => {
            if (valid) setModal("send an enquiry");
            else {
              const validation = validate();
              scrollToView(validation);
            }
          }}
        >
          Send us an enquiry instead
        </button>
        {validation && !validation.valid && (
          <p className={"pt-3 text-sm text-pink-500"}>
            {"Please check entries above for possible errors*"}
          </p>
        )}
      </div>
    </section>
  );
};

export default ProceedToPay;
