import { SalonCategory, SalonServiceType } from "../../../../typings/salon";

export const CategoryConfig: Record<
  SalonServiceType,
  { title: string; thumbnailUrl: string }
> = {
  HAIR_STYLING: {
    title: "Hair Styling",
    thumbnailUrl: "https://cdn-icons-png.flaticon.com/512/7686/7686607.png",
  },
  HAIR_CUT: {
    title: "Hair Cut",
    thumbnailUrl: "https://cdn-icons-png.flaticon.com/512/7686/7686607.png",
  },
  PEDICURE: {
    title: "Pedicure",
    thumbnailUrl: "https://cdn-icons-png.flaticon.com/512/7686/7686607.png",
  },
  MANICURE: {
    title: "Manicure",
    thumbnailUrl: "https://cdn-icons-png.flaticon.com/512/7686/7686607.png",
  },
};

export const SalonCategories: SalonCategory[] = [
  {
    title: "Hair Styling",
    description: "string",
    thumbnailUrl: "",
    type: "HAIR_STYLING",
  },
];
