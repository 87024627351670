import React, { useEffect, useState } from "react";
import { getAvailableRooms } from "../../../../api/rooms";
import { Room } from "../../../../typings/rooms";
import { Customer } from "../../../../typings/customer";
import {
  DEFAULT_ADULTS,
  DEFAULT_CHECK_IN_HOURS_UTC,
  DEFAULT_CHECK_OUT_HOURS_UTC,
  DEFAULT_CHILDREN,
} from "../fragments/shared/SingleBooking";
import SelectCustomer from "../fragments/SelectCustomer";
import Price, { DEFAULT_PAID, DEFAULT_TOTAL } from "../fragments/shared/Price";
import {
  Booking,
  CreateDto,
  Reservation,
} from "../../../../typings/reservation";
import ConfirmedReservations from "../../../../emails/ConfirmedReservations";
import { createReservation } from "../../../../api/reservation";
import { render } from "@react-email/components";
import { sendEmail } from "../../../../api/email";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import FullPage from "../../../../components/FullPage";
import timezone from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../../../../constants/links";
import Reserve from "../fragments/shared/Reserve";
import BookingsEditor from "../fragments/BookingsEditor";
import { useStaff } from "../../../../hooks/useStaff";
import { hasPermission } from "../../../../utils/validation";

const EMPTY_BOOKING: Partial<Booking> = {
  checkInDateTime: timezone
    .tz(DEFAULT_TIMEZONE)
    .set({
      hour: DEFAULT_CHECK_IN_HOURS_UTC,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISOString(),
  checkOutDateTime: timezone
    .tz(DEFAULT_TIMEZONE)
    .add(1, "days")
    .set({
      hour: DEFAULT_CHECK_OUT_HOURS_UTC,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISOString(),
  guests: { adults: DEFAULT_ADULTS, children: DEFAULT_CHILDREN },
  roomUuid: undefined,
};

const AddReservation = () => {
  const { staff } = useStaff();
  const [rooms, setRooms] = useState<Room[]>([]);

  const [customer, setCustomer] = useState<Customer | undefined>();
  const [total, setTotal] = useState(DEFAULT_TOTAL);
  const [paid, setPaid] = useState(DEFAULT_PAID);
  const [bookings, setBookings] = useState<Partial<Booking>[]>([EMPTY_BOOKING]);

  const [validate, setValidate] = useState(false);

  const [saveState, setSaveState] = useState<
    | {
        messages: string[];
        done: boolean;
        error: boolean;
        reservation?: { id: number };
      }
    | undefined
  >(undefined);

  const roomsMap = new Map(
    rooms.map((room) => [room.uuid, { ...room, id: room.uuid }]),
  );

  function reset(): void {
    setSaveState(undefined);
    setBookings([{ ...EMPTY_BOOKING }]);
    setCustomer(undefined);
    setValidate(false);
    setTotal(DEFAULT_TOTAL);
    setPaid(DEFAULT_PAID);
  }

  async function onReserve(reservation: CreateDto<Reservation>) {
    if (reservation && customer && staff) {
      let currentState = {
        ...saveState,
        done: false,
        error: false,
        messages: ["Getting reservation number..."],
      };
      setSaveState(currentState);

      await createReservation(reservation)
        .then(async (item: Reservation) => {
          currentState = {
            ...currentState,
            done: false,
            error: false,
            messages: [
              ...currentState.messages,
              "Sending reservation confirmation email...",
            ],
            reservation: item,
          };
          setSaveState(currentState);

          await render(
            <ConfirmedReservations
              staff={staff}
              reservation={item}
              rooms={roomsMap}
              customer={customer!}
            />,
          )
            .then(async (html) => {
              await sendEmail({
                to: customer.emailAddress,
                html,
                subject: "Reservation Confirmation",
              })
                .then(() => {
                  currentState = {
                    ...currentState,
                    done: true,
                    error: false,
                    messages: [
                      ...currentState.messages,
                      "Email sent to customer.",
                      "Reservation completed!",
                    ],
                  };
                  setSaveState(currentState);
                })
                .catch(() => {
                  currentState = {
                    ...currentState,
                    done: true,
                    error: true,
                    messages: [
                      ...currentState.messages,
                      "Failed to send email to the customer",
                    ],
                  };
                  setSaveState(currentState);
                });
            })
            .catch(() => {
              currentState = {
                ...currentState,
                done: true,
                error: true,
                messages: [
                  ...currentState.messages,
                  "Failed to generate dynamic email",
                ],
              };
              setSaveState(currentState);
            });
        })
        .catch((e) => {
          currentState = {
            ...currentState,
            done: true,
            error: true,
            messages: [
              ...currentState.messages,
              "Failed to save reservation to our database",
              `Reason: ${e.errors || e.message}`,
            ],
          };
          setSaveState(currentState);
        });
    } else {
      setSaveState({
        ...saveState,
        error: true,
        done: true,
        messages: ["An unexpected error occurred, please try again"],
      });
    }
  }

  useEffect(() => {
    getAvailableRooms()
      .then(setRooms)
      .catch(() => {
        window.alert("Failed to get available rooms.");
      });
  }, []);

  const showSaveState = () => {
    if (!saveState)
      return (
        <p className={"text-2xl"}>{"An unexpected error has occurred."}</p>
      );
    return (
      <div
        className={
          "w-full h-screen flex flex-col justify-center bg-white px-9 relative"
        }
      >
        {saveState.done && (
          <Link to={"/host"}>
            <button
              disabled={!saveState.done}
              className={"absolute top-9 left-9 text-4xl"}
            >
              <i className={"bx bx-arrow-back"}></i>
            </button>
          </Link>
        )}

        {!saveState.done && (
          <div className={"w-fit h-fit"}>
            <Loader />
          </div>
        )}

        <p className={"text-5xl font-thin my-3"}>
          {!saveState.done
            ? "Working on it..."
            : !saveState.error
              ? "Completed"
              : "Failed"}
        </p>
        {saveState.messages.map((message) => (
          <p key={message} className={"text-gray-700"}>
            {message}
          </p>
        ))}
        {saveState.reservation && (
          <div>
            <p
              className={
                "my-3 px-6 py-1 rounded-full font-thin bg-pink-500 text-white text-4xl w-fit"
              }
            >{`#${saveState.reservation?.id}`}</p>
          </div>
        )}
      </div>
    );
  };

  function omitListUuidFromBooking(booking: Booking & any) {
    const { listUuid, ...rest } = booking; // Omit listUuid
    return rest; // Return remaining properties
  }

  if (saveState) return showSaveState();

  return (
    <FullPage
      bannerUrl={
        "https://img.freepik.com/free-vector/modern-sale-banner-with-text-space-area_1017-27331.jpg"
      }
      back={"/host/reservations"}
      title={"NEW RESERVATION"}
      hasPermission={hasPermission(["create:reservation"], staff?.permissions)}
      component={
        <div className={"space-y-6 pb-20"}>
          <BookingsEditor
            setBookings={setBookings}
            validate={validate}
            bookings={bookings}
          />
          <SelectCustomer setCustomer={setCustomer} validate={validate} />
          <Price
            total={total}
            paid={paid}
            setTotal={setTotal}
            setPaid={setPaid}
          />
          <Reserve
            customer={customer}
            setValidate={setValidate}
            bookings={bookings.map(omitListUuidFromBooking)}
            rooms={roomsMap}
            paid={paid}
            total={total}
            reset={reset}
            onReserve={onReserve}
          />
        </div>
      }
    />
  );
};

export default AddReservation;
