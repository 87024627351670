import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Booking,
  Reservation,
  ReservationStatus,
} from "../../../../typings/reservation";
import { get, patch } from "../../../../api/database";
import Loader from "../../../../components/Loader";
import FullPage from "../../../../components/FullPage";
import { Customer } from "../../../../typings/customer";
import BookingsEditor from "../fragments/BookingsEditor";
import Price from "../fragments/shared/Price";
import Button from "../../../../components/Button";
import { render } from "@react-email/components";
import ConfirmedReservations from "../../../../emails/ConfirmedReservations";
import { sendEmail } from "../../../../api/email";
import {
  hasPermission,
  totTitleCase,
  validateReservation,
} from "../../../../utils/validation";
import { Room } from "../../../../typings/rooms";
import { getAvailableRooms } from "../../../../api/rooms";
import PillSelector from "../../../../components/PillSelector";
import { useStaff } from "../../../../hooks/useStaff";
import CheckInCustomer from "./CheckInCustomer";

const ModifyReservation = () => {
  const { staff } = useStaff();
  const [rooms, setRooms] = useState<Room[]>([]);
  const { reservationId } = useParams<{ reservationId: string }>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [reservation, setReservation] = useState<Reservation | undefined>();
  const [customer, setCustomer] = useState<Customer | undefined>();

  const [bookings, setBookings] = useState<Partial<Booking>[]>(
    reservation?.bookings || [],
  );
  const [paid, setPaid] = useState(reservation?.paymentInfo?.paid || 0);
  const [total, setTotal] = useState(reservation?.paymentInfo?.total || 0);

  const [working, setWorking] = useState(false);
  const [validate, setValidate] = useState(false);
  const [checkInModal, setCheckInModal] = useState(false);

  const fallback = "/host/reservations";

  const roomsMap = new Map(
    rooms.map((room) => [room.uuid, { ...room, id: room.uuid }]),
  );

  async function save(): Promise<Reservation> {
    return new Promise(async (resolve, reject) => {
      setValidate(true);

      const validation = validateReservation(
        bookings,
        roomsMap,
        total,
        paid,
        customer,
      );

      if (validation.error) {
        window.alert(validation.error);
        reject();
      }

      if (!reservation === null) {
        reject();
      }

      setWorking(true);
      await patch<Reservation>("reservations", {
        ...reservation!!,
        bookings: validation.bookings,
        paymentInfo: {
          ...reservation!!.paymentInfo,
          paid,
          total,
          due: Math.max(0, total - paid),
        },
      })
        .then((r) => {
          setReservation(r);
          resolve(r);
        })
        .catch((e) => {
          window.alert("Failed to save changes to reservation");
          reject(e);
        })
        .finally(() => setWorking(false));
    });
  }

  async function sendMail() {
    setValidate(true);
    if (!reservation || !customer) return;

    const validation = validateReservation(
      bookings,
      roomsMap,
      total,
      paid,
      customer,
    );

    if (validation.error) {
      window.alert(validation.error);
      return;
    }

    if (!staff) {
      window.alert("Staff is required");
      return;
    }

    setWorking(true);
    await save().then(async (r) => {
      setWorking(true);
      render(
        <ConfirmedReservations
          staff={staff}
          reservation={r}
          customer={customer}
          rooms={roomsMap}
          previewText={`Hello ${customer.firstName}, we’ve updated your reservation details. Please take a moment to review them.`}
          reservationState={r.status === "CANCELLED" ? "Cancelled" : undefined}
        />,
      )
        .then((html) => {
          setWorking(true);
          sendEmail({
            to: customer.emailAddress,
            html: html,
            subject: `Your Reservation Has Been Updated`,
          })
            .catch(() => window.alert("Failed to send email to customer"))
            .finally(() => setWorking(false));
        })
        .catch(() => setWorking(false));
    });
  }

  function onStatusChanged(ids: string[]) {
    if (!reservation || !customer) return;

    if (ids.length === 0) return;

    let id = ids[0];

    if (id === reservation.status) return;

    if ((id as ReservationStatus) === "CHECKED_IN") {
      setCheckInModal(!checkInModal);
      return;
    } else {
      patch<Partial<Reservation>>("reservations", {
        id: reservation.id,
        status: id as ReservationStatus,
      })
        .then(() => {
          setReservation({ ...reservation, status: id as ReservationStatus });
        })
        .catch(console.log);
    }
  }

  useEffect(() => {
    getAvailableRooms()
      .then(setRooms)
      .catch(() => {
        window.alert("Failed to get available rooms.");
      });
  }, []);

  useEffect(() => {
    if (reservationId) {
      get<Reservation>("reservations/" + reservationId)
        .then(async (data) => {
          if (data) {
            setReservation(data);
            setPaid(data.paymentInfo.paid);
            setTotal(data.paymentInfo.total);
            get<Customer>("customers/" + data.customerUuid)
              .then(setCustomer)
              .catch(console.log);
          } else {
            navigate(fallback);
          }
        })
        .catch(() => navigate(fallback))
        .finally(() => setLoading(false));
    } else {
      navigate(fallback);
    }
  }, [reservationId, navigate]);

  const statuses: ReservationStatus[] = [
    "PENDING",
    "CONFIRMED",
    "CHECKED_IN",
    "CHECKED_OUT",
    "CLOSED",
    "CANCELLED",
  ];

  if (loading || !reservation || !customer) return <Loader />;

  return (
    <FullPage
      title={"MODIFY RESERVATION"}
      back={"/host/reservations"}
      busy={working}
      hasPermission={hasPermission(["modify:reservation"], staff?.permissions)}
      component={
        <div className={"w-full"}>
          <div className={"space-y-6"}>
            <div
              className={
                "bg-white p-6 rounded-xl shadow flex flex-col items-center justify-center space-y-3"
              }
            >
              <img
                alt={""}
                src={customer.thumbnailUrl}
                className={
                  "h-12 w-12 rounded-full overflow-hidden object-cover shadow"
                }
              />
              <a
                className={
                  "text-lg font-extrabold underline underline-offset-4 decoration-dashed"
                }
                href={"/host/customers?uuid=" + customer.uuid}
              >
                {customer.firstName} {customer.lastName}
                <span className={"pl-3"}>&#x2197;</span>
              </a>
              <p
                className={
                  "font-extrabold bg-pink-500 text-white text-lg p-3 px-6 rounded-2xl"
                }
              >
                <span className={""}>
                  <i className="bx bxs-purchase-tag-alt"></i>
                </span>
                {reservation.id}
              </p>
            </div>
            <div className={"space-y-3"}>
              <p className={"text-lg font-extrabold"}>Status</p>
              <PillSelector
                large={true}
                multiple={false}
                selectedIds={[reservation.status]}
                items={statuses.map((status) => ({
                  id: status,
                  title: totTitleCase(status),
                }))}
                onChange={onStatusChanged}
              />
            </div>

            <BookingsEditor
              setBookings={setBookings}
              validate={validate}
              bookings={reservation.bookings}
            />
            <Price
              total={total}
              paid={paid}
              setTotal={setTotal}
              setPaid={setPaid}
            />
            <div className={"flex flex-row justify-between w-full space-y-3"}>
              <Button
                type={"secondary"}
                text={"Email changes"}
                onClick={sendMail}
              />
              <Button type={"primary"} text={"Save changes"} onClick={save} />
            </div>
          </div>
          {checkInModal && staff && (
            <CheckInCustomer
              staff={staff}
              onClose={() => {
                setCheckInModal(false);
              }}
              customer={customer}
              reservation={reservation}
              setReservation={setReservation}
            />
          )}
        </div>
      }
    />
  );
};

export default ModifyReservation;
