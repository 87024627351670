import React from "react";
import FullPage from "../../../components/FullPage";
import Selection from "./fragments/Selection";

const BeautySalon = () => {
  return (
    <FullPage
      title={"BEAUTY SALON"}
      bannerUrl={
        "https://t3.ftcdn.net/jpg/06/39/76/72/360_F_639767241_ANBm8EezTNPDWHaq2CiIDft285rRlSKF.jpg"
      }
      component={
        <div>
          <Selection />
        </div>
      }
    />
  );
};

export default BeautySalon;
