import * as React from "react";
import {
  Column,
  Heading,
  Hr,
  Img,
  Link,
  Row,
  Section,
  Text,
} from "@react-email/components";
import { Booking, Reservation } from "../typings/reservation";
import moment from "moment-timezone";
import { Customer } from "../typings/customer";
import { Room } from "../typings/rooms";
import { faqs } from "../pages/faq/FaqHome";
import { BUSINESS_EMAIL, BUSINESS_WEBSITE } from "../constants/links";
import MailSetup from "./shared/MailSetup";
import { Staff } from "../typings/permissions";

const Box = (props: {
  id: string;
  day: string;
  date: string;
  icon: any;
  time: string;
}) => {
  return (
    <Section className={""}>
      <Section>
        <Row className={"ml-6"}>
          <Column align={"left"}>{props.icon}</Column>
          <Column align={"left"}>{props.id}</Column>
        </Row>
      </Section>

      <Hr className={"border-solid border-[#eaeaea]"} />
      <Section className={"p-6"}>
        <Text className={"font-extrabold text-lg p-0 m-0"}>{props.day}</Text>
        <Text className={"text-lg p-0 m-0"}>{props.date}</Text>
        <Text className={"font-thin text-sm p-0 m-0"}>{props.time}</Text>
      </Section>
    </Section>
  );
};

const Support = ({ href, title }: { href: string; title: string }) => {
  return (
    <Link href={href} className={"text-gray-800"}>
      <Text
        className={
          "text-center text-xl p-3 mb-3 rounded-xl border border-solid border-[#eaeaea]"
        }
      >
        {title}
      </Text>
    </Link>
  );
};

const Price = ({ amount, title }: { amount: string; title: string }) => {
  return (
    <Section
      className={
        "items-center justify-center flex flex-col rounded border py-3 mx-auto"
      }
    >
      <Text className={"font-thin p-0 m-0 mx-auto"}>{title}</Text>
      <Text className={"font-extrabold text-xl p-0 m-0 mx-auto"}>{amount}</Text>
    </Section>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Heading className="font-extrabold text-2xl my-3 text-gray-90">
      {title}
    </Heading>
  );
};

const SectionSubTitle = ({ title }: { title: string }) => {
  return <Text className={"font-thin text-xl"}>{title}</Text>;
};

const SectionItem = (props: any) => {
  return <Section className={"my-3"}>{props.children}</Section>;
};

export const LogoText = () => {
  return (
    <Text className={"font-extrabold text-pink-500 p-0 m-0"}>
      IFEMIDE ESTATES
    </Text>
  );
};

const FAQS = faqs.slice(0, 6);

interface Props {
  reservation: Reservation;
  customer: Customer;
  rooms: Map<string, Room>;
  previewText?: string;
  reservationState?: string;
  staff: Staff;
}

function formatNumber(num: number) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 10,
    notation: "compact",
    compactDisplay: "short",
  }).format(num);
}

const BookingTemplate = ({
  numberOfBookings,
  number,
  booking,
  roomDescription,
}: {
  numberOfBookings: number;
  number: number;
  booking: Booking;
  roomDescription: string;
}) => {
  const guests = `${Number(booking.guests.adults) + Number(booking.guests.children)} Guests`;

  const dayFormat = "dddd";
  const dateFormat = "MMMM Do YYYY";
  const timeFormat = "hh:mm A (z)";
  const timeZone = "Africa/Lagos";

  var localCheckInDate = moment(booking.checkInDateTime).tz(timeZone);
  var localCheckOutDate = moment(booking.checkOutDateTime).tz(timeZone);

  const checkInDay = localCheckInDate.format(dayFormat);
  const checkInDate = localCheckInDate.format(dateFormat);
  const checkInTime = localCheckInDate.format(timeFormat);

  const checkOutDay = localCheckOutDate.format(dayFormat);
  const checkOutDate = localCheckOutDate.format(dateFormat);
  const checkOutTime = localCheckOutDate.format(timeFormat);

  return (
    <>
      <SectionItem>
        <SectionTitle
          title={`Reservation details ${numberOfBookings > 0 ? `${number}/${numberOfBookings}` : ""}`}
        />
        <SectionSubTitle title={roomDescription} />
      </SectionItem>

      <SectionItem>
        <SectionTitle title={"Guests"} />
        <SectionSubTitle title={guests} />
        <Row className={"border rounded-xl border-solid border-[#eaeaea]"}>
          <Column className={"mr-3"}>
            <Box
              icon={<Text>&darr;</Text>}
              id={"Check In"}
              day={checkInDay}
              date={checkInDate}
              time={checkInTime}
            />
          </Column>
          <Column className={"ml-3"}>
            <Box
              icon={<Text>&uarr;</Text>}
              id={"Check Out"}
              day={checkOutDay}
              date={checkOutDate}
              time={checkOutTime}
            />
          </Column>
        </Row>
      </SectionItem>
    </>
  );
};

const reservationMessages: string[] = [
  "Your reservation at Ifemide Estates, Alagbaka Akure is confirmed! We can't wait to welcome you!",
  "Fantastic news! Your stay at Ifemide Estates, Alagbaka Akure is officially booked. We look forward to hosting you!",
  "Your reservation at Ifemide Estates, Alagbaka Akure is confirmed! We’re thrilled to have you!",
  "Great news! You're all set for your stay at Ifemide Estates, Alagbaka Akure. We look forward to hosting you!",
  "Awesome! Your reservation at Ifemide Estates, Alagbaka Akure has been confirmed! We’re thrilled to have you!",
  "Your booking at Ifemide Estates, Alagbaka Akure is confirmed. Can't wait to have you with us!",
  "You're all booked! Your reservation at Ifemide Estates, Alagbaka Akure is confirmed.",
  "Confirmed! Your stay at Ifemide Estates, Alagbaka Akure is locked in. We’re thrilled to have you!",
  "Wonderful news! Your reservation at Ifemide Estates, Alagbaka Akure is a go! We look forward to hosting you!",
];

export function ConfirmedReservations({
  reservation,
  customer,
  rooms,
  previewText,
  reservationState = "Confirmation",
  staff,
}: Props) {
  const reservationId = `${reservation.id}`;

  const customerTitle = customer!.salutation;
  const customerFirstName = customer!.firstName;
  const customerLastName = customer!.lastName;

  const paid = formatNumber(reservation.paymentInfo.paid);

  const due =
    reservation.paymentInfo.due === 0
      ? "-"
      : formatNumber(reservation.paymentInfo.due);
  const total = formatNumber(reservation.paymentInfo.total);

  return (
    <MailSetup
      staff={staff}
      previewText={
        previewText ||
        reservationMessages[
          Math.floor(Math.random() * reservationMessages.length)
        ]
      }
      component={
        <Section>
          <SectionItem>
            <Img
              alt={"Ifemide Host Thumbnail Photo"}
              height={50}
              width={50}
              className={"contain-content rounded-full m-0 p-0"}
              src={staff?.thumbnailUrl}
            ></Img>
            <Text className="font-thin m-0 p-0 text-black text-xs">
              {`${staff?.displayName}, ${staff?.role}`}
            </Text>
            <Text className="font-thin m-0 text-xs text-gray-500 p-0">
              {moment(new Date()).format("MMMM D, YYYY")}
            </Text>
          </SectionItem>

          <Heading className={"text-3xl font-extrabold text-gray-700"}>
            {`Reservation ${reservationState}: Ifemide Estates, Alagbaka Akure – ${customerTitle} ${customerFirstName} ${customerLastName} [ID: #${reservationId}]`}
          </Heading>

          <Section className={"overflow-hidden"}>
            <Img
              height="160"
              style={{
                width: "100%",
                objectFit: "cover",
              }}
              className={"rounded-xl cover"}
              src={
                "https://lh3.googleusercontent.com/pw/AP1GczOJnVN6wRy0F2T0CmQgrAu7dc2spMasxVNnqRlOrcN8HjMvDJ27fFp-uFanTp1ibT8RrgCVUPBfu4Q61KgPenMDYqzAuukLf8Zlh0RoJqCkURnEjw=w2400"
              }
              alt={"Ifemide Solitiare Building Photo"}
            />
          </Section>

          {reservation.bookings.map((booking, index) => (
            <BookingTemplate
              key={index}
              numberOfBookings={reservation.bookings.length}
              number={index + 1}
              booking={booking}
              roomDescription={
                rooms.get(booking.roomUuid)?.description || "Not provided."
              }
            />
          ))}

          <SectionItem>
            <SectionTitle title={"Price"} />
            <Row className={""}>
              <Column>
                <Price amount={paid} title={"Paid"} />
              </Column>
              <Column>
                <Price amount={due} title={"Due"} />
              </Column>
              <Column>
                <Price amount={total} title={"Total"} />
              </Column>
            </Row>
          </SectionItem>

          <SectionItem>
            <SectionTitle title={"Frequently asked questions"} />
            {FAQS.map((faq) => (
              <Link
                href={`${BUSINESS_WEBSITE}/faq#${faq.id}`}
                key={faq.title}
                className={"text-gray-800 font-thin"}
              >
                <Text className={"font-thin text-xl underline my-6"}>
                  {faq.title}
                </Text>
              </Link>
            ))}
          </SectionItem>

          <SectionItem>
            <SectionTitle title={"Customer Support"} />
            <Support href={"tel:+2349067921124"} title={"Phone Call"} />
            <Support
              href={"mailto:" + BUSINESS_EMAIL}
              title={"Contact the Host"}
            />
          </SectionItem>

          <SectionItem>
            <SectionTitle title={"Terms of Service"} />
            <Link
              href={`${BUSINESS_WEBSITE}/terms-of-service`}
              className={"text-gray-800 font-thin"}
            >
              <Text className={"font-thin text-xl underline my-6"}>
                Please find our general terms of service here.
              </Text>
            </Link>
          </SectionItem>
        </Section>
      }
    />
  );
}

export default ConfirmedReservations;
