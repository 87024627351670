import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AddReservation from "../pages/host/reservations/pages/AddReservation";
import AdminAuthenticator from "../components/HostAuthenticator";
import Customers from "../pages/host/customers/Customers";
import HostHome from "../pages/host/HostHome";
import FaqHome from "../pages/faq/FaqHome";
import GuestLounge from "../pages/guest/lounge/GuestLounge";
import GuestAuthenticator from "../pages/guest/GuestAuthenticator";
import Menu from "../pages/host/menu/Menu";
import GuestInvoice from "../pages/guest/invoice/GuestInvoice";
import InternetCredentials from "../components/InternetCredentials";
import ReferAFriend from "../pages/guest/lounge/pages/ReferAFriend";
import MakeAPayment from "../pages/guest/lounge/pages/MakeAPayment";
import HostInvoice from "../pages/host/invoice/HostInvoice";
import BankDetails from "../pages/guest/lounge/pages/BankDetails";
import Header from "../pages/visitor/home/fragments/Header";
import Home from "../pages/visitor/home/Home";
import DailyTransactions from "../pages/host/transactions/DailyTransactions";
import BookingCalendar from "../pages/host/calendar/BookingCalendar";
import HostReservations from "../pages/host/reservations/Reservations";
import ModifyReservation from "../pages/host/reservations/pages/ModifyReservation";
import EnergyDashboard from "../pages/energy/EnergyDashboard";
import Reservations from "../pages/visitor/reservation/Reservations";
import Kitchen from "../pages/guest/kitchen/Kitchen";
import PaystackPayment from "../pages/callbacks/PaystackPayment";
import BeautySalon from "../pages/guest/salon/BeautySalon";
import JobsPage from "../pages/visitor/jobs/JobsPage";
import TermsOfService from "../pages/terms/TermsOfService";

const Navigation = () => {
  return (
    <div className={"h-screen mx-auto max-w-[600px] text-gray-800"}>
      <Routes>
        {/* VISITOR ROUTES */}
        <Route path="/" element={<Header component={<Home />} />} />
        <Route path="/reservations" element={<Reservations />} />
        <Route path="/kitchen" element={<Kitchen />} />
        <Route path="/salon" element={<BeautySalon />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* HOST ROUTES */}
        <Route
          path="/host"
          element={
            <AdminAuthenticator>
              <HostHome />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/reservations"
          element={
            <AdminAuthenticator>
              <HostReservations />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/reservations/:reservationId"
          element={
            <AdminAuthenticator>
              <ModifyReservation />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/create-reservation"
          element={
            <AdminAuthenticator>
              <AddReservation />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/customers"
          element={
            <AdminAuthenticator>
              <Customers />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/menu"
          element={
            <AdminAuthenticator>
              <Menu editor={true} back={"/host"} />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/invoice"
          element={
            <AdminAuthenticator>
              <HostInvoice />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/transactions"
          element={
            <AdminAuthenticator>
              <DailyTransactions />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/calendar"
          element={
            <AdminAuthenticator>
              <BookingCalendar />
            </AdminAuthenticator>
          }
        />
        <Route
          path="/host/energy"
          element={
            <AdminAuthenticator>
              <EnergyDashboard />
            </AdminAuthenticator>
          }
        />
        {/* GUEST ROUTES */}
        <Route
          path="/guest"
          element={
            <GuestAuthenticator>
              <GuestLounge />
            </GuestAuthenticator>
          }
        />
        <Route path="/guest/menu" element={<Menu />} />
        <Route
          path="/guest/invoice"
          element={
            <GuestAuthenticator>
              <GuestInvoice />
            </GuestAuthenticator>
          }
        />
        <Route
          path="/guest/internet"
          element={
            <GuestAuthenticator>
              <InternetCredentials />
            </GuestAuthenticator>
          }
        />
        <Route
          path="/guest/payment"
          element={
            <GuestAuthenticator>
              <MakeAPayment />
            </GuestAuthenticator>
          }
        />
        <Route
          path="/guest/bank-details"
          element={
            <GuestAuthenticator>
              <BankDetails />
            </GuestAuthenticator>
          }
        />
        <Route
          path="/guest/refer"
          element={
            <GuestAuthenticator>
              <ReferAFriend />
            </GuestAuthenticator>
          }
        />
        <Route path="/faq" element={<FaqHome />} />

        {/* CALLBACKS */}
        <Route path="/paystack-callback" element={<PaystackPayment />} />

        {/* FALL BACK ROUTE */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Navigation;
