import React from "react";
import { formatPrice } from "../../../../utils/validation";
import RoundPick from "../../../../components/RoundPick";
import { HowToPay } from "../../../../components/payment/PaymentOption";
import { defaultDeposit } from "../Reservations";

const ChooseHowToPay = ({
  setHowToPay,
  howToPay,
  grandTotal,
}: {
  howToPay: HowToPay;
  setHowToPay: (d: HowToPay) => void;
  grandTotal: number;
}) => {
  return (
    <section className={"p-9 bg-white space-y-3 rounded-lg"}>
      <p className={"font-semibold "}>Choose how to pay</p>
      <div className={"space-y-3"}>
        <div className={"flex flex-row justify-between"}>
          <p className={""}>
            Pay{" "}
            <span className={"font-extrabold"}>
              {grandTotal ? formatPrice(grandTotal) : ""}
            </span>{" "}
            now
          </p>
          <RoundPick
            selected={howToPay === "full payment"}
            onClick={() => setHowToPay("full payment")}
          />
        </div>
        <div className={"flex flex-row justify-between opacity-50"}>
          <p>Pay at the property</p>
          <RoundPick selected={howToPay === "pay at the property"} />
        </div>
      </div>
      <hr />
      <div>
        <div className={"flex flex-row justify-between"}>
          <p>
            Make{" "}
            <span
              className={"font-extrabold"}
            >{`${defaultDeposit * 100}%`}</span>{" "}
            deposit
          </p>
          <RoundPick
            selected={howToPay === "part payment"}
            onClick={() => setHowToPay("part payment")}
          />
        </div>
        <p className={"text-gray-500 w-10/12"}>
          You can pay{" "}
          <span className={"font-extrabold"}>
            {formatPrice(grandTotal * defaultDeposit)}
          </span>{" "}
          out of the total charges, and pay the rest when you arrive at the
          property.
        </p>
      </div>
    </section>
  );
};

export default ChooseHowToPay;
