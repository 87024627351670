import React, { useEffect, useState } from "react";
import { Cart } from "../Kitchen";
import { Counter } from "../../../../components/Counter";
import {
  formatPrice,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../../../../utils/validation";
import Button from "../../../../components/Button";
import { Menu } from "../../../../typings/menu";
import PaymentOption, {
  PayWith,
} from "../../../../components/payment/PaymentOption";
import ActivityModal from "../../../../components/ActivityModal";
import FormEntry from "../../../../components/FormEntry";
import { toastError } from "../../../../utils/toast";
import { CustomerInfo, renovate, storeInfo } from "../../../../utils/memory";
import {
  BUSINESS_ADDRESS_LINE_1,
  BUSINESS_ADDRESS_LINE_2,
} from "../../../../constants/links";

interface Validation extends Partial<CustomerInfo> {
  paymentOption?: string;
  isValid?: boolean;
}

export const DELIVERY_FREE = 1200;
export const FREE_DELIVERY_FROM = 30000;

const Basket = ({
  cart,
  notes,
  onClose,
  addToCart,
  addNotes,
  confirmAndPay,
}: {
  cart: Cart;
  notes?: string;
  onClose: () => void;
  addToCart: (menu: Menu, qty: number) => void;
  addNotes: () => void;
  confirmAndPay: (
    payWith: PayWith,
    amountToPay: number,
    withDeliveryFee: number,
    pickUpAtTheHotel: boolean,
    customerInfo: CustomerInfo,
  ) => void;
}) => {
  const [pickUpAtTheHotel, setPickUpAtTheHotel] = useState(true);

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount] = useState(0);
  const [coupon] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [payWith, setPayWith] = useState<PayWith>();

  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumberLine1: "",
    addressLine1: "",
    addressLine2: "",
    ...renovate(),
  });

  const [validation, setValidation] = useState<Validation>({});

  useEffect(() => {
    const subTotal = Array.from(cart.values()).reduce(
      (sum, item) => sum + item.quantity * item.food.rate,
      0,
    );
    const total = subTotal + deliveryFee - discount - coupon;

    setSubTotal(subTotal);
    setTotal(total);
  }, [cart, coupon, deliveryFee, discount]);

  // Load data on mount, save data on unmount
  useEffect(() => {
    renovate();
  }, []);

  useEffect(() => {
    const newFee =
      !pickUpAtTheHotel && total <= FREE_DELIVERY_FROM ? DELIVERY_FREE : 0;

    if (newFee !== deliveryFee) setDeliveryFee(newFee);
  }, [deliveryFee, pickUpAtTheHotel, total]);

  const handleChange = (value: string, name: keyof CustomerInfo) => {
    const newData = { ...customerInfo, [name]: value };
    setCustomerInfo(newData);
    storeInfo(newData);
  };

  const validate = () => {
    let newValidation: Validation = { isValid: true };

    if (!payWith) {
      const message = "Could not determine payment option";
      newValidation = {
        ...newValidation,
        paymentOption: message,
      };
      newValidation.isValid = false;

      toastError(message);
    }

    if (!isValidEmail(customerInfo.emailAddress)) {
      const message = "Invalid email";
      newValidation = {
        ...newValidation,
        emailAddress: message,
      };
      newValidation.isValid = false;
      toastError(message);
    }

    if (!isValidName(customerInfo.firstName)) {
      newValidation = {
        ...newValidation,
        firstName: "Invalid first name",
      };
      newValidation.isValid = false;
      toastError("Invalid first name");
    }

    if (!isValidName(customerInfo.lastName)) {
      newValidation = {
        ...newValidation,
        lastName: "Invalid name",
      };
      newValidation.isValid = false;
      toastError("Invalid last name");
    }

    if (!isValidEmail(customerInfo.emailAddress)) {
      newValidation = {
        ...newValidation,
        emailAddress: "Invalid email",
      };
      newValidation.isValid = false;
    }

    if (!isValidPhoneNumber(customerInfo.phoneNumberLine1)) {
      newValidation = {
        ...newValidation,
        phoneNumberLine1: "Invalid phone number",
      };
      newValidation.isValid = false;
      toastError("Invalid phone number");
    }

    if (!pickUpAtTheHotel) {
      if (!customerInfo.addressLine1) {
        newValidation = {
          ...newValidation,
          addressLine1: "Invalid street and house number",
        };
        newValidation.isValid = false;
        toastError("Invalid street and house number");
      }
      if (!customerInfo.addressLine2) {
        newValidation = {
          ...newValidation,
          addressLine2: "Invalid neighborhood description",
        };
        newValidation.isValid = false;
        toastError("Invalid city and state");
      }
    }

    setValidation(newValidation);

    return newValidation;
  };

  const handleConfirmAndPay = () => {
    const newValidation = validate();

    if (newValidation.isValid && payWith && total > 0)
      confirmAndPay(
        payWith,
        total,
        deliveryFee,
        pickUpAtTheHotel,
        customerInfo,
      );

    if (Array.from(cart.keys()).length < 1) {
      toastError("Please add more items to your cart");
    }
  };

  return (
    <ActivityModal
      title={"Check out"}
      body={
        <>
          {Array.from(cart.keys()).length < 1 && (
            <p className={"p-6 font-semibold border-b"}>
              Please{" "}
              <span>
                <button
                  onClick={onClose}
                  className={
                    "underline decoration-dashed underline-offset-4 text-pink-500"
                  }
                >
                  add
                </button>
              </span>{" "}
              more items to your cart
            </p>
          )}
          <div>
            {Array.from(cart.values()).map((item, index) => (
              <div
                className={`border-b py-3 flex flex-row justify-between px-6 ${index % 2 === 1 ? "bg-white" : "bg-slate-100"}`}
                key={item.food.uuid}
              >
                <p className={"font-semibold"}>{item.food.title}</p>
                <div className="space-y-1">
                  <p className="text-end">
                    {formatPrice(item.quantity * item.food.rate)}
                  </p>
                  <Counter
                    defaultValue={item.quantity}
                    onChange={(qty) => addToCart(item.food, qty)}
                    max={999}
                  ></Counter>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className={"space-y-2 px-6"}>
              <div className={"flex flex-row justify-between"}>
                <p>Sub total</p>
                <p>{formatPrice(subTotal)}</p>
              </div>
              <div className={"flex flex-row justify-between"}>
                <p>Discount</p>
                <p>{formatPrice(discount)}</p>
              </div>
              <div className={"flex flex-row justify-between"}>
                <p>Delivery free</p>
                <p>{formatPrice(deliveryFee)}</p>
              </div>
            </div>
            <div
              className={
                "flex flex-row justify-between font-semibold px-6 mt-3"
              }
            >
              <p>Total</p>
              <p>{formatPrice(total)}</p>
            </div>
          </div>
          <hr className={"my-3"} />
          <div className={"px-6 space-y-3"}>
            <p className={"font-semibold"}>Add notes</p>
            <div className={"flex flex-row justify-between"}>
              <p className={"line-clamp-3 text-ellipsis"}>
                {notes ? notes : "No notes added"}
              </p>
              <button onClick={addNotes}>
                <i className={"bx bxs-edit text-lg"}></i>
              </button>
            </div>
          </div>
          <hr className={"my-3"} />
          <div className={"px-6 space-y-3"}>
            <p className={"font-semibold"}>Pay with</p>
            <PaymentOption setPaymentMethod={setPayWith} />
          </div>
          <hr className={"my-3"} />
          <div className={"px-6 space-y-1"}>
            <p className={"font-semibold"}>Your details</p>
            <div className={"grid grid-cols-2 gap-4"}>
              <FormEntry
                error={validation.firstName}
                title={"First name*"}
                placeholder={"Jide"}
                value={customerInfo.firstName}
                type={"name"}
                onChange={(d) => handleChange(d, "firstName")}
              />
              <FormEntry
                error={validation.lastName}
                title={"Last name*"}
                placeholder={"Bucknor"}
                value={customerInfo.lastName}
                type={"name"}
                onChange={(d) => handleChange(d, "lastName")}
              />
            </div>
            <FormEntry
              error={validation.emailAddress}
              title={"Email address*"}
              placeholder={"jidebucknor@email.com"}
              value={customerInfo.emailAddress}
              type={"email"}
              onChange={(d) => handleChange(d, "emailAddress")}
            />
            <FormEntry
              error={validation.phoneNumberLine1}
              title={"Phone number*"}
              placeholder={"+234801112324"}
              value={customerInfo.phoneNumberLine1}
              type={"tel"}
              onChange={(d) => handleChange(d, "phoneNumberLine1")}
            />
          </div>
          <div className={"px-6 space-y-1"}>
            <p className={"font-semibold"}>Delivery</p>
            <div
              className={
                "flex flex-row items-start bg-slate-100 p-6 rounded-xl"
              }
            >
              <input
                checked={pickUpAtTheHotel}
                type={"checkbox"}
                onChange={(event) => setPickUpAtTheHotel(!pickUpAtTheHotel)}
                className={
                  "mr-3 border border-pink-500 flex-shrink-0 checked:bg-pink-500 h-4 w-4"
                }
              />
              <p className={""}>
                I am currently staying or I will pick up at the hotel
              </p>
            </div>
            {!pickUpAtTheHotel && (
              <p className={"p-6 bg-pink-100 rounded-xl"}>
                Enjoy free delivery on orders of{" "}
                <span className={"text-pink-500"}>
                  {formatPrice(FREE_DELIVERY_FROM)}
                </span>{" "}
                or more!
              </p>
            )}
            <FormEntry
              error={validation.addressLine1}
              title={"Street and house number*"}
              placeholder={"109 Sola Aladesuyi Street"}
              value={
                pickUpAtTheHotel
                  ? BUSINESS_ADDRESS_LINE_1
                  : customerInfo.addressLine1
              }
              type={"address"}
              disabled={pickUpAtTheHotel}
              onChange={(d) => handleChange(d, "addressLine1")}
            />
            <FormEntry
              error={validation.addressLine2}
              title={"Neighborhood description*"}
              placeholder={"Alagbaka GRA Extension, Akure"}
              value={
                pickUpAtTheHotel
                  ? BUSINESS_ADDRESS_LINE_2
                  : customerInfo.addressLine2
              }
              disabled={pickUpAtTheHotel}
              type={"address"}
              onChange={(d) => handleChange(d, "addressLine2")}
            />
          </div>
        </>
      }
      footer={
        <Button
          type={"primary"}
          text={"Confirm and pay"}
          block={true}
          onClick={handleConfirmAndPay}
        />
      }
      onClose={onClose}
    />
  );
};

export default Basket;
