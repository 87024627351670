import { Customer } from "../typings/customer";

export type CustomerInfo = Pick<
  Customer,
  | "firstName"
  | "lastName"
  | "emailAddress"
  | "phoneNumberLine1"
  | "addressLine1"
  | "addressLine2"
>;

const key = "00385a86-19f7-4492-ad77-ee42abc140c6";

export const renovate = (): Partial<CustomerInfo> => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data) as CustomerInfo;
    }
  } catch (e) {
    console.log(e);
  }
  return {};
};

export const storeInfo = (d: CustomerInfo) => {
  localStorage.setItem(key, JSON.stringify(d));
};
