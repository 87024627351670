import React from "react";
import { Link, Section, Text } from "@react-email/components";
import MailSetup from "./shared/MailSetup";
import { Staff } from "../typings/permissions";
import { BUSINESS_WEBSITE } from "../constants/links";

const TermsOfServiceMail = ({
  customerFirstName = "{{customerFirstName}}",
  previewText = "{{previewText}}",
  staff,
}: {
  reservationId?: string;
  customerFirstName?: string;
  previewText?: string;
  checkInTime?: string;
  staff: Staff;
}) => {
  return (
    <MailSetup
      staff={staff}
      previewText={previewText}
      component={
        <>
          <Text>Hi {customerFirstName} 👋</Text>
          <Section>
            <Text className={"font-bold text-lg"}>
              You have requested for an information regarding our service:
            </Text>
          </Section>
          <Section>
            <div className="w-full flex justify-center bg-slate-100 p-3 rounded-lg">
              <Link
                href={`${BUSINESS_WEBSITE}/terms-of-service`}
                className={"text-gray-800 font-thin"}
              >
                <Text className={"font-thin text-xl underline my-6"}>
                  Please find our general terms of service here.
                </Text>
              </Link>
            </div>
          </Section>
          <br />
        </>
      }
    />
  );
};

export default TermsOfServiceMail;
