import React from "react";
import { Customer } from "../typings/customer";
import Loader from "./Loader";
import FullPage from "./FullPage";
import BotChat from "./BotChat";
import { CurrentHostThumbnailUrl } from "../constants/links";
import { getGreeting } from "../utils/validation";

const InternetCredentials = ({ customer }: { customer?: Customer }) => {
  const content =
    "" +
    "Wi-Fi Name: IfemideEstates\n" +
    "Password: Ifemide@$1\n" +
    "\n" +
    "Wi-Fi Name: FirstAtlanticHomesSolitaireGF\n" +
    "Password: 45B369DD\n" +
    "\n" +
    "Wi-Fi Name: FirstAtlanticHomesSolitaire1F\n" +
    "Password: 52564087\n" +
    "\n" +
    "Wi-Fi Name: FirstAtlanticHomes1F\n" +
    "Password: 52085305";

  if (!customer) return <Loader />;

  return (
    <FullPage
      back={"/guest"}
      bannerUrl={
        "https://img.freepik.com/premium-vector/ray-big-data-background-with-extending-forward-data-channel-internet_476006-5852.jpg"
      }
      title={"INTERNET CREDENTIALS"}
      component={
        <div>
          <p className={"text-xs my-3 text-gray-700"}>INTERNET CREDENTIALS</p>
          <div
            className={"space-y-3 text-xs p-3 px-6 bg-white rounded-lg shadow"}
          >
            <BotChat
              text={"text-sm"}
              delays={2000}
              profilePhoto={CurrentHostThumbnailUrl}
              messages={[
                {
                  content: `${getGreeting()} ${customer.firstName}, there are four buildings in the yard, there is a Starlink Wifi available outside each building, and two or one router in each of the building per floor.
              `,
                },
                {
                  content,
                },
                {
                  content: `There are also wired ethernet cable connection in each room in case you need it for fast gaming experience or conference call.`,
                },
              ]}
            />
          </div>
        </div>
      }
    />
  );
};

export default InternetCredentials;
