import { Menu, MenuType } from "../../../../typings/menu";

export interface MenuRecordItem<K> {
  type: K;
  thumbnailUrl: string;
  title: string;
}

export type MenuRecord = {
  [K in MenuType]: MenuRecordItem<K>;
};

export const CATEGORIES: MenuRecord = {
  MAIN_COURSE: {
    type: "MAIN_COURSE",
    thumbnailUrl:
      "https://dolapogrey.com/wp-content/uploads/2020/05/RA-3Z78OE_IMG_20180327_072343_583.jpg",
    title: "Main Course",
  },
  SWALLOWS: {
    thumbnailUrl:
      "https://smokeyjollof.com/wp-content/uploads/2022/07/Ey9RpckWQAIzcXj.jpg",
    title: "Swallows",
    type: "SWALLOWS",
  },
  PROTEIN: {
    thumbnailUrl:
      "https://png.pngtree.com/png-vector/20240207/ourlarge/pngtree-grilled-meat-in-plate-cut-into-pieces-png-image_11671669.png",
    title: "Protein",
    type: "PROTEIN",
  },
  SOUPS: {
    thumbnailUrl:
      "https://www.mydiasporakitchen.com/wp-content/uploads/2022/02/B04F4133-B5D1-4000-A943-C1BA652C377D-768x1024.jpeg",
    title: "Soups",
    type: "SOUPS",
  },
  SIDES: {
    thumbnailUrl:
      "https://sisijemimah.com/wp-content/uploads/2015/12/moimoi-14-1024x672.jpg",
    title: "Sides",
    type: "SIDES",
  },
  SMALL_CHOPS: {
    thumbnailUrl: "https://pbs.twimg.com/media/E0kTMf7WYAMxN1y.png",
    title: "Small chops",
    type: "SMALL_CHOPS",
  },
  EXTRAS: {
    thumbnailUrl:
      "https://static.vecteezy.com/system/resources/previews/032/623/006/non_2x/plate-of-vegetable-salad-ai-generative-png.png",
    title: "Extras",
    type: "EXTRAS",
  },
  DRINK: {
    thumbnailUrl:
      "https://static.vecteezy.com/system/resources/thumbnails/046/592/156/small/cocktails-beverages-illustration-watercolor-style-png.png",
    title: "Drinks",
    type: "DRINK",
  },
};

export const BANNER_URL =
  "https://www.freewebheaders.com/wp-content/gallery/food/popular-food-meal-website-header.jpg";

export const Foods: Menu[] = [
  {
    uuid: "50af9a76-3d8f-439d-84a7-8bcd092f51c0",
    thumbnailUrl:
      "https://static.vecteezy.com/system/resources/thumbnails/042/268/394/small_2x/ai-generated-3d-rendering-of-a-fried-rice-in-a-plate-or-bowl-on-transparent-background-ai-generated-png.png",
    title: "Jollof Rice",
    description:
      "Our Jollof Rice is a beloved Nigerian classic, featuring long-grain rice cooked to perfection in a rich tomato and bell pepper sauce. Infused with onions, garlic, and a blend of savory spices.",
    rate: 4500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "tomatoes", "onions", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "58bdd34d-0aeb-4961-84ee-97a252b3fdfd",
    thumbnailUrl:
      "https://i0.wp.com/ounjealadun.com/wp-content/uploads/2021/12/20211208_190246_wm.jpg?resize=759%2C1135&ssl=1",
    title: "Village Rice",
    description:
      "Traditional Nigerian-style rice cooked with local spices, herbs, and a hint of smoked fish for an authentic, rustic taste.",
    rate: 4500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: [],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "ceb4241b-3e8d-49e7-a546-faf15a082aff",
    thumbnailUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfzNOv7nlHxFfj7u1wruA4HcJ4LuNDiOc-QQ&s",
    title: "Ofada Rice with Sauce",
    description:
      "Locally grown Nigerian rice served with a spicy pepper sauce, you can enjoy this with assorted meats and a traditional favorite.",
    rate: 4000,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["ofada rice", "pepper sauce", "assorted meats"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "9752763d-91e0-493c-a422-eb635cfe0b5a",
    thumbnailUrl:
      "https://eatwellabi.com/wp-content/uploads/2022/01/Coconut-rice-9.jpg",
    title: "Coconut Rice",
    description:
      "Creamy coconut-flavored rice, offering a subtle sweetness and a tropical twist to the traditional rice dish.",
    rate: 4500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "coconut milk", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "e717e29b-8f05-4c04-86dc-4f4a84f00f91",
    thumbnailUrl:
      "https://static.vecteezy.com/system/resources/previews/034/616/928/non_2x/bowl-of-white-rice-with-ai-generated-free-png.png",
    title: "White Rice",
    description:
      "Steamed white rice, perfect as a side dish or base for sauces and meats, delivering a light and fluffy texture in each bite.",
    rate: 3000,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "d2f38487-ab1c-4e3a-917a-2ef84569d0af",
    thumbnailUrl:
      "https://foodsturvs.ca/wp-content/uploads/2024/04/rice-and-beans-768x512.jpg",
    title: "Rice and Beans",
    description:
      "A delicious combination of rice and beans cooked for a balanced meal full of protein, carbohydrate and flavor.",
    rate: 3500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "a82e6b8c-306a-40ce-b3ea-e21abd0d2a30",
    thumbnailUrl:
      "https://mamacassng.com/wp-content/uploads/2020/10/LUXURY-COCONUT-RICE-450x450.jpg",
    title: "Fried rice",
    description:
      "Nigerian style fried rice with long grain rice cooked in stock, either a meat stock mixed with vegetables.",
    rate: 5000,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "1143353f-305b-4c41-ae3b-bd743214a2eb",
    thumbnailUrl:
      "https://afrokitchennl.ca/wp-content/uploads/2021/03/haddicious-ewa-agoyin.jpg",
    title: "Ewa Agoyin",
    description:
      "A delicious combination of rice and beans cooked with spices for a balanced meal full of protein and flavor.",
    rate: 3500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "467c96f4-446e-43a4-99ab-bc7ebe2c5e69",
    thumbnailUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Beans_porridge_and_fried_plantains.jpg",
    title: "Beans Porridge",
    description:
      "Made with tender honey or brown beans, it's simmered with tomatoes, onions, and palm oil, and seasoned with rich spices with a touch of crayfish and chili. You can enjoy this with our fried plantain side dish as well ",
    rate: 3000,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "aa4523d0-cf5a-477f-8f64-343c49d1217f",
    thumbnailUrl:
      "https://dolapogrey.com/wp-content/uploads/2020/05/RA-3Z78OE_IMG_20180327_072343_583.jpg",
    title: "Yam Porridge",
    description:
      "Comforting dish featuring tender yams cooked in a rich, savory tomato and pepper sauce. Infused with palm oil, onions, and a blend of traditional spices, this porridge has a slightly spicy kick and is perfectly balanced with a hint of crayfish for depth of flavor. Fresh greens like spinach or ugu (pumpkin leaves) are stirred in for added color and nutrition",
    rate: 4000,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "6aa4d173-e852-43d9-bb1b-921ad17a8f51",
    thumbnailUrl:
      "https://mamacassng.com/wp-content/uploads/2020/11/mamacass-restaurant-33-450x450.jpg",
    title: "Boiled Yam (per slice)",
    description:
      "Our Boiled Yam features tender slices of yam boiled to perfection with a soft texture that pairs beautifully with a variety of sides. Enjoy it with fried eggs, garden egg sauce, tomato stew, or a drizzle of palm oil.",
    rate: 1000, // per slice
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "da00ae8f-e21f-40bb-afe9-97515545bb30",
    thumbnailUrl:
      "https://img.freepik.com/premium-photo/jollof-spaghetti-nigerian-dish-plate-white-background-directly-view_864588-12567.jpg?w=360",
    title: "Spaghetti Jollof",
    description:
      "Our Spaghetti Jollof is a vibrant twist on the classic Jollof rice, made with perfectly cooked spaghetti simmered in a rich, spicy tomato and bell pepper sauce. Infused with onions, garlic, and a blend of spices, each strand is coated in flavorful goodness. Often garnished with vegetables or served with grilled chicken, shrimp, or fried plantains.",
    rate: 6200,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "a79e6cdc-f78c-4ee5-be6b-ba8fecaa4a6c",
    thumbnailUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuBp-euDMVHSU99DMPiPxmuvaCm2UWQJ4UaPqjAMeB_xvOy9iUVvSk2ZixMUCi5TEq4PA&usqp=CAU",
    title: "Farm House Porridge",
    description:
      "Cooked with palm oil, onions, tomatoes, and a touch of crayfish, it's seasoned with aromatic spices for a deep, comforting flavor. Fresh greens like ugu (pumpkin leaves) or spinach add a burst of color and nutrition, while smoked fish or beef can be added for extra richness.",
    rate: 5500,
    currentStock: 999,
    type: "MAIN_COURSE",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "bc514d42-9e73-478c-bb5e-5d80c13e5572",
    thumbnailUrl:
      "https://img-global.cpcdn.com/recipes/846df25e22f0107c/1200x630cq70/photo.jpg",
    title: "Titus",
    description:
      "Titus is a nutritious and delicious choice, packed with omega-3 fatty acids and a unique flavor that enhances any meal.",
    rate: 3000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "ffe8070f-9a16-4700-b9d9-f4810f94a2ed",
    thumbnailUrl:
      "https://lowcarbafrica.com/wp-content/uploads/2022/09/Instant-Pot-Turkey-Wings-IG-1.jpg",
    title: "Turkey (big)",
    description:
      "Whether grilled, fried, or stewed, the turkey absorbs spices and seasonings beautifully, offering a juicy and savory taste in every bite.",
    rate: 9000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "e1d50d0c-6086-4445-8fe4-6af6a2a84045",
    thumbnailUrl:
      "https://assets.epicurious.com/photos/65130e0e9a1a62fec9ea57fb/16:9/w_1280,c_limit/Turkey-Wings_RECIPE.jpg",
    title: "Turkey (small)",
    description:
      "Whether grilled, fried, or stewed, the turkey absorbs spices and seasonings beautifully, offering a juicy and savory taste in every bite.",
    rate: 6000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "6c81ce25-d003-4813-9b5f-0485c96e6433",
    thumbnailUrl:
      "https://media.istockphoto.com/id/1391724292/photo/juicy-roast-chicken-legs-with-green-beans-salad.jpg?s=612x612&w=0&k=20&c=JrkFMOs2XcDHb1K5OYVHzPBRbqAULgE-wd8ok-nYlDs=",
    title: "Chicken (big)",
    description:
      "Whether grilled, fried, or stewed, the chicken absorbs spices and seasonings beautifully, offering a juicy and savory taste in every bite.",
    rate: 7000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "e2a45351-1256-4b48-84e7-28c842986a7d",
    thumbnailUrl:
      "https://www.allrecipes.com/thmb/8SI7kuVs0lOwi7PiDvdou4Uzqe8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/241152-fried-chicken-wings-ddmfs-hero-3x4-1260-d37ffaac793c4db7a4ced4ab24a5d2ee.jpg",
    title: "Chicken (small)",
    description:
      "Whether grilled, fried, or stewed, the chicken absorbs spices and seasonings beautifully, offering a juicy and savory taste in every bite.",
    rate: 4000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "e25e080e-8ba6-4fd0-9f71-b93892eb02fa",
    thumbnailUrl:
      "https://img-global.cpcdn.com/recipes/35e6e8a9b49f61b1/680x482cq70/cow-leg-special-recipe-main-photo.jpg",
    title: "Cow leg (2nos)",
    description:
      "Slow-cooked and boiled to tender perfection and can be served with dishes like Jollof rice, pounded yam, or yam porridge.",
    rate: 4000,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "cf484c02-c2d1-4f65-911f-8b7d129f8b30",
    thumbnailUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyUQqPSk8GZsg4toF8N8wXCUykDKQqO1Itxhb9EOQxexCoFzy8kkI_gEOfw4NwjC9dyHo&usqp=CAU",
    title: "Goat meat (2nos)",
    description:
      "Marinated with a blend of spices, herbs, and seasonings, then cooked until tender, allowing the meat to absorb the rich flavors. Our goat meat can be grilled, boiled, or fried, and is often enjoyed with rice, yam, plantains, or as a savory snack.",
    rate: 5500,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "263ca46e-3244-4f9e-bf95-df68f67944f6",
    thumbnailUrl: "https://manage.xshop.ng/storage/1773/BEEF.jpeg",
    title: "Beef (2nos)",
    description:
      "Our beef can be prepared in many ways, from boiling and frying to grilling, and you can pair with spicy sauces, vegetables, or served alongside rice, yam, or plantains for a hearty, satisfying meal.",
    rate: 5300,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "5865434b-87f3-42b1-8b15-a4f6742cecd8",
    thumbnailUrl: "https://i.ytimg.com/vi/CBBj6ibfgSk/maxresdefault.jpg",
    title: "Assorted meat (per piece)",
    description:
      "Delicious mix of different cuts of meat, including beef, goat meat, cow tripe (shaki), and other choice parts, all simmered together in a savory, aromatic broth. This flavorful mix is  seasoned with a blend of spices, herbs, and seasonings like thyme, garlic, and chili peppers, and is cooked until tender.",
    rate: 1500,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "5b7079c0-8f09-4cbb-8cae-6830238c4667",
    thumbnailUrl: "https://i.ytimg.com/vi/_MUosCdKcpY/maxresdefault.jpg",
    title: "Ponmo",
    description:
      "Ponmo, also known as cow skin, this is boiled until tender and you can use it in a variety of dishes, such as soups, stews, and pepper soups.",
    rate: 2800,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "35b76531-7965-402d-8f17-2fa0fbe88e77",
    thumbnailUrl:
      "https://mamacassng.com/wp-content/uploads/2022/07/IMG-20240703-WA0074.jpg",
    title: "Boiled egg",
    description:
      "Boiled Eggs are a simple yet nutritious staple, perfect for any meal or snack. Cooked to your preferred level of doneness, from soft-boiled to hard-boiled, they are rich in protein and packed with vitamins and can be served as a side dish or accompaniment to dishes like Jollof rice, fried rice, yam porridge, or salad.",
    rate: 999,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "fa046f0c-28e3-461f-a3f6-3f794b495a6e",
    thumbnailUrl: "https://i.ytimg.com/vi/r3cqsVZJ0HQ/maxresdefault.jpg",
    title: "Croaker fish",
    description:
      "Croaker fish which can be grilled, fried, or steamed to perfection. It is seasoned with a blend of spices, herbs, and aromatic ingredients like garlic, onions, and chili peppers, allowing it to absorb rich, savory flavors. Croaker Fish pairs beautifully with a variety of sides, such as fried plantains, rice, or yam.",
    rate: 32100,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "82495c1f-67a4-4abf-84ce-620f1863e47f",
    thumbnailUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAG8dSJj0JmshykMHEtUvFBTjSvY2nG8LtbA&s",
    title: "Roasted cat fish",
    description:
      "THe Catfish is marinated with a blend of spices, herbs, and seasonings, then slow-cooked over an open flame or in the oven until perfectly charred and tender. The fish absorbs the smoky aroma, giving it a rich, savory taste. Typically served with a spicy pepper sauce, steamed vegetables, or a side of rice or yam..",
    rate: 24200,
    currentStock: 999,
    type: "PROTEIN",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "22951ae4-3260-4bd2-bf17-7a771cf2ab23",
    thumbnailUrl:
      "https://naijastickitchen.com/wp-content/uploads/2024/01/Pounded-Yam.jpg",
    title: "Poundo Yam",
    description:
      "Soft, fluffy dough made from yam flour, replicating the taste and feel of freshly pounded yam without the labor. It has a smooth, starchy texture and is typically served with hearty soup.",
    rate: 1500,
    currentStock: 999,
    type: "SWALLOWS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "7caaa17b-5cf4-4435-9ec4-3e9b88f33179",
    thumbnailUrl:
      "https://smokeyjollof.com/wp-content/uploads/2022/07/Ey9RpckWQAIzcXj.jpg",
    title: "Amala",
    description:
      "Soft, smooth, dark-brown dish made from yam flour (elubo), with a distinct flavor and satisfying texture. Perfect when paired with rich stews and soups like Ewedu, Gbegiri, or Egusi,",
    rate: 1500,
    currentStock: 999,
    type: "SWALLOWS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "0610a0f3-ef2d-4447-85f5-e5adfc57e5f1",
    thumbnailUrl:
      "https://bustopbistro.ng/wp-content/uploads/2020/04/34-Semovita-300x300.png",
    title: "Semo",
    description:
      "Made from a blend of semolina flour and wheat, forming a soft, mild dough that complements a variety of stews and soups.",
    rate: 1500,
    currentStock: 999,
    type: "SWALLOWS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "8aaa4d16-70d9-46eb-be21-f87c8823eb0e",
    thumbnailUrl:
      "https://www.myactivekitchen.com/wp-content/uploads/2015/03/how-to-make-eba-food-nigerian-eba-img2-1-499x375.jpg",
    title: "Eba",
    description:
      "Made from garri (cassava flour) and is known for its slightly coarse texture and unique, tangy taste. Quick to prepare and filling, Eba pairs wonderfully with any stew or soup, making it a staple choice for a flavorful, satisfying meal.",
    rate: 1500,
    currentStock: 999,
    type: "SWALLOWS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "7b252bb7-6052-4a5f-9502-a0070f2efc87",
    thumbnailUrl:
      "https://i.ytimg.com/vi/lVjME9OyIfY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLASxfbsh9M6A-tD0Ac6Qhjpka-Cbw",
    title: "Egusi Soup",
    description:
      "Cooked with ground melon seeds (egusi), giving it a unique, nutty flavor and a thick, satisfying texture. It's cooked in a flavorful base of palm oil, tomatoes, onions, and a blend of spices, often enriched with tender meat, smoked fish, or shrimp. Fresh leafy greens like spinach, ugu (pumpkin leaves), or bitter leaf add color and nutrition. Served alongside pounded yam, fufu, or rice",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "2cd7857c-7895-4f6e-be5e-407bdd1bcdc6",
    thumbnailUrl:
      "https://i0.wp.com/delish9jaspice.co.uk/wp-content/uploads/2021/04/Delish_Edikaikong_.jpg?fit=680%2C680&ssl=1",
    title: "Edikaikong",
    description:
      "Made with a mix of fresh fluted pumpkin leaves (ugu) and waterleaf, creating a lush, hearty texture. Originating from the Efik and Ibibio people of Southern Nigeria, this soup is cooked in a base of palm oil, spiced with ground crayfish, and enriched with tender cuts of meat, snail, or smoked fish for a savory depth. Often seasoned with a blend of traditional spices, Edikaikong is a nutrient-packed dish enjoyed with pounded yam, fufu, or rice.",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "5820a154-bf3e-4e2b-bf31-bf27e560dd1d",
    thumbnailUrl:
      "https://www.weeklyjollof.com/cdn/shop/files/Eforiro.jpg?v=1689239625",
    title: "Efo riro",
    description:
      "Efo riro is a vibrant and flavorful Nigerian spinach stew, cooked with a blend of tomatoes, bell peppers, and onions for a rich, spicy base. Enhanced with palm oil and seasoned with traditional spices, this dish often includes tender chunks of meat, smoked fish, or shrimp for added depth. Fresh spinach or fluted pumpkin leaves (ugu) give it a bright, hearty touch, making it a nutrient-rich favorite. Perfectly paired with rice, pounded yam, or fufu.",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "76c06bde-c408-478d-b2ae-615867947cfd",
    thumbnailUrl: "https://wunmiselectivekitchen.com/storage/2024/05/afang.png",
    title: "Afang",
    description:
      "Made with a blend of finely shredded afang (wild spinach) and waterleaf, giving it a hearty, slightly earthy flavor. Cooked with palm oil, onions, and ground crayfish, this dish is typically enhanced with chunks of assorted meats, smoked fish, and sometimes periwinkles for extra depth and texture. Served with pounded yam, eba, or fufu.",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "c9eed23e-f90c-4c96-ab01-ed5f928c964e",
    thumbnailUrl:
      "https://img.freepik.com/premium-photo/ogbono-soup-nigerian-dish-plate-white-background-directly-view_864588-12623.jpg",
    title: "Ogbono",
    description:
      "The soup is cooked in palm oil with a base of onions, tomatoes, and traditional spices, creating a savory, flavorful broth. It’s enriched with a variety of meats such as beef, goat, or chicken, along with smoked fish or stockfish for extra depth. Vegetables are added for a fresh, earthy balance. Enjoyed with pounded yam, fufu, or semovita.",
    rate: 4000,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "70713549-64bb-4709-84e7-77bad91039ac",
    thumbnailUrl:
      "https://lowcarbafrica.com/wp-content/uploads/2020/10/Ewedu-Soup-featured-1.jpg",
    title: "Ewedu",
    description:
      "Made from the mucilaginous (slimy) leaves of the jute plant, known as Ewedu, enriched with ground crayfish for added flavor. Typically served with amala (yam flour), eba, or fufu can be paired with a variety of meats, including goat meat, chicken, or fish.",
    rate: 2500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "9184a388-ecd9-43e7-8e86-b19d701bc0ff",
    thumbnailUrl:
      "https://streetfoodng.com/wp-content/uploads/2024/05/3a9f0444-7230-4fff-8e5f-efec1461cb23.png",
    title: "Gbegiri",
    description:
      "Made from pureed beans, typically brown or honey beans. The beans are cooked and blended into a smooth, velvety texture, then simmered with palm oil, onions, and a blend of spices to create a rich, hearty broth. This can paired with our Ewedu, efo riro (spinach stew) or served alongside amala (yam flour), eba, or fufu.",
    rate: 2000,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "c3e78247-c5e8-461f-9708-f9ab2e8e7d3e",
    thumbnailUrl:
      "https://lagosbukagrillandbar.com/wp-content/uploads/2021/07/Catfish-Peppersoup.jpeg",
    title: "Catfish pepper soup",
    description:
      "Made with tender pieces of catfish cooked in a spicy, savory broth. The fish is simmered with a blend of traditional spices such as thyme, bay leaves, and chili peppers, along with aromatics like onions, garlic, and ginger. Scent leaves (nchuanwu) or basil are added for extra fragrance. This light yet spicy soup is known for its healing properties and is typically enjoyed as a starter or a main dish, often served with a side of rice, yam, or plantains.",
    rate: 8500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "058ed039-04a5-45c4-817c-0ab765794d51",
    thumbnailUrl:
      "https://i0.wp.com/foodieng.com/wp-content/uploads/2022/11/Olugbo-Soup.jpeg?fit=506%2C510&ssl=1",
    title: "Bitter leaf soup",
    description:
      "The soup is made with fresh bitter leaves (onugbu), which are carefully washed to reduce their bitterness, and cooked in a rich, flavorful broth made from palm oil, onions, and ground crayfish. The soup is thickened with cocoyam or yam, giving it a hearty consistency. Served with pounded yam, fufu, or semovita",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "04925bce-dded-4c49-a2ba-f766d5c8614d",
    thumbnailUrl: "https://wunmiselectivekitchen.com/storage/2024/03/w2.png",
    title: "Seafood okro",
    description:
      "Made with finely sliced okra, giving it a thick, hearty texture. This version is enriched with a variety of seafood such as shrimp, catfish, stockfish, and crabs, creating a deliciously savory and aromatic broth. The soup is cooked with palm oil, onions, peppers, and traditional spices, including crayfish, for added depth and flavor. Often enjoyed with pounded yam, fufu, or rice.",
    rate: 5000,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "c95d1a72-36ff-4c08-8b6f-157649c51707",
    thumbnailUrl:
      "https://i.pinimg.com/originals/05/4d/98/054d9840df4ed29a3e7bc758d73d35ef.jpg",
    title: "White Soup (Ofe Nsala)",
    description:
      "Rich in palm oil, with a lighter, clear broth made with fresh ingredients like chicken, goat meat, or fish. It’s flavored with aromatic spices, including the unique addition of local herbs such as uziza (West African pepper), and thickened with yam or cocoyam. The soup is seasoned with a rich blend of traditional spices and sometimes enriched with dried fish for added depth. Typically served with pounded yam or fufu.",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "6860901d-acf8-4e7c-8b9b-b6942b8c9331",
    thumbnailUrl:
      "https://allnigerianfoods.com/wp-content/uploads/2015/04/black-soup.jpg",
    title: "Black Soup (Gbanunu)",
    description:
      "Gbanunu Soup is a flavorful Nigerian soup made with pounded cocoyam as a thickener, palm oil, smoked fish, assorted meats, and seasoned with native spices, crayfish, and leafy greens like uziza or spinach for a rich, earthy taste..",
    rate: 4500,
    currentStock: 999,
    type: "SOUPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "b620836f-7932-4c95-98df-69fdbdc0dc15",
    thumbnailUrl:
      "https://www.shutterstock.com/image-photo/overhead-view-nigerian-akamu-being-600nw-2523690915.jpg",
    title: "Cooked pap (Ogi)",
    description:
      "Smooth, fermented porridge made from ground maize (corn). The pap is cooked into a creamy, warm consistency and can be sweetened with sugar or flavored with a bit of milk. Often paired with fried akara (bean cakes) or puff-puff.",
    rate: 3000,
    currentStock: 999,
    type: "EXTRAS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "b6f9b054-c3e0-4714-9273-dfd41c32a2f0",
    thumbnailUrl:
      "https://media.istockphoto.com/id/1225981808/photo/healthy-homemade-fruit-salad-bowl-shot-from-above.jpg?s=612x612&w=0&k=20&c=f9hWan8nPo4C0UKxG7bF65cAmyUhbb-Qa0TYrc0BwWc=",
    title: "Fruit salad",
    description:
      "Our refreshing Fruit Salad is a vibrant mix of fresh, seasonal fruits, carefully selected for their natural sweetness and crisp textures. It features a variety of colorful fruits like pineapple, watermelon, mango, papaya, and oranges, all cut into bite-sized pieces. Lightly tossed with a touch of honey or citrus dressing, it’s a nutritious and refreshing dish that’s perfect as a snack, side dish, or light dessert. Full of vitamins and antioxidants",
    rate: 5500,
    currentStock: 999,
    type: "EXTRAS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "3534dde9-0b2b-4071-85fb-b8dffabc39b9",
    thumbnailUrl:
      "https://probiodelights.com.ng/wp-content/uploads/2024/03/akara.webp",
    title: "Akara (per piece)",
    description:
      "Snack made from ground black-eyed beans, onions, and spices, shaped into small fritters and deep-fried until golden and crispy. The beans are blended into a smooth paste, seasoned with a touch of salt, pepper, and sometimes chili, then fried to perfection. With a crunchy exterior and a soft, fluffy interior, Akara is often enjoyed as a breakfast dish or snack, typically paired with pap (ogi) or a spicy dipping sauce.",
    rate: 1000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "4f55a7d8-c92f-4b0a-a8a6-1d5d55684883",
    thumbnailUrl:
      "https://allnigerianfoods.com/wp-content/uploads/puff_puff_recipe.jpg",
    title: "Puff puff (per piece)",
    description:
      "Snack made from a sweet, fluffy dough. The dough is made from flour, sugar, yeast, and a pinch of nutmeg, then fried until golden and crispy on the outside while remaining soft and airy on the inside.",
    rate: 1000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
    outOfStock: true,
  },
  {
    uuid: "299caddc-5fa9-4778-85db-7ed801457f8c",
    thumbnailUrl:
      "https://img-global.cpcdn.com/recipes/cec12943c437f6f5/400x400cq70/photo.jpg",
    title: "Fried yam (per slice)",
    description:
      "Classic street food, made by slicing fresh yam tubers into thick pieces and frying them until golden and crispy on the outside, while remaining soft and tender on the inside. Perfectly seasoned, this dish is can be served with our spicy pepper sauce, fried eggs, or a side of grilled fish or chicken. It’s a satisfying, crunchy snack or main meal that’s both flavorful and hearty.",
    rate: 1000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "26621bbb-154b-4e11-a56f-68823158f25a",
    thumbnailUrl:
      "https://media.istockphoto.com/id/1427917867/photo/roasted-potatoes-baked-potato-wedges-in-frying-pan-on-dark-stone-background.jpg?s=612x612&w=0&k=20&c=JQZnA9LiHYKsBRjJzEQUmv1GZ4gNiJEzUwpZfFr0h8U=",
    title: "Fried potatoes (per piece)",
    description:
      "Made by slicing fresh potatoes into bite-sized pieces and frying them until golden and perfectly crisp. Seasoned with a touch of salt and pepper, these fried potatoes offer a satisfying crunch on the outside, while remaining soft and fluffy on the inside. They can also be served with our spicy pepper sauce, fried eggs, or grilled meats.",
    rate: 1000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "f358baff-f578-4a29-9cdc-34ba2db8e3a4",
    thumbnailUrl:
      "https://lagosbukagrillandbar.com/wp-content/uploads/2024/09/pomo.jpg",
    title: "Peppered ponmo (per piece)",
    description:
      "Made with tender, well-cooked cow skin (ponmo) that is marinated and stir-fried in a spicy, aromatic sauce. The ponmo is simmered until soft, then cooked in a rich blend of tomatoes, onions, chili peppers, and seasoning, often with the addition of smoked fish or stock cubes for extra depth.",
    rate: 2500,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "c30b837c-0663-458d-b260-f40a31bc595c",
    thumbnailUrl:
      "https://www.allnigerianrecipes.com/wp-content/uploads/2019/03/peppered-chicken.jpg",
    title: "Peppered chicken",
    description:
      "Chicken pieces marinated and grilled or fried to perfection. The chicken is cooked in a flavorful, spicy sauce made with a blend of tomatoes, onions, chili peppers, and traditional seasonings.",
    rate: 8000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "aaa119f1-68db-4033-8213-e26b2b8a5980",
    thumbnailUrl:
      "https://www.food.goglowonline.com/upload/Peppered%20Turkey1.jpg",
    title: "Peppered turkey",
    description:
      "Flavorful and spicy dish made with tender turkey pieces cooked in a rich, aromatic pepper sauce. The turkey is first marinated to absorb a blend of spices, then simmered in a sauce made with tomatoes, onions, garlic, and chili peppers, giving it a fiery kick.",
    rate: 10000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "04161fa7-ef09-433e-a458-3e22b9a18bf7",
    thumbnailUrl: "https://i.ytimg.com/vi/9l87hqOfS9Y/maxresdefault.jpg",
    title: "Shrimps",
    description:
      "Our succulent Shrimps are fresh, tender, and full of flavor. Perfectly cooked to retain their natural sweetness, they can be grilled, sautéed, or seasoned with a variety of spices, herbs, and sauces. Whether served as a stand-alone dish, tossed in a flavorful sauce, or added to your favorite stew, fried rice.",
    rate: 10000,
    currentStock: 999,
    type: "SMALL_CHOPS",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "b5fe668c-3ecf-4c7c-89c5-eec1e2fc076e",
    thumbnailUrl:
      "https://static01.nyt.com/images/2022/12/21/multimedia/19kwanzaa-rex2-1-742f/19kwanzaa-rex2-1-742f-superJumbo.jpg",
    title: "Plantain (per slice)",
    description:
      "Made by slicing ripe plantains and frying them until golden brown and caramelized on the outside.",
    rate: 500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "056b9225-ba24-4b97-8ec9-21bfafc42ceb",
    thumbnailUrl:
      "https://ahmazingfoodsng.com/wp-content/uploads/2023/11/f10993f5da07b71323b7afb07905bdb7-removebg-preview.png",
    title: "Egg sauce",
    description:
      "Prepared with chopped tomatoes, onions, bell peppers, and scotch bonnet peppers for a spicy kick, the eggs are whisked and cooked into the sauce until fluffy and well-blended.",
    rate: 3500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "ddaaa25b-ce32-4dc6-be2c-84456552291f",
    thumbnailUrl:
      "https://shop.africanfoodsupermarket.com/cdn/shop/articles/moi_moi_600x.jpg?v=1611605450",
    title: "Moi moi",
    description:
      "Made from blended black-eyed beans, peppers, onions, and spice. Enhanced with ingredients such as boiled eggs, flaked fish, for added flavor and texture.",
    rate: 2000,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "0531d177-315a-49a8-ae3d-8d43a8aa01fc",
    thumbnailUrl:
      "https://i.ytimg.com/vi/wF9EMg5rQZk/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDOG_jlsBxg39fLp9MS9Kgzektc4g",
    title: "Fish sauce",
    description:
      "Made with mackerel (Titus), simmered in a rich, spicy tomato and pepper sauce, includes ingredients like tomatoes, bell peppers, onions, and Scotch bonnet peppers, all blended and cooked in vegetable oil.",
    rate: 3500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "819ef448-92e1-4f81-830a-6912d10a75e6",
    thumbnailUrl:
      "https://mamacassng.com/wp-content/uploads/2020/10/BOILED-PLANTAIN-450x450.jpg",
    title: "Boiled plantain (per peel)",
    description:
      "Made by unripe plantains until tender, boiled until soft depending on their ripeness. You can enjoy this with our side dishes such as stews, sauces, or protein-rich meals such as eggs, beans, or fish.",
    rate: 1000,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "9fc8c8cd-1be3-41c6-882b-ef6cb5c2030c",
    thumbnailUrl:
      "https://oyettymeals.com/wp-content/uploads/2020/11/Gizzard-Dodo.jpg",
    title: "Gizz dodo",
    description:
      "The gizzards are seasoned and cooked until tender, then mixed with fried plantain cubes and simmered in a rich tomato and pepper sauce. Flavored with onions, bell peppers, and traditional spices, this dish balances the sweetness of plantains with the hearty, savory taste of gizzards.",
    rate: 8500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "1c2db61e-64be-45e7-a940-af6c1756cecf",
    thumbnailUrl:
      "https://bigmamaspices.ng/wp-content/uploads/2023/06/ofada-stew.webp",
    title: "Ofada sauce",
    description:
      "Prepared using a blend of green bell peppers, Scotch bonnet peppers, onions, and sometimes tomatoes, giving it a vibrant greenish-brown color. Palm oil is used for cooking, seasoned with spices and crayfish. You can enjoy this sauce by ordering for our assorted meats, such as goat meat, tripe, and cow skin (ponmo), as well as boiled eggs.",
    rate: 8500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
  {
    uuid: "e3164e06-b945-4ccf-9525-a6aa54cdc908",
    thumbnailUrl:
      "https://images.squarespace-cdn.com/content/v1/60d8d9ee1a52ec4669cc8fd1/1638224791546-OP19N7Y80BNSFD390A28/DAM+Products+Agege+Bread.png?format=1500w",
    title: "Bread",
    description:
      "One foot long, soft but dense sweet white bread made from a rich, low-yeast dough",
    rate: 1500,
    currentStock: 999,
    type: "SIDES",
    ingredients: ["rice", "beans", "spices"],
    createdAt: "2024-11-08T10:07:11.419748Z",
    updatedAt: "2024-11-08T10:07:11.419789Z",
  },
];
