import React, { useEffect, useState } from "react";
import { RoomVariant, views } from "../../home/Home";
import { RoomTypeConfig, Solitaire, Twinmotion } from "../../home/config/Rooms";
import Carousel from "../../../../components/Carousel";
import ListInput from "../../../../components/ListInput";
import Button from "../../../../components/Button";
import ActivityModal from "../../../../components/ActivityModal";
import { Room } from "../../../../typings/rooms";

const RoomSelection = ({
  view,
  roomUuid,
  onChange,
  onClose,
  rooms,
}: {
  view: RoomVariant;
  roomUuid: string;
  onChange: (d: { view: RoomVariant; roomUuid: string }) => void;
  onClose: () => void;
  rooms: Room[];
}) => {
  const [currentView, setCurrentView] = useState<RoomVariant>(view);
  const [currentRoomUuid, setCurrentRoomUuid] = useState<string>(roomUuid);
  const [availableRoomTypes, setAvailableRoomTypes] = useState<RoomVariant[]>(
    [],
  );

  function onSave() {
    onChange({
      view: currentView,
      roomUuid: currentRoomUuid,
    });
    onClose();
  }

  useEffect(() => {
    const availableRoomTypes: RoomVariant[] = [];

    if (RoomTypeConfig.Solitaire.includes(currentRoomUuid)) {
      availableRoomTypes.push("Solitaire");
    }

    if (RoomTypeConfig.Twinmotion.includes(currentRoomUuid)) {
      availableRoomTypes.push("Twinmotion");
    }

    if (
      availableRoomTypes.length &&
      !availableRoomTypes.includes(currentView)
    ) {
      setCurrentView(availableRoomTypes[0]);
    }
    setAvailableRoomTypes(availableRoomTypes);
  }, [currentView, currentRoomUuid]);

  const roomConfig = currentView === "Solitaire" ? Solitaire : Twinmotion;

  return (
    <ActivityModal
      title={"Room selection"}
      body={
        <div>
          <div
            className={"w-fit overflow-hidden border rounded-xl mx-auto my-3"}
          >
            {views.map((view) => (
              <button
                key={view.view}
                onClick={() => setCurrentView(view.view)}
                disabled={!availableRoomTypes.includes(view.view)}
                className={`py-3 px-6 ${currentView === view.view ? "bg-pink-500 text-white" : ""} ${!availableRoomTypes.includes(view.view) ? "line-through text-gray-500" : ""}`}
              >
                {view.view}
              </button>
            ))}
          </div>
          <div className={"grid grid-cols-2 gap-4 items-center p-9"}>
            <p className={"w-3/4 font-bold"}>Select number of rooms</p>
            <ListInput<Room>
              selectedId={currentRoomUuid}
              keySelector={"uuid"}
              titleSelector={"title"}
              onChange={setCurrentRoomUuid}
              list={rooms
                .filter((room) => !room.archived)
                .filter((room) =>
                  RoomTypeConfig[currentView].includes(room.uuid),
                )}
            />
          </div>
          <div className={"w-full aspect-square overflow-x-hidden"}>
            <Carousel images={roomConfig.photos.map((photo) => photo.url)} />
          </div>
        </div>
      }
      footer={
        <Button type={"primary"} text={"Save"} onClick={onSave} block={true} />
      }
      onClose={onClose}
    />
  );
};

export default RoomSelection;
