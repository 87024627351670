import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { AxiosError } from "axios";
import Modal from "./Modal";
import BotChat from "./BotChat";
import { BotThumbnailUrl } from "../constants/links";
import { useStaff } from "../hooks/useStaff";
import StaffHeaderProfile from "./StaffHeaderProfile";
import NoPermission from "./NoPermission";

export const headerHeightInPx = 56;

const FullPage = ({
  title,
  component,
  loading,
  error,
  bannerUrl,
  showTitleText = true,
  header,
  busy = false,
  hasPermission = true,
}: {
  title: string;
  component: any;
  back?: string;
  loading?: boolean;
  error?: AxiosError | any;
  bannerUrl?: string;
  px?: number;
  header?: ReactNode;
  busy?: boolean;
  showTitleText?: boolean;
  hasPermission?: boolean;
}) => {
  const navigate = useNavigate();
  const { staff } = useStaff();
  const handleGoBack = () => {
    if (window.history.length > 1) {
      // There is a back page available
      navigate(-1);
    } else {
      // No back page, navigate to the home page
      navigate("/"); // Replace '/home' with your desired default route
    }
  };

  const renderHeader = () => {
    return (
      <>
        {header ? (
          <>{header}</>
        ) : (
          <div
            // style={{ height: headerHeightInPx + "px" }}
            className="flex flex-row items-center justify-between px-6 h-16 bg-slate-200 relative"
            id="header"
          >
            <button onClick={handleGoBack} className="absolute top-5 left-6">
              <i className="bx bx-arrow-back text-lg"></i>
            </button>
            <p className="my-3 mx-6 text-sm text-center w-full">{title}</p>
            {staff ? (
              <div className={"absolute top-3 right-6"}>
                <StaffHeaderProfile staff={staff} />
              </div>
            ) : (
              <a href="/">
                <i className="bx bx-notification text-transparent"></i>
              </a>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="h-screen flex flex-col px-6">
      <div className="fixed top-0 left-0 w-full z-10">
        <div className={"mx-auto max-w-[600px]"}>{renderHeader()}</div>
      </div>

      {/* Body */}
      <div
        className={`mt-16 pb-80 scrollbar-hidden flex-1 overflow-y-auto w-full`}
      >
        {bannerUrl && (
          <div
            className="relative rounded-lg bg-white my-6 h-28 overflow-hidden shadow-lg text-center"
            style={{
              backgroundImage: `url(${bannerUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {showTitleText && (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-white text-4xl font-thin">{title}</p>
              </div>
            )}
          </div>
        )}
        {error && (
          <div className="w-full text-center bg-red-200 rounded-lg p-3 my-3 border-red-500 border">
            {error.message || "An error occurred"}
          </div>
        )}
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <>{component}</>
        ) : (
          <NoPermission />
        )}
      </div>
      <Modal
        show={busy}
        component={
          <BotChat
            profilePhoto={BotThumbnailUrl}
            messages={[{ content: "" }]}
            delays={100000}
          />
        }
      />
    </div>
  );
};

export default FullPage;
