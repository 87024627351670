import React, { useState } from "react";
import Loader from "../../../../components/Loader";
import { isValidEmail } from "../../../../utils/validation";
import { sendEmail } from "../../../../api/email";
import { Customer } from "../../../../typings/customer";
import FullPage from "../../../../components/FullPage";
import BotChat from "../../../../components/BotChat";
import { CurrentHostThumbnailUrl } from "../../../../constants/links";
import { render } from "@react-email/components";
import ReferAFriendEmail from "../../../../emails/ReferAFriendEmail";

const ReferAFriend = ({ customer }: { customer?: Customer }) => {
  const [referring, setReferring] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<
    { content: string; type: "error" | "success" } | undefined
  >(undefined);

  async function onRefer() {
    if (isValidEmail(email)) {
      setMessage(undefined);
      setReferring(true);

      if (!customer) return;

      await render(<ReferAFriendEmail customerName={customer.firstName} />)
        .then(async (html) => {
          await sendEmail({
            to: `${email},${customer.emailAddress}`,
            subject: `${customer.firstName} referred you, claim up to 10k discount on your next stay!`,
            html,
          })
            .then(() => {
              setEmail("");
              setMessage({
                content: `Email has been sent to ${email}, if you need to refer more friends, just type another in the box and claim more discounts!`,
                type: "success",
              });
              setReferring(false);
            })
            .catch(() => {
              setReferring(false);
              setMessage({
                content:
                  "Oh no!, an error occurred while completing this referral, kindly try again later.",
                type: "error",
              });
            });
        })
        .catch(() => {
          setMessage({
            type: "error",
            content: "An unexpected error occurred, please try again later",
          });
          setReferring(false);
        });
    } else {
      setMessage({ type: "error", content: "Invalid email address" });
      setReferring(false);
    }
  }

  function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setEmail(event.target.value);
  }

  return (
    <FullPage
      title={"REFER A FRIEND"}
      back={"/guest"}
      component={
        <div
          className={
            "p-6 space-y-3 bg-white shadow-xl rounded-xl overflow-y-auto max-h-screen min-h-0 pb-20"
          }
        >
          <div className={"mx-auto w-3/4 text-center my-12"}>
            <div className={"mb-9"}>
              <p className={"text-2xl font-bold"}>
                <span className={"text-white px-3 py-1 rounded-lg bg-pink-500"}>
                  ifemide
                </span>{" "}
                Estates
              </p>
            </div>
            <p className={"font-extrabold text-2xl text-gray-700"}>
              We value friendship
            </p>
            <p className={"my-3"}>
              Invite a friend. Just type their email address, you will both
              receive up to{" "}
              <span className={"font-bold text-pink-500"}>10k discount</span> on
              your next booking with us!
            </p>
            <div
              className={`${referring ? "opacity-30" : ""} border border-pink-500 rounded-xl text-sm flex flex-row justify-between overflow-hidden`}
            >
              <input
                onChange={onChangeEmail}
                disabled={referring}
                value={email}
                className={
                  "flex-grow-0 h-12 m-1 mx-3 w-full focus:outline-none"
                }
                placeholder={"Enter an email address"}
              />
              <button
                disabled={referring}
                onClick={onRefer}
                className={"mx-3 text-pink-500 font-bold"}
              >
                Invite
              </button>
            </div>
            {referring && (
              <div
                className={
                  "flex flex-row items-center justify-center w-full mt-3"
                }
              >
                <Loader />
              </div>
            )}
            <div className={"text-start my-6"}>
              {message && (
                <BotChat
                  delays={1000}
                  text={`text-sm text-gray-800 ${message.type === "error" ? "text-start text-pink-500" : ""}`}
                  profilePhoto={CurrentHostThumbnailUrl}
                  timestamp={true}
                  messages={[{ content: message.content }]}
                />
              )}
            </div>
          </div>
          <img
            alt={"icon"}
            className={
              "object-contain h-40 w-40 mx-auto my-16 rounded-full overflow-hidden"
            }
            src={
              "https://thumbs.dreamstime.com/b/colorful-friends-forever-calligraphy-corner-flower-vector-illustration-white-background-325431760.jpg"
            }
          />
        </div>
      }
    />
  );
};

export default ReferAFriend;
