import React from "react";
import BankTransfer from "./BankTransfer";
import DebitOrCreditCard from "./DebitOrCreditCard";
import Loader from "../Loader";
import { PayWith } from "./PaymentOption";
import { Service } from "../../typings/reservation";
import { PayStackPayload } from "../../pages/callbacks/PaystackPayment";

const PaymentResolver = ({
  payWith,
  amountToPay,
  type,
  email,
  payload,
  handlePaymentCompleted,
}: {
  amountToPay: number;
  payWith: PayWith;
  type: Service;
  email: string;
  payload: PayStackPayload;
  handlePaymentCompleted: (amountToPaid: number) => void;
}) => {
  const resolve = () => {
    switch (payWith) {
      case "bank transfer":
        return (
          <BankTransfer
            amount={amountToPay}
            onPaymentCompleted={handlePaymentCompleted}
          />
        );
      case "credit or debit card":
        return (
          <DebitOrCreditCard
            amount={amountToPay}
            type={type}
            email={email}
            orderJson={JSON.stringify(payload)}
          />
        );
      default:
        return <Loader />;
    }
  };

  return <>{resolve()}</>;
};

export default PaymentResolver;
