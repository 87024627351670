import React from "react";
import Navigation from "./app/nav/Navigation";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { StaffProvider } from "./app/hooks/useStaff";

function App() {
  return (
    <React.StrictMode>
      <StaffProvider>
        <BrowserRouter>
          <Navigation />
          <Toaster />
        </BrowserRouter>
      </StaffProvider>
    </React.StrictMode>
  );
}

export default App;
