import React, { useState } from "react";

const DropdownButton = ({
  items,
  onClick,
}: {
  onClick: (id: string) => void;
  items: { title: string; id: string }[];
}) => {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(items[0]);

  return (
    <div className={"bg-pink-500 text-white rounded-lg relative"}>
      <button
        className={"border-r px-3 p-1"}
        onClick={() => onClick(current.id)}
      >
        {current.title}
      </button>
      <button
        onClick={() => setOpen(!open)}
        className={"w-8 h-full text-center"}
      >
        <i className="bx bx-chevron-down"></i>
      </button>
      {open && (
        <ul
          className={
            "bg-white absolute top-9 right-0 shadow-xl rounded-lg w-full text-black"
          }
        >
          {items.map((item) => (
            <li
              className={"p-2 border-b"}
              key={item.id}
              onClick={() => {
                setOpen(false);
                setCurrent(item);
                onClick(item.id);
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
