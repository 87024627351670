import React, { useEffect, useState } from "react";
import DrinkList from "./DrinkList";
import ActivityModal from "../../../../components/ActivityModal";
import Restock from "./Restock";
import Button from "../../../../components/Button";
import CreateDrink from "./CreateDrink";
import { Drink } from "../../../../typings/menu";
import { v4 } from "uuid";
import { get, post, put } from "../../../../api/database";
import {
  toastAxiosError,
  toastError,
  toastSuccess,
} from "../../../../utils/toast";
import { DrinkAction } from "../Menu";
import Loader from "../../../../components/Loader";

const DrinkMenu = ({ editor = false }: { editor: boolean }) => {
  const [restockDrink, setRestockDrink] = useState(false);
  const [createDrink, setCreateDrink] = useState(false);

  const [modifiedDrinks, setModifiedDrinks] = useState<Drink[]>([]);
  const [updatingDrinks, setUpdatingDrinks] = useState(false);

  const [drinkDto, setDrinkDto] = useState<Partial<Drink>>({});

  const [drinks, setDrinks] = useState<Drink[]>([]);
  const [listVersion, setListVersion] = useState(v4());

  function drinkAction(action: DrinkAction, drink?: Drink): boolean {
    if (action === "restock") setRestockDrink(true);
    if (action === "create") setCreateDrink(true);
    return false;
  }

  function handleOnSaveDrinks() {
    if (modifiedDrinks.length) {
      setUpdatingDrinks(true);
      put<Drink[]>("/drinks", modifiedDrinks)
        .then((d) => {
          setDrinks(d);
          setRestockDrink(false);
        })
        .catch(toastAxiosError)
        .finally(() => {
          setModifiedDrinks([]);
          setListVersion(v4());
          setUpdatingDrinks(false);
        });
    }
  }

  function handleOnCreateDrink() {
    if (!drinkDto.title) {
      toastError("Invalid name");
      return;
    }

    if (isNaN(Number(drinkDto.rate)) || Number(drinkDto.rate) <= 0) {
      toastError("Invalid rate");
      return;
    }
    if (
      isNaN(Number(drinkDto.currentStock)) ||
      Number(drinkDto.currentStock) < 0
    ) {
      toastError("Invalid current stock");
      return;
    }

    const drink = {
      currentStock: drinkDto.currentStock!!,
      description: drinkDto.description || "",
      rate: drinkDto.rate!!,
      title: drinkDto.title!!,
      thumbnailUrl: drinkDto.thumbnailUrl!!,
    };

    setUpdatingDrinks(true);

    post<Partial<Drink>, Drink>("drinks", drink)
      .then((d) => {
        toastSuccess(`${d.title} created`);
        setDrinkDto({});
      })
      .catch(toastAxiosError)
      .finally(() => setUpdatingDrinks(false));
  }

  useEffect(() => {
    get<Drink[]>("drinks")
      .then((d) => {
        setDrinks(d);
      })
      .catch(toastAxiosError);
  }, [listVersion, createDrink, restockDrink]);

  return (
    <div>
      <DrinkList
        drinks={drinks}
        editor={editor}
        action={drinkAction}
        listVersion={listVersion}
      />
      {editor && restockDrink && (
        <ActivityModal
          onClose={() => setRestockDrink(false)}
          title={"Restock"}
          body={
            <Restock setDrinks={setModifiedDrinks} working={updatingDrinks} />
          }
          footer={
            <Button text={"Save"} block={true} onClick={handleOnSaveDrinks} />
          }
        />
      )}
      {editor && createDrink && (
        <ActivityModal
          onClose={() => setCreateDrink(false)}
          title={"Create new drink"}
          body={
            <>
              {updatingDrinks && (
                <div className={"h-6 w-full"}>
                  <Loader />
                </div>
              )}
              <CreateDrink drinkDto={drinkDto} setDrinkDto={setDrinkDto} />
            </>
          }
          footer={
            <Button text={"Save"} block={true} onClick={handleOnCreateDrink} />
          }
        />
      )}
    </div>
  );
};

export default DrinkMenu;
