import React, { createContext, ReactNode, useContext, useState } from "react";
import { Staff } from "../typings/permissions";

// Define the shape of the context
interface StaffContextType {
  staff: Staff | null; // null means no staff logged in
  setStaff: (staff: Staff | null) => void; // function to update the staff
}

// Create the StaffContext
const StaffContext = createContext<StaffContextType | undefined>(undefined);

// Create a provider component
export const StaffProvider = ({ children }: { children: ReactNode }) => {
  const [staff, setStaff] = useState<Staff | null>(null);

  return (
    <StaffContext.Provider value={{ staff, setStaff }}>
      {children}
    </StaffContext.Provider>
  );
};

// Custom hook to use the StaffContext
export const useStaff = () => {
  const context = useContext(StaffContext);
  if (context === undefined) {
    throw new Error("useStaff must be used within a StaffProvider");
  }
  return context;
};
