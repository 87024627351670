import React, { useEffect, useRef, useState } from "react";
import { Invoice, Reservation } from "../../../typings/reservation";
import { getDates, getGreeting, printToJpg } from "../../../utils/validation";
import { CurrentHostThumbnailUrl } from "../../../constants/links";
import BotChat from "../../../components/BotChat";
import { Customer } from "../../../typings/customer";
import { InvoiceView } from "./InvoiceView";
import HostSignature from "../../../emails/shared/HostSignature";
import IfemideFooter from "../../../emails/shared/IfemideFooter";
import LogoRider from "../../../emails/shared/LogoRider";
import { useStaff } from "../../../hooks/useStaff";

const AvailableInvoice = ({
  invoice,
  reservation,
  customer,
}: {
  invoice: Invoice;
  reservation: Reservation;
  customer: Customer;
  checkInDate?: string;
  checkOutDate?: string;
}) => {
  const { staff } = useStaff();
  const [showInvoice, setShowInvoice] = useState(false);
  const printRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = () => {
    printToJpg(
      printRef.current,
      "ifemide-invoice-" + invoice.reservationId + ".jpg",
    ).catch(console.log);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInvoice(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (!invoice || !reservation)
    return (
      <div className={"bg-white p-3 text-sm rounded-xl my-6 shadow-lg m-3"}>
        <p>No invoice is available, please check again later.</p>
      </div>
    );

  const { minCheckIn, maxCheckOut } = getDates(reservation.bookings);

  return (
    <div className={"space-y-6"}>
      <BotChat
        delays={1000}
        text={"text-sm"}
        profilePhoto={CurrentHostThumbnailUrl}
        messages={[
          {
            content: `${getGreeting()} ${customer.firstName}, I have prepared your invoice, just a sec, let me fetch it for you:`,
            sender: "Rita",
          },
        ]}
      />
      {showInvoice && staff && (
        <div className={"space-y-3"}>
          <div className={"flex flex-row justify-end"}>
            <button
              type="button"
              onClick={handlePrint}
              className={
                "h-8 w-8 rounded-full bg-pink-500 text-white font-extrabold shadow-xl"
              }
            >
              <i className="bx bx-printer"></i>
            </button>
          </div>
          <div className={"p-3 bg-white rounded-xl shadow-xl"} ref={printRef}>
            <div className={"my-6 mb-9"}>
              <LogoRider />
            </div>
            <InvoiceView
              customerName={customer.firstName}
              invoice={invoice}
              checkInDate={minCheckIn.format("ddd. MMMM D")}
              checkOutDate={maxCheckOut.format("ddd. MMMM D")}
            />
            <HostSignature staff={staff} />
            <hr />
            <IfemideFooter />
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableInvoice;
