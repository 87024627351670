import React from "react";
import { Link } from "react-router-dom";
import FullPage from "../../components/FullPage";

interface Grid {
  id: string;
  icon: string;
  title: string;
  description: string;
  disabled?: boolean;
  new?: boolean;
}

const GridItem = (props: Grid) => {
  return (
    <div className={"relative"}>
      <button
        disabled={props.disabled}
        className={`w-full h-40 rounded-xl shadow-xl border flex flex-col justify-center p-3 text-left ${props.disabled ? "bg-gray-200 border-none" : " bg-white"}`}
      >
        <p className={"font-thin text-2xl text-gray-500"}>{props.title}</p>
        <p className={"text-xs text-black"}>{props.description}</p>
      </button>
      {props.new && (
        <p
          className={
            "absolute top-2 right-2 bg-pink-400 px-3 py-1 text-xs text-white rounded-full"
          }
        >
          NEW
        </p>
      )}
    </div>
  );
};

const banners = [
  "https://woodsbrand.co.zw/wp-content/uploads/2016/01/cropped-Remax-cartoon-header.jpg",
  "https://www.freewebheaders.com/wp-content/gallery/houses/dreamy-asian-houses-landscape-header.jpg",
  "https://www.homeanddesign.com/portfolio/wp-content/uploads/2018/05/McDonald-header.jpg",
  "https://i0.wp.com/scarydad.com/wp-content/uploads/2012/06/mansion-header.jpg?ssl=1",
  "https://www.freewebheaders.com/wp-content/gallery/beautiful-landscape/cache/amazing-house-on-lake-reflection-landscape-web-header.jpg-nggid044742-ngg0dyn-1280x375x100-00f0w010c010r110f110r010t010.jpg",
  "https://www.freewebheaders.com/wp-content/gallery/beautiful-landscape/cache/fabulous-spring-nature-trees-and-river-landscape-web-header.jpg-nggid044735-ngg0dyn-1280x375x100-00f0w010c010r110f110r010t010.jpg",
  "https://www.freewebheaders.com/wp-content/gallery/beautiful-landscape/cache/pink-purple-sky-and-spring-nature-landscape-header.jpg-nggid044745-ngg0dyn-1280x375x100-00f0w010c010r110f110r010t010.jpg",
  "https://www.freewebheaders.com/wp-content/gallery/flowers/colorful-spring-flowers-and-blue-sky-website-header.jpg",
  "https://img.freepik.com/premium-photo/beautiful-tulips-spring-nature-background-web-banner-card-design_355067-5426.jpg",
];

const HostHome = () => {
  return (
    <FullPage
      title={"HOST DASHBOARD"}
      back={"/"}
      bannerUrl={banners[Math.floor(Math.random() * banners.length)]}
      component={
        <div
          className={"w-full flex flex-col justify-center items-center pb-80"}
        >
          <div className={"w-full space-y-4"}>
            <div className="w-full grid grid-cols-2 gap-4">
              <Link to={"/host/customers"}>
                <GridItem
                  new={false}
                  id={"1"}
                  icon={""}
                  title={"Manage Customers"}
                  description={
                    "Modify or create a new customer or enquirer and save to the database."
                  }
                />
              </Link>
              <Link to={"/host/reservations"}>
                <GridItem
                  new={false}
                  id={"1"}
                  icon={""}
                  title={"Manage Reservations"}
                  description={
                    "View, modify or create a new reservation and send automatic confirmation email."
                  }
                />
              </Link>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <Link to={"/host/invoice"}>
                <GridItem
                  new={false}
                  id={"1"}
                  icon={""}
                  title={"Customer Invoice"}
                  description={
                    "Generate customer invoice from completed or new reservations."
                  }
                />
              </Link>
              <Link to={"/host/menu"}>
                <GridItem
                  new={false}
                  id={"1"}
                  icon={""}
                  title={"Manage Stock"}
                  description={
                    "View and or modify price list, customers can be able to see this immediately."
                  }
                />
              </Link>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <Link to={"/host/calendar"}>
                <GridItem
                  disabled={false}
                  new={true}
                  id={"1"}
                  icon={""}
                  title={"Bookings Calendar"}
                  description={
                    "See weekly or monthly calender for confirmed reservations."
                  }
                />
              </Link>
              <Link to={"/host/transactions"}>
                <GridItem
                  disabled={false}
                  new={true}
                  id={"1"}
                  icon={""}
                  title={"Daily Transactions"}
                  description={
                    "Update your daily transactions, records are pulled from the bank every 2 hours."
                  }
                />
              </Link>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <GridItem
                disabled={true}
                new={false}
                id={"1"}
                icon={""}
                title={"Energy Documentation"}
                description={
                  "Document energy usages such as petrol, diesel or gas used on site."
                }
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default HostHome;
