import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import FormEntry from "./FormEntry";
import Button from "./Button";
import Logo from "./Logo";
import { SpanLink } from "../pages/faq/FaqHome";
import { useStaff } from "../hooks/useStaff";
import { AllStaffs, Staff } from "../typings/permissions";
import { sendEmail } from "../api/email";
import { generateOTP } from "../utils/validation";
import { toastError, toastSuccess } from "../utils/toast";
import { render } from "@react-email/components";
import VerifyIdentity from "../emails/shared/VerifyIdentity";

export const JWT_KEY = "2003e1e9-efd3-4590-a206-8016ffefd519";
const JWT_SIGNATURE = "2003e1e9-efd3-4590-a206-8016ffefd519";

/// const allowedUsers = ["rita-0424", "olu-admin", "seun-admin"];

// Function to simulate JWT creation (without actual signing)
const createMockJWT = (payload: object) => {
  // Base64 encode the header
  const header = {
    alg: "HS256", // Mock algorithm
    typ: "JWT",
  };
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));

  return `${encodedHeader}.${encodedPayload}.${JWT_SIGNATURE}`;
};

const loginRequest = (staff: Staff) => {
  const payload = {
    sub: staff.username,
    iat: Math.floor(Date.now() / 1000),
    exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60, // Expires in 1 day
  };

  // Return the mock JWT token
  return createMockJWT(payload);
};

// HostAuthenticator HOC
const HostAuthenticator = (props: any) => {
  const { setStaff } = useStaff();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const [challenge, setChallenge] = useState<string>();

  function handleGetOtp() {
    setError("");
    const staff = AllStaffs[username.trim().toLowerCase()];
    if (!username || !staff) {
      setError("Invalid user name");
      return;
    }

    const generatedOTP = String(generateOTP());

    render(<VerifyIdentity validationCode={String(generatedOTP)} />)
      .then((html) => {
        sendEmail({
          to: staff.emailAddress,
          subject: "Your one time passcode",
          html,
          isSecret: true,
        })
          .then(() => {
            setChallenge(generatedOTP);
            toastSuccess("OTP sent to your email address");
          })
          .catch(() => {
            setError(
              "An error occurred while trying to send OTP, please try again later.",
            );
          });
      })
      .catch(toastError);
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(JWT_KEY);
    if (token) {
      try {
        // Decode and verify token expiration
        const decodedToken: any = jwtDecode(token);
        const _staff = AllStaffs[(decodedToken.sub as string).toLowerCase()];
        if (decodedToken.exp * 1000 > Date.now() && _staff) {
          login(_staff);
        } else {
          localStorage.removeItem(JWT_KEY);
        }
      } catch (error) {
        localStorage.removeItem(JWT_KEY);
      }
    }
    setLoading(false);
  }, []);

  function login(staff: Staff) {
    const token = loginRequest(staff);
    localStorage.setItem(JWT_KEY, token);
    setStaff(staff);
    setIsAuthenticated(true);
  }

  const handleLogin = () => {
    try {
      if (challenge && challenge === otp && AllStaffs[username.toLowerCase()]) {
        login(AllStaffs[username.toLowerCase()]);
      } else setError("Invalid username or otp");
    } catch (err) {
      setError("Invalid username or otp");
    }
  };

  if (isAuthenticated) {
    return props.children;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="flex h-screen justify-center flex-col w-full px-9">
      <div className={"bg-white p-6 px-9 py-12 rounded-xl shadow-2xl"}>
        <Logo />
        <p className={"font-extrabold text-pink-500 my-3"}>
          IFEMIDE{" "}
          <span
            className={"text-black font-extrabold underline underline-offset-2"}
          >
            HOST
          </span>
        </p>
        <FormEntry
          placeholder="Username"
          onChange={setUsername}
          title={"Username"}
          value={username}
          type={"text"}
        />
        <div className={"flex flex-row justify-end mt-6"}>
          <Button onClick={handleGetOtp} type="secondary" text="Get OTP" />
        </div>
        <FormEntry
          placeholder="PIN"
          onChange={setOtp}
          title={"OTP"}
          value={otp}
          type={"password"}
        />
        {error && <p className="text-pink-500 py-3">{error}</p>}
        <div className={"flex flex-row justify-end mt-6"}>
          <Button onClick={handleLogin} type="primary" text="Login" />
        </div>
        <p className={"my-6 text-black"}>
          Have a reservation id? Login{" "}
          <SpanLink title={"here"} href={"/guest"} target={""} />
        </p>
      </div>
    </div>
  );
};

export default HostAuthenticator;
