import React from "react";
import { Column, Heading, Row, Section, Text } from "@react-email/components";
import MailSetup from "./shared/MailSetup";
import { Instagram, WhatsApp } from "../pages/faq/FaqHome";
import { KitchenOrder } from "../typings/menu";
import moment from "moment";
import { formatPrice } from "../utils/validation";
import { Rita } from "../typings/permissions";

interface Interface {
  order: KitchenOrder;
}

const ConfirmedKitchenOrder = ({ order }: Interface) => {
  return (
    <MailSetup
      staff={Rita}
      previewText={"Your reservation is almost confirmed!"}
      component={
        <>
          <Text>Hi {order.firstName + " " + order.lastName} 👋</Text>
          <Section>
            <Text className={""}>
              We’re excited to prepare your meal and confirm that we’ve received
              your order{" "}
              <span
                className={
                  "font-extrabold underline underline-offset-4 text-pink-500"
                }
              >
                #{order.id}
              </span>
              .
            </Text>
          </Section>
          <Section>
            <Heading className={"my-3 text-lg"}>Order Summary</Heading>
            <div>
              <Text style={{ padding: 0, margin: 0 }}>
                <span className={"font-semibold ml-2"}>
                  {" "}
                  &bull; Order number:{" "}
                </span>
                {order.id}
              </Text>
              <Text style={{ padding: 0, margin: 0 }}>
                <span className={"font-semibold ml-2"}>
                  {" "}
                  &bull; Order date and time:{" "}
                </span>
                {moment().format("Do, dddd MMM")}
              </Text>
              <Text style={{ padding: 0, margin: 0 }}>
                <span className={"font-semibold ml-2"}>
                  {" "}
                  &bull; Estimated time:{" "}
                </span>
                30-60 minutes
              </Text>
              <div className={"bg-gray-100 rounded-xl border space-y-3 p-3"}>
                {order.cart.map((item) => (
                  <Row className={"p-2"}>
                    <Column align={"left"}>
                      <Text style={{ padding: 0, margin: 0 }} className={""}>
                        {item.title}
                      </Text>
                    </Column>
                    <Column align={"right"}>
                      <Text style={{ padding: 0, margin: 0 }} className={""}>
                        {`${item.quantity} x ${formatPrice(item.rate)}`}
                        &nbsp;&nbsp;
                        {formatPrice(item.rate * item.quantity)}
                      </Text>
                    </Column>
                  </Row>
                ))}
                <hr />
                {order.withDeliveryFee > 0 && (
                  <Row className={"p-2"}>
                    <Column align={"left"}>
                      <Text style={{ padding: 0, margin: 0 }}>
                        Delivery fee
                      </Text>
                    </Column>
                    <Column align={"right"}>
                      <Text
                        style={{ padding: 0, margin: 0 }}
                        className={"font-semibold text-end"}
                      >
                        {formatPrice(order.withDeliveryFee)}
                      </Text>
                    </Column>
                  </Row>
                )}
                <Row className={"p-2"}>
                  <Column align={"left"}>
                    <Text style={{ padding: 0, margin: 0 }}>Total</Text>
                  </Column>
                  <Column align={"right"}>
                    <Text
                      style={{ padding: 0, margin: 0 }}
                      className={"font-semibold text-end"}
                    >
                      {formatPrice(order.paymentInfo.total)}
                    </Text>
                  </Column>
                </Row>
              </div>
            </div>
            {order.notes.length > 0 && (
              <div className={"space-y-3"}>
                <Heading className={"my-3 text-lg"}>Notes</Heading>
                <ul className={"space-y-1"}>
                  {order.notes.map((note) => (
                    <li>
                      <Text style={{ margin: 0, padding: 0 }}>{note}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <Heading className={"my-3 text-lg"}>Payment Information</Heading>
            <div className={"flex flex-col bg-white px-3"}>
              <Row className={""}>
                <Column align={"left"}>
                  <Text style={{ padding: 0, margin: 0 }} className={"mr-3"}>
                    &bull; Amount paid
                  </Text>
                </Column>
                <Column align={"right"}>
                  <Text
                    style={{ padding: 0, margin: 0 }}
                    className={"font-semibold text-end"}
                  >
                    {formatPrice(order.paymentInfo.paid)}
                  </Text>
                </Column>
              </Row>
            </div>
            <div className={"flex flex-col bg-white px-3"}>
              <Row className={""}>
                <Column align={"left"}>
                  <Text style={{ padding: 0, margin: 0 }} className={"mr-3"}>
                    &bull; Amount due
                  </Text>
                </Column>
                <Column align={"right"}>
                  <Text
                    style={{ padding: 0, margin: 0 }}
                    className={"font-semibold text-end"}
                  >
                    {formatPrice(order.paymentInfo.due)}
                  </Text>
                </Column>
              </Row>
            </div>
            <Heading className={"my-3 text-lg"}>Delivery Information</Heading>
            <div className={"bg-gray-100 rounded-xl border space-y-3 p-3"}>
              <Text style={{ padding: 0, margin: 0 }}>
                {order.delivery.addressLine1}
              </Text>
              <Text style={{ padding: 0, margin: 0 }}>
                {order.delivery.addressLine2}
              </Text>
            </div>
            <Text>
              We’re committed to ensuring your order is prepared just the way
              you like it! If you need to make any changes, please contact us as
              soon as possible at {Instagram()} or {WhatsApp()}! 💬✨
            </Text>
            <Text>Thanks a bunch for choosing us! 🤗❤️</Text>
          </Section>
        </>
      }
    />
  );
};

export default ConfirmedKitchenOrder;
