import React, { ReactNode } from "react";

interface Props {
  show: boolean;
  component: ReactNode;
  style?: string;
}

const Modal = (props: Props) => {
  if (!props.show) return null;

  return (
    <div
      className={
        "absolute top-0 bottom-0 right-0 left-0 bg-black/50 flex flex-col items-center justify-center p-6 z-50 " +
        props.style
      }
    >
      {props.component}
    </div>
  );
};

export default Modal;
