import {
  BotThumbnailUrl,
  FaithThumbnailUrl,
  RitaThumbnailUrl,
  TimileyinThumbnailUrl,
  TobiThumbnailUrl,
} from "../constants/links";

export type AllPermission = "*";

export type InvoicePermission =
  | "*:invoice"
  | "read:invoice"
  | "create:invoice"
  | "delete:invoice"
  | "modify:invoice";
export type StockPermission =
  | "*:stock"
  | "read:stock"
  | "create:stock"
  | "delete:stock"
  | "modify:stock";
export type ReservationPermission =
  | "*:reservation"
  | "read:reservation"
  | "create:reservation"
  | "modify:reservation"
  | "delete:reservation";
export type CustomerPermission =
  | "*:customer"
  | "read:customer"
  | "create:customer"
  | "modify:customer"
  | "delete:customer";

export type Permission =
  | AllPermission
  | InvoicePermission
  | StockPermission
  | ReservationPermission
  | CustomerPermission;

export interface Staff {
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  role:
    | "Guest Experience Host"
    | "Chef"
    | "Management"
    | "House Master"
    | "Civil Site Engineer";
  emailAddress: string;
  permissions: Permission[];
  thumbnailUrl: string;
  phoneNumber: string;
}

export const Olu: Staff = {
  emailAddress: "enocholumide@gmail.com",
  phoneNumber: "",
  firstName: "Olumide",
  displayName: "Admin",
  username: "olu",
  lastName: "Igbiloba",
  permissions: ["*"],
  role: "Management",
  thumbnailUrl: BotThumbnailUrl,
};

export const Rita: Staff = {
  emailAddress: "o.rita@firstatlanticindustries.com",
  phoneNumber: "+234 913 657 9971",
  firstName: "Rita",
  displayName: "Rita Esosa",
  username: "rita",
  lastName: "Olu-Ighama",
  permissions: ["*:stock", "*:customer", "*:reservation", "*:invoice"],
  role: "Guest Experience Host",
  thumbnailUrl: RitaThumbnailUrl,
};

export const Faith: Staff = {
  emailAddress: "olowoyeyefaith1@gmail.com",
  phoneNumber: "+234 810 811 0908",
  firstName: "Damilola Faith",
  displayName: "Faith Damilola",
  username: "faith",
  lastName: "Olowoyeye",
  permissions: ["*:stock", "*:customer", "*:reservation", "*:invoice"],
  role: "Guest Experience Host",
  thumbnailUrl: FaithThumbnailUrl,
};

export const Tobi: Staff = {
  emailAddress: "tobioyetunji74@gmail.com",
  phoneNumber: "+234 813 718 9729",
  firstName: "Oluwatobi Elijah",
  lastName: "Oyetunji",
  displayName: "Oluwatobi Oyetunji",
  username: "tobi",
  permissions: ["read:reservation"],
  role: "Civil Site Engineer",
  thumbnailUrl: TobiThumbnailUrl,
};

export const Timileyin: Staff = {
  emailAddress: "josepholuwatimileyin16@gmail.com",
  phoneNumber: "+234 703 730 6520",
  firstName: "Oluwatimileyin",
  lastName: "Joseph",
  displayName: "Timileyin",
  username: "timileyin",
  permissions: ["read:reservation", "read:stock"],
  role: "House Master",
  thumbnailUrl: TimileyinThumbnailUrl,
};

export const AllStaffs: Record<string, Staff> = {
  [Rita.username.toLowerCase()]: Rita,
  [Olu.username.toLowerCase()]: Olu,
  [Faith.username.toLowerCase()]: Faith,
  [Tobi.username.toLowerCase()]: Tobi,
  [Timileyin.username.toLowerCase()]: Timileyin,
};
