import { RoomVariantConfig } from "../pages/RoomView";
import { RoomVariant } from "../Home";
import { RoomUuids } from "../../../../typings/rooms";

export type Uuid = string;
export type BedroomsConfigType = Record<RoomVariant, Uuid[]>;

export const RoomTypeConfig: BedroomsConfigType = {
  Solitaire: [
    RoomUuids.FOUR_BEDROOM,
    RoomUuids.THREE_BEDROOM,
    RoomUuids.TWO_BEDROOM,
    RoomUuids.INDOOR_PARTY,
    RoomUuids.WEDDING_PARTY_AND_EVENTS,
    RoomUuids.PHOTO_SHOOT,
  ],
  Twinmotion: [
    RoomUuids.THREE_BEDROOM,
    RoomUuids.TWO_BEDROOM,
    RoomUuids.PHOTO_SHOOT,
  ],
};

export const Solitaire: RoomVariantConfig = {
  defaultRoomUuid: RoomUuids.FOUR_BEDROOM,
  title: "Solitaire",
  photos: [
    {
      title: "Sleek, contemporary design with a modern touch.",
      subtitle:
        "Experience the perfect blend of style and comfort for modern living.",
      actionText: "Check availability",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/1.png",
    },
    {
      title: "Sleek, contemporary design with a modern touch.",
      subtitle:
        "Experience the perfect blend of style and comfort for modern living.",
      actionText: "Check availability",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/1b.png",
      skip: true,
    },
    {
      title: "Where Comfort Meets Elegance",
      subtitle:
        "Discover chic bedrooms that blend comfort with contemporary design",
      actionText: "Book now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/2.JPG",
    },
    {
      title: "Natural Light from Floor-to-Ceiling Windows",
      subtitle:
        "Bright, airy, and serene—our rooms feature full-height windows for maximum natural light and views",
      actionText: "Book now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/2b.JPG",
    },
    {
      title: "Natural Light from Floor-to-Ceiling Windows",
      subtitle:
        "Bright, airy, and serene—our rooms feature full-height windows for maximum natural light and views",
      actionText: "Book now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/2d.png",
      skip: true,
    },
    {
      title: "Fitted Kitchen with Seamless Flow to Dining",
      subtitle:
        "An inviting kitchen with a flawless transition to the dining area, perfect for gatherings",
      actionText: "Offers are available",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/6.jpeg",
    },
    {
      title:
        "Elegant Wooden Dining Table with Modern Side-by-Side Refrigerator",
      subtitle:
        "Gather around a beautifully crafted wooden dining table, complemented by a sleek side-by-side refrigerator for effortless entertaining",
      actionText: "Book now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/5a.jpeg",
    },
    {
      title: "Stunning Double-Volume Living Room",
      subtitle:
        "Experience the perfect blend of modern design and comfort in our expansive living area, ideal for socializing or simply enjoying a quiet evening",
      actionText: "Check availability",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/4.jpeg",
    },
    {
      title: "Stunning Double-Volume Living Room",
      subtitle:
        "Experience the perfect blend of modern design and comfort in our expansive living area, ideal for socializing or simply enjoying a quiet evening",
      actionText: "Check availability",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/14.png",
      skip: true,
    },
    {
      title: "Modern Bathroom with Ceiling Speakers and Immaculate Cleanliness",
      subtitle:
        "Enjoy the perfect blend of cleanliness and modern convenience with ceiling speakers that enhance your daily routine, making it feel like a personal retreat",
      actionText: "Book now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/3d.jpeg",
    },
    {
      title: "Showers with Wall-Mounted Water Closets",
      subtitle:
        "Enjoy the perfect blend of form and function with our luxurious showers and minimalist floating water closets, designed for your comfort and convenience",
      actionText: "Make a reservation",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/3c.JPG",
    },
  ],
};

export const Twinmotion: RoomVariantConfig = {
  defaultRoomUuid: RoomUuids.TWO_BEDROOM,
  title: "Twinmotion",
  photos: [
    {
      title: "Charming Two-Story Home with Minimalist Design",
      subtitle:
        "Embrace open living in a spacious layout, complemented by a large balcony perfect for outdoor relaxation.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_150213_547.jpg",
      backgroundPosition: "1px 0px",
    },
    {
      title: "Private Compound with Exclusive Driveway",
      subtitle:
        "Enjoy the convenience of a spacious driveway, featuring stylish white and black tiled flooring and room for two cars.",
      actionText: "Reserve Your Spot",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145811_958.jpg",
    },
    {
      title: "Private Compound with Exclusive Driveway",
      subtitle:
        "Enjoy the convenience of a spacious driveway, featuring stylish white and black tiled flooring and room for two cars.",
      actionText: "Reserve Your Spot",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145826_531.jpg",
      skip: true,
    },
    {
      title: "Private Compound with Exclusive Driveway",
      subtitle:
        "Enjoy the convenience of a spacious driveway, featuring stylish white and black tiled flooring and room for two cars.",
      actionText: "Reserve Your Spot",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_150224_703.jpg",
      skip: true,
    },
    {
      title: "Rooms with Private Balconies",
      subtitle:
        "Every room offers a private balcony for you to enjoy fresh air and scenic views.",
      actionText: "Reserve Your Room",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_150418_810.jpg",
    },
    {
      title: "Cozy Compact Living Room",
      subtitle:
        "Relax in a minimalist living room, featuring a sleek L-shaped sofa and stylish accent chairs for ultimate comfort.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145432_117.jpg",
    },
    {
      title: "Cozy Compact Living Room",
      subtitle:
        "Relax in a minimalist living room, featuring a sleek L-shaped sofa and stylish accent chairs for ultimate comfort.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145330_081.jpg",
      skip: true,
    },
    {
      title: "Spacious Open Floor Concept",
      subtitle:
        "The seamless design connects the living area with the modern kitchen, perfect for entertaining.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145711_987.jpg",
    },
    {
      title: "Spacious Open Floor Concept",
      subtitle:
        "The seamless design connects the living area with the modern kitchen, perfect for entertaining.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145312_429.jpg",
    },
    {
      title: "Spacious Open Floor Concept",
      subtitle:
        "The seamless design connects the living area with the modern kitchen, perfect for entertaining.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145707_894.jpg",
      skip: true,
    },
    {
      title: "Spacious Open Floor Concept",
      subtitle:
        "The seamless design connects the living area with the modern kitchen, perfect for entertaining.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_124026_258.jpg",
      skip: true,
    },
    {
      title: "Modern Kitchen with Island",
      subtitle:
        "A fully fitted kitchen featuring a stylish island, cooker top, and all the essentials for gourmet meals.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145525_431.jpg",
    },
    {
      title: "Stunning Glass Staircase",
      subtitle:
        "Admire the sleek beauty of a custom-designed glass staircase, framed by stylish aluminum railings that seamlessly blend elegance with modern design.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145229_890-EDIT.jpg",
    },
    {
      title: "Elegant Minimalistic Stairwell",
      subtitle:
        "Step into a refined space where clean lines meet natural light. A spacious stairwell adorned with flowing bright curtains, inspiring wall art, and a timeless marble floor that exudes luxury and tranquility.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_145055_740.jpg",
    },
    {
      title: "Stunning Glass Staircase",
      subtitle:
        "Admire the sleek beauty of a custom-designed glass staircase, framed by stylish aluminum railings that seamlessly blend elegance with modern design.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143348_896.jpg",
      skip: true,
    },
    {
      title: "Stunning Glass Staircase",
      subtitle:
        "Admire the sleek beauty of a custom-designed glass staircase, framed by stylish aluminum railings that seamlessly blend elegance with modern design.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143406_538.jpg",
      skip: true,
    },
    {
      title: "Stunning Glass Staircase",
      subtitle:
        "Admire the sleek beauty of a custom-designed glass staircase, framed by stylish aluminum railings that seamlessly blend elegance with modern design.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143414_662.jpg",
      skip: true,
    },
    {
      title: "Comfy King Size Bed for Ultimate Relaxation",
      subtitle:
        "Enjoy a restful sleep on a luxurious king-size bed, complete with air conditioning and stylish furniture.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143058_771.jpg",
    },
    {
      title: "Comfy King Size Bed for Ultimate Relaxation",
      subtitle:
        "Enjoy a restful sleep on a luxurious king-size bed, complete with air conditioning and stylish furniture.",
      actionText: "Book Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143035_500.jpg",
      skip: true,
    },
    {
      title: "Elegant King Size Bed and Spacious Comfort",
      subtitle:
        "Relax in style with a king-size bed, air conditioning, a dresser, and plenty of storage space.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_142556_564.jpg",
    },
    {
      title: "Elegant King Size Bed and Spacious Comfort",
      subtitle:
        "Relax in style with a king-size bed, air conditioning, a dresser, and plenty of storage space.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_142624_818.jpg",
      skip: true,
    },
    {
      title: "Elegant King Size Bed and Spacious Comfort",
      subtitle:
        "Relax in style with a king-size bed, air conditioning, a dresser, and plenty of storage space.",
      actionText: "Reserve Now",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143910_534.jpg",
      skip: true,
    },
    {
      title: "Sleek Bathroom with Dual Wash Basins",
      subtitle:
        "Experience ultimate comfort with a modern toilet and bath, featuring dual wash basins, a large mirror, and a premium shower system.",
      actionText: "Reserve Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_142522_463.jpg",
    },
    {
      title: "Sleek Bathroom with Dual Wash Basins",
      subtitle:
        "Experience ultimate comfort with a modern toilet and bath, featuring dual wash basins, a large mirror, and a premium shower system.",
      actionText: "Reserve Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143751_041.jpg",
      skip: true,
    },
    {
      title: "Sleek Bathroom with Dual Wash Basins",
      subtitle:
        "Experience ultimate comfort with a modern toilet and bath, featuring dual wash basins, a large mirror, and a premium shower system.",
      actionText: "Reserve Your Stay",
      url: "https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Tm2-Edited/IMG_20241108_143606_892.jpg",
      skip: true,
    },
  ],
};
