import { Drink } from "../../../../typings/menu";
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import DoubleClickButton from "../../../../components/DoubleClickButton";
import { formatPrice } from "../../../../utils/validation";

export const DrinkItem = ({
  drink,
  editable = false,
  removeItem = () => {},
  updateItem = () => {},
  working = false,
  showStock = false,
}: {
  drink: Drink;
  editable?: boolean;
  removeItem?: (uuid: string) => void;
  updateItem?: (drink: { uuid: string } & Partial<Drink>) => void;
  working?: boolean;
  showStock?: boolean;
}) => {
  const [showThumbnail, setShowThumbnail] = useState(false);

  const [title, setTitle] = useState(drink.title);
  const [currentStock, setCurrentStock] = useState(drink.currentStock);
  const [rate, setRate] = useState(drink.rate);
  const [thumbnailUrl, setThumbnailUrl] = useState(drink.thumbnailUrl);

  const [error] = useState<AxiosError | undefined>(undefined);

  useEffect(() => {
    if (title !== drink.title) setTitle(drink.title);
    if (currentStock !== drink.currentStock)
      setCurrentStock(drink.currentStock);
    if (rate !== drink.rate) setRate(drink.rate);
    if (thumbnailUrl !== drink.thumbnailUrl)
      setThumbnailUrl(drink.thumbnailUrl);
  }, [currentStock, drink, rate, thumbnailUrl, title]);

  async function handleRemoveItem() {
    removeItem(drink.uuid);
  }

  function onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setTitle(e.target.value);
    updateItem({ ...drink, title: e.target.value });
  }

  function onStockChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
      setCurrentStock(parsedValue);
      updateItem({ ...drink, currentStock: parsedValue });
    }
  }

  function onRateChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setRate(parsedValue);
      updateItem({ ...drink, rate: parsedValue });
    }
  }

  function onThumbnailUrlChanged(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setThumbnailUrl(e.target.value);
    updateItem({ ...drink, thumbnailUrl: e.target.value });
  }

  function toggleThumbnail() {
    setShowThumbnail(!showThumbnail);
  }

  return (
    <div className={`w-full border-b my-1 ${working ? "opacity-30" : ""}`}>
      {error && (
        <p className={"text-red-500 text-xs mx-3"}>
          <span className={"mr-3"}>
            <i className={"bx bxs-comment-error"}></i>
          </span>
          {error.message || "An error occurred."}
        </p>
      )}
      <div
        className={`flex flex-row justify-between items-center text-sm p-3 ${editable ? "px-1" : ""}`}
      >
        <div className={"flex flex-row space-x-3 items-center min-w-0"}>
          {editable && (
            <DoubleClickButton
              disabled={working}
              onRelease={handleRemoveItem}
              component={<i className={"bx bx-trash text-pink-500"}></i>}
            />
          )}
          <button disabled={working || !editable} onClick={toggleThumbnail}>
            <img
              alt={"drink"}
              className={"h-6 w-6 rounded-full overflow-hidden object-contain"}
              src={
                drink.thumbnailUrl ||
                "https://cdn-icons-png.freepik.com/256/2390/2390955.png?semt=ais_hybrid"
              }
            />
          </button>
          {editable ? (
            <input
              onChange={onTitleChange}
              disabled={working}
              className={"text-start min-w-0 max-w-[800px]"}
              style={{}} // Limit the width of the input
              defaultValue={title}
            />
          ) : (
            <p className={"text-start min-w-0 max-w-[800px]"}>{title}</p>
          )}
        </div>
        <div className={"space-x-3 flex flex-row items-center min-w-0"}>
          {editable ? (
            <input
              disabled={working}
              onChange={onRateChange}
              className={"text-end truncate min-w-0"}
              style={{ maxWidth: "50px" }}
              value={formatPrice(rate)}
            />
          ) : (
            <p className={"text-start min-w-0 max-w-[800px]"}>
              {formatPrice(rate)}
            </p>
          )}
          {showStock && (
            <div className={"space-x-1 flex-row flex"}>
              {editable ? (
                <input
                  onChange={onStockChange}
                  disabled={!editable}
                  className={`rounded truncate min-w-0 text-center text-white py-1 ${currentStock < 1 ? "bg-gray-400" : "bg-pink-400"}`}
                  style={{ maxWidth: "40px" }}
                  value={currentStock}
                  type={"number"}
                />
              ) : (
                <div
                  className={`rounded truncate min-w-0 text-center text-white px-3 py-1 ${currentStock < 1 ? "bg-gray-400" : "bg-pink-400"}`}
                >
                  {currentStock}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showThumbnail && (
        <input
          value={thumbnailUrl}
          onChange={onThumbnailUrlChanged}
          className={
            "text-xs p-1 mb-2 w-full mx-auto border rounded text-gray-600"
          }
          placeholder={`https://www.product-image-link-here`}
        />
      )}
    </div>
  );
};

export default DrinkItem;
