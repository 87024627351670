import React from "react";
import ActivityModal from "../../../../components/ActivityModal";
import { PayWith } from "../../../../components/payment/PaymentOption";
import { Service } from "../../../../typings/reservation";
import PaymentResolver from "../../../../components/payment/PaymentResolver";
import { PayStackRoomPayload } from "../../../callbacks/PaystackPayment";

const ConfirmAndPay = ({
  email,
  payload,
  type,
  handlePaymentCompleted,
  amountToPay,
  onClose,
  payWith,
}: {
  amountToPay: number;
  payWith: PayWith;
  type: Service;
  email: string;
  payload: PayStackRoomPayload;
  handlePaymentCompleted: (amountToPaid: number) => void;
  onClose: () => void;
}) => {
  return (
    <ActivityModal
      footer={""}
      onClose={onClose}
      title={"Confirm and pay"}
      body={
        <PaymentResolver
          type={type}
          payWith={payWith}
          email={email}
          payload={payload}
          handlePaymentCompleted={handlePaymentCompleted}
          amountToPay={amountToPay}
        />
      }
    />
  );
};

export default ConfirmAndPay;
