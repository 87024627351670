import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import MonthCalendar from "./MonthCalendar";
import { BookingDates } from "../pages/visitor/reservation/Reservations";

const HourSelector = ({
  isDateDisabled,
  startMonth,
  onChange,
  checkIn: _checkIn,
  checkOut: _checkOut,
}: {
  startMonth: Moment;
  checkIn?: Moment;
  checkOut?: Moment;
  isDateDisabled: (d: Moment) => boolean;
  onChange: (d: BookingDates) => void;
}) => {
  const [checkIn, setCheckIn] = useState<Moment | undefined>(_checkIn);
  const [checkOut, setCheckOut] = useState<Moment | undefined>(_checkOut);

  // Generate an array of hours from 07:00 to 20:00
  const hours = [];
  let start = moment("12:00", "HH:mm"); // Start time
  let end = moment("15:00", "HH:mm"); // End time
  while (start.isBefore(end) || start.isSame(end)) {
    hours.push(start.clone());
    start.add(1, "hour");
  }

  const handleHourClick = (hour: Moment) => {
    if (checkIn && !checkOut) {
      setCheckOut(checkIn.clone().hour(hour.hour()).minute(0));
    } else if (checkOut && !checkIn) {
      setCheckIn(checkOut.clone().hour(hour.hour()).minute(0));
    } else if (checkIn && checkOut) {
      setCheckIn(checkIn.clone().hour(hour.hour()).minute(0));
      setCheckOut(undefined);
    }
  };

  // Handle date selection
  const handleDateSelect = (date: Moment) => {
    // If checkIn and checkOut are defined, preserve their times but update the day, month, and year
    if (checkIn) {
      setCheckIn(date.clone().hour(checkIn.hour()).minute(checkIn.minute()));
    } else {
      // If checkIn is undefined, set it to 12:00 on the selected date
      setCheckIn(date.clone().hour(12).minute(0));
    }

    if (checkOut) {
      setCheckOut(date.clone().hour(checkOut.hour()).minute(checkOut.minute()));
    } else {
      // If checkOut is undefined, set it to 15:00 on the selected date
      setCheckOut(date.clone().hour(15).minute(0));
    }
  };

  useEffect(() => {
    if (checkIn && checkOut && checkOut.isBefore(checkIn)) {
      const temp = checkIn.clone();
      setCheckIn(checkOut.clone());
      setCheckOut(temp);
    }
  }, [checkIn, checkOut]);

  useEffect(() => {
    onChange({ checkIn, checkOut });
  }, [checkIn, checkOut]);

  const selectedTimes = [
    checkIn ? checkIn.format("HH:mm") : "",
    checkOut ? checkOut.format("HH:mm") : "",
  ];

  return (
    <div className="space-y-3">
      <MonthCalendar
        monthMoment={startMonth}
        onSelect={handleDateSelect}
        isDateDisabled={(d) =>
          isDateDisabled(d) || [4, 5, 6, 7].includes(d.isoWeekday())
        }
        checkIn={checkIn}
      />
      {checkIn && (
        <div className="space-y-3">
          <p className="px-3 text-lg font-semibold">Select time slot</p>
          <div className="flex space-x-2 overflow-x-auto scrollbar-hidden px-3">
            {hours.map((hour) => (
              <div
                key={hour.format("HH:mm")}
                className={`px-6 hour-slot flex items-center justify-center w-16 h-12 rounded-md border cursor-pointer transition duration-200 ease-in-out
        ${selectedTimes.includes(hour.format("HH:mm")) ? "bg-pink-500 text-white" : "bg-transparent hover:bg-gray-100"}
      `}
                onClick={() => handleHourClick(hour)}
              >
                {hour.format("HH:mm")}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HourSelector;
