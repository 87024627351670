import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://api.ifemide.com";

export const MOCK_PAYMENT: boolean = Boolean(
  process.env.REACT_APP_MOCK_PAYMENT || false,
);

export const defaultAxios = axios.create();

// Set the base URL globally for all requests
axios.defaults.baseURL = BASE_URL;

// Add default headers globally
axios.defaults.headers["Content-Type"] = "application/json";

export type EntityType =
  | "rooms"
  | "customers"
  | "reservations"
  | "drinks"
  | "invoices";

export async function get<T = any>(
  path: EntityType | string,
  config?: AxiosRequestConfig,
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    axios
      .get(path, config)
      .then((r) => {
        if (r.status === 200) resolve(r.data as T);
        else reject(new AxiosError(""));
      })
      .catch(reject);
  });
}

export async function patch<T = any, R = T>(
  path: EntityType | string,
  body: T,
  config?: AxiosRequestConfig,
): Promise<R> {
  return new Promise((resolve, reject) => {
    axios
      .patch(path, body, config)
      .then((r) => {
        if (r.status === 200) resolve(r.data);
        else reject(new AxiosError(""));
      })
      .catch(reject);
  });
}

export async function post<T = any, R = T>(
  path: EntityType | string,
  body: T,
  config?: AxiosRequestConfig,
): Promise<R> {
  return new Promise((resolve, reject) => {
    axios
      .post(path, body, config)
      .then((r) => {
        if (r.status === 200) resolve(r.data as R);
        else reject(new AxiosError(r.data));
      })
      .catch(reject);
  });
}

export async function put<T = any, R = T>(
  path: EntityType | string,
  body: T,
  config?: AxiosRequestConfig,
): Promise<R> {
  return new Promise((resolve, reject) => {
    axios
      .put(path, body, config)
      .then((r) => {
        if (r.status === 200) resolve(r.data as R);
        else reject(new AxiosError(r.data));
      })
      .catch(reject);
  });
}

export async function remove<T = void>(path: EntityType | string): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .delete(path)
      .then((r) => {
        if (r.status === 200) resolve(r.data);
        else reject(new AxiosError(""));
      })
      .catch(reject);
  });
}
