import React, { useState } from "react";

const MeansOfIDUpload = ({ onChange }: { onChange: (d: File) => void }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image file (e.g., PNG, JPG, JPEG).");
        return;
      }

      setUploadedFile(file);
      onChange(file);

      // Generate preview URL
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*" // Accept only images
        capture="environment" // Use camera, default to rear camera
        onChange={handleFileChange}
        className="file-input"
      />
      {previewUrl && (
        <div style={{ marginTop: "10px" }}>
          <img
            src={previewUrl}
            alt="Uploaded Preview"
            style={{
              maxWidth: "100%",
              height: "auto",
              border: "1px solid black",
            }}
          />
        </div>
      )}
      {uploadedFile && (
        <div style={{ marginTop: "10px" }}>
          <p>Uploaded File: {uploadedFile.name}</p>
        </div>
      )}
    </div>
  );
};

export default MeansOfIDUpload;
