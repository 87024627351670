export interface Room {
  thumbnailUrl: string;
  uuid: string;
  title: string;
  rate: number;
  description: string;
  unit: Uom;
  nightly: number;
  maxOccupancy: number;
  bedrooms: number;
  archived: boolean;
}

export type Uom = "night" | "hour" | "nos";

export const RoomUuids = {
  FOUR_BEDROOM: "e6474788-4756-4b04-b842-3b8a2f37de7e",
  THREE_BEDROOM: "5579d88d-77d9-4b60-94ec-0ce912893da3",
  TWO_BEDROOM: "a277f60a-791d-40db-83b6-317991b9bd4f",
  ONE_BEDROOM: "9ac94e6f-c297-4d46-8234-6a91f83dbe5a",
  PHOTO_SHOOT: "fd9e994b-cdf4-4628-a785-cdb64577ca35",
  INDOOR_PARTY: "ede8128a-781f-4cc5-abcb-40cb9b02067e",
  WEDDING_PARTY_AND_EVENTS: "6dcd18f8-8d45-457f-a823-f2215cbf0e4b",
};

type RoomUuid = string;
type RoomNightly = number;

export type RoomUuidRecordByNightly = Record<RoomUuid, RoomNightly>;
