import React, { useState } from "react";
import FullPageRider from "../../../../components/FullPageRider";
import CarouselModal from "../../../../components/CarouselModal";
import { RoomVariant } from "../Home";

export interface RoomVariantConfig {
  defaultRoomUuid: string;
  title: RoomVariant;
  photos: {
    title: string;
    subtitle: string;
    actionText: string;
    url: string;
    backgroundPosition?: string;
    skip?: boolean;
  }[];
}

interface Props {
  room: RoomVariantConfig;
  onAction: (uuid: string) => void;
}

const RoomView = ({ room: { defaultRoomUuid, photos }, onAction }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  // Function to toggle the modal visibility
  const handleOpenModal = (i: number) => {
    setStartIndex(i);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {photos.map(
        (photo, index) =>
          !photo.skip && (
            <FullPageRider
              key={photo.title + index}
              title={photo.title}
              subtitle={photo.subtitle}
              imageUrl={photo.url}
              onAction={() => onAction(defaultRoomUuid)}
              backgroundPosition={photo.backgroundPosition}
              actionText={photo.actionText}
              onSlidePressed={() => handleOpenModal(index)}
            />
          ),
      )}
      {showModal && (
        <CarouselModal
          images={photos.map((photo) => photo.url)}
          startIndex={startIndex}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default RoomView;
